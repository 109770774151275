// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/advisors_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { AccountStub, Advisor, AdvisorStatus, EntityMetadata, ExternalLinks, FamilyStub, Member, PlanUtilization, UploadAttachment, UserPermission } from "./common_pb.ts";
import { BillingCoupon, BillingProduct } from "./billing_pb.ts";
import { EmailMessage, PhoneCall, TextMessage } from "./conversation_pb.ts";
import { ContentUpdates, Note } from "./notes_pb.ts";
import { Task } from "./tasks_pb.ts";
import { SecretStub } from "./secrets_pb.ts";
import { FactSectionStub, FactStub } from "./facts_pb.ts";

/**
 * @generated from enum protogen.FamilyStatus
 */
export enum FamilyStatus {
  /**
   * @generated from enum value: NOT_SET = 0;
   */
  NOT_SET = 0,

  /**
   * @generated from enum value: PROSPECT = 1;
   */
  PROSPECT = 1,

  /**
   * @generated from enum value: ACTIVE = 2;
   */
  ACTIVE = 2,

  /**
   * @generated from enum value: DEACTIVATED = 3;
   */
  DEACTIVATED = 3,

  /**
   * @generated from enum value: TEST = 4;
   */
  TEST = 4,

  /**
   * @generated from enum value: DEMO = 5;
   */
  DEMO = 5,

  /**
   * @generated from enum value: DEACTIVATED_PROSPECT = 6;
   */
  DEACTIVATED_PROSPECT = 6,

  /**
   * @generated from enum value: PREACTIVATION = 7;
   */
  PREACTIVATION = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(FamilyStatus)
proto3.util.setEnumType(FamilyStatus, "protogen.FamilyStatus", [
  { no: 0, name: "NOT_SET" },
  { no: 1, name: "PROSPECT" },
  { no: 2, name: "ACTIVE" },
  { no: 3, name: "DEACTIVATED" },
  { no: 4, name: "TEST" },
  { no: 5, name: "DEMO" },
  { no: 6, name: "DEACTIVATED_PROSPECT" },
  { no: 7, name: "PREACTIVATION" },
]);

/**
 * @generated from enum protogen.PlatformFeeSchedule
 */
export enum PlatformFeeSchedule {
  /**
   * @generated from enum value: SCHEDULE_NOT_SET = 0;
   */
  SCHEDULE_NOT_SET = 0,

  /**
   * @generated from enum value: SCHEDULE_NO_FEE = 1;
   */
  SCHEDULE_NO_FEE = 1,

  /**
   * @generated from enum value: SCHEDULE_FLAT_10 = 2;
   */
  SCHEDULE_FLAT_10 = 2,

  /**
   * @generated from enum value: SCHEDULE_LEAD_GEN_30_30_30_10 = 3;
   */
  SCHEDULE_LEAD_GEN_30_30_30_10 = 3,

  /**
   * @generated from enum value: SCHEDULE_FLAT_15 = 4;
   */
  SCHEDULE_FLAT_15 = 4,

  /**
   *  SCHEDULE_FLAT_20 = 6;
   *  SCHEDULE_LEAD_GEN_30_30_30_20 = 7;
   *
   * @generated from enum value: SCHEDULE_LEAD_GEN_30_30_30_15 = 5;
   */
  SCHEDULE_LEAD_GEN_30_30_30_15 = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(PlatformFeeSchedule)
proto3.util.setEnumType(PlatformFeeSchedule, "protogen.PlatformFeeSchedule", [
  { no: 0, name: "SCHEDULE_NOT_SET" },
  { no: 1, name: "SCHEDULE_NO_FEE" },
  { no: 2, name: "SCHEDULE_FLAT_10" },
  { no: 3, name: "SCHEDULE_LEAD_GEN_30_30_30_10" },
  { no: 4, name: "SCHEDULE_FLAT_15" },
  { no: 5, name: "SCHEDULE_LEAD_GEN_30_30_30_15" },
]);

/**
 * @generated from enum protogen.Medium
 */
export enum Medium {
  /**
   * @generated from enum value: MEDIUM_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MEDIUM_SMS = 1;
   */
  SMS = 1,

  /**
   * @generated from enum value: MEDIUM_EMAIL = 2;
   */
  EMAIL = 2,

  /**
   * @generated from enum value: MEDIUM_PHONE = 3;
   */
  PHONE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Medium)
proto3.util.setEnumType(Medium, "protogen.Medium", [
  { no: 0, name: "MEDIUM_UNSPECIFIED" },
  { no: 1, name: "MEDIUM_SMS" },
  { no: 2, name: "MEDIUM_EMAIL" },
  { no: 3, name: "MEDIUM_PHONE" },
]);

/**
 * @generated from message protogen.Family
 */
export class Family extends Message<Family> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string avatarUrl = 3;
   */
  avatarUrl = "";

  /**
   * @generated from field: repeated protogen.Member familyMembers = 4;
   */
  familyMembers: Member[] = [];

  /**
   * @generated from field: protogen.FamilyStatus status = 6;
   */
  status = FamilyStatus.NOT_SET;

  /**
   * @generated from field: string advisorRef = 7;
   */
  advisorRef = "";

  /**
   * @generated from field: string address = 8;
   */
  address = "";

  /**
   * @generated from field: string startDate = 9;
   */
  startDate = "";

  /**
   * @generated from field: string endDate = 10;
   */
  endDate = "";

  /**
   * @generated from field: protogen.FamilyBilling billingInfo = 13;
   */
  billingInfo?: FamilyBilling;

  /**
   * @generated from field: protogen.PlatformFeeSchedule platformFeeSchedule = 14;
   */
  platformFeeSchedule = PlatformFeeSchedule.SCHEDULE_NOT_SET;

  /**
   * @generated from field: protogen.EntityMetadata metadata = 15;
   */
  metadata?: EntityMetadata;

  constructor(data?: PartialMessage<Family>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Family";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "avatarUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "familyMembers", kind: "message", T: Member, repeated: true },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(FamilyStatus) },
    { no: 7, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "endDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "billingInfo", kind: "message", T: FamilyBilling },
    { no: 14, name: "platformFeeSchedule", kind: "enum", T: proto3.getEnumType(PlatformFeeSchedule) },
    { no: 15, name: "metadata", kind: "message", T: EntityMetadata },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Family {
    return new Family().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Family {
    return new Family().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Family {
    return new Family().fromJsonString(jsonString, options);
  }

  static equals(a: Family | PlainMessage<Family> | undefined, b: Family | PlainMessage<Family> | undefined): boolean {
    return proto3.util.equals(Family, a, b);
  }
}

/**
 * @generated from message protogen.FamilyBilling
 */
export class FamilyBilling extends Message<FamilyBilling> {
  /**
   * @generated from field: string stripeCustomerId = 1;
   */
  stripeCustomerId = "";

  /**
   * @generated from field: protogen.BillingProduct product = 2;
   */
  product?: BillingProduct;

  /**
   * @generated from field: protogen.BillingCoupon introCoupon = 3;
   */
  introCoupon?: BillingCoupon;

  constructor(data?: PartialMessage<FamilyBilling>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FamilyBilling";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stripeCustomerId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product", kind: "message", T: BillingProduct },
    { no: 3, name: "introCoupon", kind: "message", T: BillingCoupon },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FamilyBilling {
    return new FamilyBilling().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FamilyBilling {
    return new FamilyBilling().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FamilyBilling {
    return new FamilyBilling().fromJsonString(jsonString, options);
  }

  static equals(a: FamilyBilling | PlainMessage<FamilyBilling> | undefined, b: FamilyBilling | PlainMessage<FamilyBilling> | undefined): boolean {
    return proto3.util.equals(FamilyBilling, a, b);
  }
}

/**
 * @generated from message protogen.Conversation
 */
export class Conversation extends Message<Conversation> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string familyRef = 2;
   */
  familyRef = "";

  /**
   * enum???
   *
   * @generated from field: protogen.Medium medium = 3;
   */
  medium = Medium.UNSPECIFIED;

  /**
   * @generated from field: int64 lastUpdateSec = 4;
   */
  lastUpdateSec = protoInt64.zero;

  /**
   * @generated from field: repeated protogen.Member members = 5;
   */
  members: Member[] = [];

  constructor(data?: PartialMessage<Conversation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Conversation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "medium", kind: "enum", T: proto3.getEnumType(Medium) },
    { no: 4, name: "lastUpdateSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "members", kind: "message", T: Member, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Conversation {
    return new Conversation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Conversation {
    return new Conversation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Conversation {
    return new Conversation().fromJsonString(jsonString, options);
  }

  static equals(a: Conversation | PlainMessage<Conversation> | undefined, b: Conversation | PlainMessage<Conversation> | undefined): boolean {
    return proto3.util.equals(Conversation, a, b);
  }
}

/**
 * @generated from message protogen.ListAdvisorFamiliesRequest
 */
export class ListAdvisorFamiliesRequest extends Message<ListAdvisorFamiliesRequest> {
  constructor(data?: PartialMessage<ListAdvisorFamiliesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAdvisorFamiliesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAdvisorFamiliesRequest {
    return new ListAdvisorFamiliesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAdvisorFamiliesRequest {
    return new ListAdvisorFamiliesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAdvisorFamiliesRequest {
    return new ListAdvisorFamiliesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAdvisorFamiliesRequest | PlainMessage<ListAdvisorFamiliesRequest> | undefined, b: ListAdvisorFamiliesRequest | PlainMessage<ListAdvisorFamiliesRequest> | undefined): boolean {
    return proto3.util.equals(ListAdvisorFamiliesRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListAdvisorFamiliesResponse
 */
export class ListAdvisorFamiliesResponse extends Message<ListAdvisorFamiliesResponse> {
  /**
   * @generated from field: repeated protogen.Family families = 1;
   */
  families: Family[] = [];

  constructor(data?: PartialMessage<ListAdvisorFamiliesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAdvisorFamiliesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "families", kind: "message", T: Family, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAdvisorFamiliesResponse {
    return new ListAdvisorFamiliesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAdvisorFamiliesResponse {
    return new ListAdvisorFamiliesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAdvisorFamiliesResponse {
    return new ListAdvisorFamiliesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAdvisorFamiliesResponse | PlainMessage<ListAdvisorFamiliesResponse> | undefined, b: ListAdvisorFamiliesResponse | PlainMessage<ListAdvisorFamiliesResponse> | undefined): boolean {
    return proto3.util.equals(ListAdvisorFamiliesResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorNotificationStatusesRequest
 */
export class FetchAdvisorNotificationStatusesRequest extends Message<FetchAdvisorNotificationStatusesRequest> {
  constructor(data?: PartialMessage<FetchAdvisorNotificationStatusesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorNotificationStatusesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorNotificationStatusesRequest {
    return new FetchAdvisorNotificationStatusesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorNotificationStatusesRequest {
    return new FetchAdvisorNotificationStatusesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorNotificationStatusesRequest {
    return new FetchAdvisorNotificationStatusesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorNotificationStatusesRequest | PlainMessage<FetchAdvisorNotificationStatusesRequest> | undefined, b: FetchAdvisorNotificationStatusesRequest | PlainMessage<FetchAdvisorNotificationStatusesRequest> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorNotificationStatusesRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorNotificationStatusesResponse
 */
export class FetchAdvisorNotificationStatusesResponse extends Message<FetchAdvisorNotificationStatusesResponse> {
  /**
   * @generated from field: repeated protogen.Family families = 1;
   */
  families: Family[] = [];

  /**
   * @generated from field: repeated protogen.FetchAdvisorNotificationStatusesResponse.FamilyStatus familyStatus = 2;
   */
  familyStatus: FetchAdvisorNotificationStatusesResponse_FamilyStatus[] = [];

  /**
   * @generated from field: int32 emailUnreadCount = 3;
   */
  emailUnreadCount = 0;

  /**
   * @generated from field: int32 phoneUnreadCount = 4;
   */
  phoneUnreadCount = 0;

  /**
   * @generated from field: int32 messageUnreadCount = 5;
   */
  messageUnreadCount = 0;

  /**
   * @generated from field: int32 taskUnreadCount = 6;
   */
  taskUnreadCount = 0;

  /**
   * @generated from field: int32 badgeCount = 7;
   */
  badgeCount = 0;

  /**
   * @generated from field: int32 datebookUnreadCount = 8;
   */
  datebookUnreadCount = 0;

  constructor(data?: PartialMessage<FetchAdvisorNotificationStatusesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorNotificationStatusesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "families", kind: "message", T: Family, repeated: true },
    { no: 2, name: "familyStatus", kind: "message", T: FetchAdvisorNotificationStatusesResponse_FamilyStatus, repeated: true },
    { no: 3, name: "emailUnreadCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "phoneUnreadCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "messageUnreadCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "taskUnreadCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "badgeCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "datebookUnreadCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorNotificationStatusesResponse {
    return new FetchAdvisorNotificationStatusesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorNotificationStatusesResponse {
    return new FetchAdvisorNotificationStatusesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorNotificationStatusesResponse {
    return new FetchAdvisorNotificationStatusesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorNotificationStatusesResponse | PlainMessage<FetchAdvisorNotificationStatusesResponse> | undefined, b: FetchAdvisorNotificationStatusesResponse | PlainMessage<FetchAdvisorNotificationStatusesResponse> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorNotificationStatusesResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorNotificationStatusesResponse.FamilyStatus
 */
export class FetchAdvisorNotificationStatusesResponse_FamilyStatus extends Message<FetchAdvisorNotificationStatusesResponse_FamilyStatus> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: int32 unreadMessages = 2;
   */
  unreadMessages = 0;

  /**
   * @generated from field: int32 unreadEmails = 3;
   */
  unreadEmails = 0;

  /**
   * @generated from field: int32 missedCalls = 4;
   */
  missedCalls = 0;

  /**
   * @generated from field: int32 openTasks = 5;
   */
  openTasks = 0;

  /**
   * @generated from field: int64 lastActivitySec = 8;
   */
  lastActivitySec = protoInt64.zero;

  /**
   * @generated from field: bool needsAttention = 9;
   */
  needsAttention = false;

  constructor(data?: PartialMessage<FetchAdvisorNotificationStatusesResponse_FamilyStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorNotificationStatusesResponse.FamilyStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "unreadMessages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "unreadEmails", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "missedCalls", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "openTasks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "lastActivitySec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "needsAttention", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorNotificationStatusesResponse_FamilyStatus {
    return new FetchAdvisorNotificationStatusesResponse_FamilyStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorNotificationStatusesResponse_FamilyStatus {
    return new FetchAdvisorNotificationStatusesResponse_FamilyStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorNotificationStatusesResponse_FamilyStatus {
    return new FetchAdvisorNotificationStatusesResponse_FamilyStatus().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorNotificationStatusesResponse_FamilyStatus | PlainMessage<FetchAdvisorNotificationStatusesResponse_FamilyStatus> | undefined, b: FetchAdvisorNotificationStatusesResponse_FamilyStatus | PlainMessage<FetchAdvisorNotificationStatusesResponse_FamilyStatus> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorNotificationStatusesResponse_FamilyStatus, a, b);
  }
}

/**
 * @generated from message protogen.ListAllAdvisorsRequest
 */
export class ListAllAdvisorsRequest extends Message<ListAllAdvisorsRequest> {
  /**
   * @generated from field: bool includeStaff = 1;
   */
  includeStaff = false;

  constructor(data?: PartialMessage<ListAllAdvisorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllAdvisorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "includeStaff", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllAdvisorsRequest {
    return new ListAllAdvisorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllAdvisorsRequest {
    return new ListAllAdvisorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllAdvisorsRequest {
    return new ListAllAdvisorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllAdvisorsRequest | PlainMessage<ListAllAdvisorsRequest> | undefined, b: ListAllAdvisorsRequest | PlainMessage<ListAllAdvisorsRequest> | undefined): boolean {
    return proto3.util.equals(ListAllAdvisorsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListAllAdvisorsResponse
 */
export class ListAllAdvisorsResponse extends Message<ListAllAdvisorsResponse> {
  /**
   * @generated from field: repeated protogen.Advisor advisors = 1;
   */
  advisors: Advisor[] = [];

  constructor(data?: PartialMessage<ListAllAdvisorsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListAllAdvisorsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisors", kind: "message", T: Advisor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAllAdvisorsResponse {
    return new ListAllAdvisorsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAllAdvisorsResponse {
    return new ListAllAdvisorsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAllAdvisorsResponse {
    return new ListAllAdvisorsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAllAdvisorsResponse | PlainMessage<ListAllAdvisorsResponse> | undefined, b: ListAllAdvisorsResponse | PlainMessage<ListAllAdvisorsResponse> | undefined): boolean {
    return proto3.util.equals(ListAllAdvisorsResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyRequest
 */
export class CreateFamilyRequest extends Message<CreateFamilyRequest> {
  /**
   * @generated from field: string familyName = 1;
   */
  familyName = "";

  /**
   * @generated from field: string advisorRef = 3;
   */
  advisorRef = "";

  /**
   * @generated from field: protogen.FamilyStatus status = 4;
   */
  status = FamilyStatus.NOT_SET;

  /**
   * @generated from field: string startDate = 5;
   */
  startDate = "";

  /**
   * @generated from field: repeated protogen.CreateFamilyRequest.Member members = 6;
   */
  members: CreateFamilyRequest_Member[] = [];

  /**
   * @generated from field: protogen.BillingProduct product = 7;
   */
  product?: BillingProduct;

  /**
   * @generated from field: protogen.BillingCoupon introCoupon = 8;
   */
  introCoupon?: BillingCoupon;

  /**
   * @generated from field: protogen.PlatformFeeSchedule platformFeeSchedule = 9;
   */
  platformFeeSchedule = PlatformFeeSchedule.SCHEDULE_NOT_SET;

  /**
   * @generated from field: string stripeCustomerId = 10;
   */
  stripeCustomerId = "";

  constructor(data?: PartialMessage<CreateFamilyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(FamilyStatus) },
    { no: 5, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "members", kind: "message", T: CreateFamilyRequest_Member, repeated: true },
    { no: 7, name: "product", kind: "message", T: BillingProduct },
    { no: 8, name: "introCoupon", kind: "message", T: BillingCoupon },
    { no: 9, name: "platformFeeSchedule", kind: "enum", T: proto3.getEnumType(PlatformFeeSchedule) },
    { no: 10, name: "stripeCustomerId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyRequest {
    return new CreateFamilyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyRequest {
    return new CreateFamilyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyRequest {
    return new CreateFamilyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyRequest | PlainMessage<CreateFamilyRequest> | undefined, b: CreateFamilyRequest | PlainMessage<CreateFamilyRequest> | undefined): boolean {
    return proto3.util.equals(CreateFamilyRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyRequest.Member
 */
export class CreateFamilyRequest_Member extends Message<CreateFamilyRequest_Member> {
  /**
   * @generated from field: string firstName = 1;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 2;
   */
  lastName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone = 4;
   */
  phone = "";

  /**
   * @generated from field: repeated string altEmails = 5;
   */
  altEmails: string[] = [];

  /**
   * @generated from field: string timezone = 6;
   */
  timezone = "";

  constructor(data?: PartialMessage<CreateFamilyRequest_Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyRequest.Member";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "altEmails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyRequest_Member {
    return new CreateFamilyRequest_Member().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyRequest_Member {
    return new CreateFamilyRequest_Member().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyRequest_Member {
    return new CreateFamilyRequest_Member().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyRequest_Member | PlainMessage<CreateFamilyRequest_Member> | undefined, b: CreateFamilyRequest_Member | PlainMessage<CreateFamilyRequest_Member> | undefined): boolean {
    return proto3.util.equals(CreateFamilyRequest_Member, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyResponse
 */
export class CreateFamilyResponse extends Message<CreateFamilyResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  constructor(data?: PartialMessage<CreateFamilyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyResponse {
    return new CreateFamilyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyResponse {
    return new CreateFamilyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyResponse {
    return new CreateFamilyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyResponse | PlainMessage<CreateFamilyResponse> | undefined, b: CreateFamilyResponse | PlainMessage<CreateFamilyResponse> | undefined): boolean {
    return proto3.util.equals(CreateFamilyResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyRequest
 */
export class UpdateFamilyRequest extends Message<UpdateFamilyRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string updatedName = 2;
   */
  updatedName = "";

  /**
   * @generated from field: string updatedAdvisorRef = 4;
   */
  updatedAdvisorRef = "";

  /**
   * @generated from field: string updatedStartDate = 5;
   */
  updatedStartDate = "";

  /**
   * @generated from field: bool clearStartDate = 6;
   */
  clearStartDate = false;

  /**
   * @generated from field: protogen.FamilyStatus updatedStatus = 7;
   */
  updatedStatus = FamilyStatus.NOT_SET;

  /**
   * @generated from field: repeated protogen.UpdateFamilyRequest.Member updatedMembers = 8;
   */
  updatedMembers: UpdateFamilyRequest_Member[] = [];

  /**
   * @generated from field: repeated protogen.UpdateFamilyRequest.Member newMembers = 9;
   */
  newMembers: UpdateFamilyRequest_Member[] = [];

  /**
   * @generated from field: repeated protogen.UpdateFamilyRequest.UpdatedPermissions updatedPermissions = 10;
   */
  updatedPermissions: UpdateFamilyRequest_UpdatedPermissions[] = [];

  /**
   * @generated from field: string updatedAddress = 12;
   */
  updatedAddress = "";

  /**
   * @generated from field: protogen.BillingProduct updatedProduct = 15;
   */
  updatedProduct?: BillingProduct;

  /**
   * @generated from field: protogen.BillingCoupon updatedIntroCoupon = 16;
   */
  updatedIntroCoupon?: BillingCoupon;

  /**
   * @generated from field: bool nullifyCoupon = 17;
   */
  nullifyCoupon = false;

  /**
   * @generated from field: protogen.PlatformFeeSchedule platformFeeSchedule = 18;
   */
  platformFeeSchedule = PlatformFeeSchedule.SCHEDULE_NOT_SET;

  /**
   * @generated from field: string updatedEndDate = 19;
   */
  updatedEndDate = "";

  /**
   * @generated from field: bool clearEndDate = 20;
   */
  clearEndDate = false;

  /**
   * @generated from field: string setStripeCustomerId = 21;
   */
  setStripeCustomerId = "";

  /**
   * @generated from field: bool shouldUpdateMetadata = 22;
   */
  shouldUpdateMetadata = false;

  /**
   * @generated from field: protogen.EntityMetadata updatedMetadata = 23;
   */
  updatedMetadata?: EntityMetadata;

  constructor(data?: PartialMessage<UpdateFamilyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updatedAdvisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updatedStartDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "clearStartDate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "updatedStatus", kind: "enum", T: proto3.getEnumType(FamilyStatus) },
    { no: 8, name: "updatedMembers", kind: "message", T: UpdateFamilyRequest_Member, repeated: true },
    { no: 9, name: "newMembers", kind: "message", T: UpdateFamilyRequest_Member, repeated: true },
    { no: 10, name: "updatedPermissions", kind: "message", T: UpdateFamilyRequest_UpdatedPermissions, repeated: true },
    { no: 12, name: "updatedAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "updatedProduct", kind: "message", T: BillingProduct },
    { no: 16, name: "updatedIntroCoupon", kind: "message", T: BillingCoupon },
    { no: 17, name: "nullifyCoupon", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "platformFeeSchedule", kind: "enum", T: proto3.getEnumType(PlatformFeeSchedule) },
    { no: 19, name: "updatedEndDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "clearEndDate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "setStripeCustomerId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "shouldUpdateMetadata", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "updatedMetadata", kind: "message", T: EntityMetadata },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyRequest {
    return new UpdateFamilyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyRequest {
    return new UpdateFamilyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyRequest {
    return new UpdateFamilyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyRequest | PlainMessage<UpdateFamilyRequest> | undefined, b: UpdateFamilyRequest | PlainMessage<UpdateFamilyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyRequest.Member
 */
export class UpdateFamilyRequest_Member extends Message<UpdateFamilyRequest_Member> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string updatedFirstName = 2;
   */
  updatedFirstName = "";

  /**
   * @generated from field: string updatedLastName = 3;
   */
  updatedLastName = "";

  /**
   * @generated from field: string updatedEmail = 4;
   */
  updatedEmail = "";

  /**
   * @generated from field: string updatedPhone = 5;
   */
  updatedPhone = "";

  /**
   * @generated from field: bool shouldUpdateAltEmails = 6;
   */
  shouldUpdateAltEmails = false;

  /**
   * @generated from field: repeated string updatedAltEmails = 7;
   */
  updatedAltEmails: string[] = [];

  /**
   * @generated from field: string updatedTimezone = 8;
   */
  updatedTimezone = "";

  constructor(data?: PartialMessage<UpdateFamilyRequest_Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyRequest.Member";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedFirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updatedLastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updatedEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updatedPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "shouldUpdateAltEmails", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "updatedAltEmails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "updatedTimezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyRequest_Member {
    return new UpdateFamilyRequest_Member().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyRequest_Member {
    return new UpdateFamilyRequest_Member().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyRequest_Member {
    return new UpdateFamilyRequest_Member().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyRequest_Member | PlainMessage<UpdateFamilyRequest_Member> | undefined, b: UpdateFamilyRequest_Member | PlainMessage<UpdateFamilyRequest_Member> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyRequest_Member, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyRequest.UpdatedPermissions
 */
export class UpdateFamilyRequest_UpdatedPermissions extends Message<UpdateFamilyRequest_UpdatedPermissions> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string accountRef = 2;
   */
  accountRef = "";

  /**
   * @generated from field: int64 expiration = 3;
   */
  expiration = protoInt64.zero;

  /**
   * @generated from field: map<string, bool> actions = 4;
   */
  actions: { [key: string]: boolean } = {};

  /**
   * Mutually exclusive with isCreation / reactivate
   *
   * @generated from field: bool deactivate = 5;
   */
  deactivate = false;

  /**
   * Mutually exclusive with ref
   *
   * @generated from field: bool isCreation = 6;
   */
  isCreation = false;

  /**
   * Mutually exclusive with isCreation / deactivate
   *
   * @generated from field: bool reactivate = 7;
   */
  reactivate = false;

  constructor(data?: PartialMessage<UpdateFamilyRequest_UpdatedPermissions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyRequest.UpdatedPermissions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "accountRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expiration", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "actions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 8 /* ScalarType.BOOL */} },
    { no: 5, name: "deactivate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "isCreation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "reactivate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyRequest_UpdatedPermissions {
    return new UpdateFamilyRequest_UpdatedPermissions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyRequest_UpdatedPermissions {
    return new UpdateFamilyRequest_UpdatedPermissions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyRequest_UpdatedPermissions {
    return new UpdateFamilyRequest_UpdatedPermissions().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyRequest_UpdatedPermissions | PlainMessage<UpdateFamilyRequest_UpdatedPermissions> | undefined, b: UpdateFamilyRequest_UpdatedPermissions | PlainMessage<UpdateFamilyRequest_UpdatedPermissions> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyRequest_UpdatedPermissions, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyResponse
 */
export class UpdateFamilyResponse extends Message<UpdateFamilyResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  /**
   * @generated from field: repeated protogen.UserPermission advisorPermissions = 2;
   */
  advisorPermissions: UserPermission[] = [];

  constructor(data?: PartialMessage<UpdateFamilyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
    { no: 2, name: "advisorPermissions", kind: "message", T: UserPermission, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyResponse {
    return new UpdateFamilyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyResponse {
    return new UpdateFamilyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyResponse {
    return new UpdateFamilyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyResponse | PlainMessage<UpdateFamilyResponse> | undefined, b: UpdateFamilyResponse | PlainMessage<UpdateFamilyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyResponse, a, b);
  }
}

/**
 * @generated from message protogen.FeedEntry
 */
export class FeedEntry extends Message<FeedEntry> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string senderRef = 2;
   */
  senderRef = "";

  /**
   * @generated from field: int64 timestampSec = 3;
   */
  timestampSec = protoInt64.zero;

  /**
   * @generated from field: protogen.Medium medium = 4;
   */
  medium = Medium.UNSPECIFIED;

  /**
   * oneof content ???
   *
   * @generated from field: protogen.TextMessage textMessage = 5;
   */
  textMessage?: TextMessage;

  /**
   * @generated from field: protogen.EmailMessage emailMessage = 6;
   */
  emailMessage?: EmailMessage;

  /**
   * @generated from field: protogen.PhoneCall phoneCall = 7;
   */
  phoneCall?: PhoneCall;

  /**
   * Used to differentiate which user a message was sent to.
   *
   * @generated from field: repeated string recipientRefs = 8;
   */
  recipientRefs: string[] = [];

  /**
   * @generated from field: int64 session = 9;
   */
  session = protoInt64.zero;

  constructor(data?: PartialMessage<FeedEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FeedEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "senderRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "timestampSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "medium", kind: "enum", T: proto3.getEnumType(Medium) },
    { no: 5, name: "textMessage", kind: "message", T: TextMessage },
    { no: 6, name: "emailMessage", kind: "message", T: EmailMessage },
    { no: 7, name: "phoneCall", kind: "message", T: PhoneCall },
    { no: 8, name: "recipientRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "session", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeedEntry {
    return new FeedEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeedEntry {
    return new FeedEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeedEntry {
    return new FeedEntry().fromJsonString(jsonString, options);
  }

  static equals(a: FeedEntry | PlainMessage<FeedEntry> | undefined, b: FeedEntry | PlainMessage<FeedEntry> | undefined): boolean {
    return proto3.util.equals(FeedEntry, a, b);
  }
}

/**
 * @generated from message protogen.GetFamilyDetailsRequest
 */
export class GetFamilyDetailsRequest extends Message<GetFamilyDetailsRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  constructor(data?: PartialMessage<GetFamilyDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetFamilyDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFamilyDetailsRequest {
    return new GetFamilyDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFamilyDetailsRequest {
    return new GetFamilyDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFamilyDetailsRequest {
    return new GetFamilyDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFamilyDetailsRequest | PlainMessage<GetFamilyDetailsRequest> | undefined, b: GetFamilyDetailsRequest | PlainMessage<GetFamilyDetailsRequest> | undefined): boolean {
    return proto3.util.equals(GetFamilyDetailsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetFamilyDetailsResponse
 */
export class GetFamilyDetailsResponse extends Message<GetFamilyDetailsResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  /**
   * @generated from field: protogen.Note familyNotes = 2;
   */
  familyNotes?: Note;

  /**
   * @generated from field: repeated protogen.Task activeTasks = 3;
   */
  activeTasks: Task[] = [];

  /**
   * @generated from field: repeated protogen.SecretStub secrets = 4;
   */
  secrets: SecretStub[] = [];

  /**
   * @generated from field: repeated protogen.FactStub facts = 6;
   */
  facts: FactStub[] = [];

  /**
   * @generated from field: repeated protogen.FactSectionStub factSections = 7;
   */
  factSections: FactSectionStub[] = [];

  /**
   * @generated from field: repeated protogen.UserPermission advisorPermissions = 8;
   */
  advisorPermissions: UserPermission[] = [];

  /**
   * @generated from field: protogen.Advisor primaryAdvisor = 9;
   */
  primaryAdvisor?: Advisor;

  /**
   * @generated from field: protogen.PlanUtilization planUtilization = 10;
   */
  planUtilization?: PlanUtilization;

  constructor(data?: PartialMessage<GetFamilyDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetFamilyDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
    { no: 2, name: "familyNotes", kind: "message", T: Note },
    { no: 3, name: "activeTasks", kind: "message", T: Task, repeated: true },
    { no: 4, name: "secrets", kind: "message", T: SecretStub, repeated: true },
    { no: 6, name: "facts", kind: "message", T: FactStub, repeated: true },
    { no: 7, name: "factSections", kind: "message", T: FactSectionStub, repeated: true },
    { no: 8, name: "advisorPermissions", kind: "message", T: UserPermission, repeated: true },
    { no: 9, name: "primaryAdvisor", kind: "message", T: Advisor },
    { no: 10, name: "planUtilization", kind: "message", T: PlanUtilization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFamilyDetailsResponse {
    return new GetFamilyDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFamilyDetailsResponse {
    return new GetFamilyDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFamilyDetailsResponse {
    return new GetFamilyDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFamilyDetailsResponse | PlainMessage<GetFamilyDetailsResponse> | undefined, b: GetFamilyDetailsResponse | PlainMessage<GetFamilyDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetFamilyDetailsResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetFamilyActivityRequest
 */
export class GetFamilyActivityRequest extends Message<GetFamilyActivityRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * Need to enrich this.
   *
   * @generated from field: string startCursor = 2;
   */
  startCursor = "";

  /**
   * @generated from field: bool markConversationsRead = 3;
   */
  markConversationsRead = false;

  /**
   * @generated from field: int32 pageSize = 4;
   */
  pageSize = 0;

  /**
   * @generated from field: string direction = 5;
   */
  direction = "";

  constructor(data?: PartialMessage<GetFamilyActivityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetFamilyActivityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "startCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "markConversationsRead", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "pageSize", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "direction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFamilyActivityRequest {
    return new GetFamilyActivityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFamilyActivityRequest {
    return new GetFamilyActivityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFamilyActivityRequest {
    return new GetFamilyActivityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFamilyActivityRequest | PlainMessage<GetFamilyActivityRequest> | undefined, b: GetFamilyActivityRequest | PlainMessage<GetFamilyActivityRequest> | undefined): boolean {
    return proto3.util.equals(GetFamilyActivityRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetFamilyActivityResponse
 */
export class GetFamilyActivityResponse extends Message<GetFamilyActivityResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  /**
   * @generated from field: repeated protogen.Conversation conversations = 2;
   */
  conversations: Conversation[] = [];

  /**
   * @generated from field: repeated protogen.FeedEntry entries = 3;
   */
  entries: FeedEntry[] = [];

  /**
   * @generated from field: repeated protogen.AccountStub accounts = 4;
   */
  accounts: AccountStub[] = [];

  /**
   * @generated from field: string startCursor = 5;
   */
  startCursor = "";

  /**
   * @generated from field: string nextCursor = 6;
   */
  nextCursor = "";

  /**
   * @generated from field: bool hasMore = 7;
   */
  hasMore = false;

  constructor(data?: PartialMessage<GetFamilyActivityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetFamilyActivityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
    { no: 2, name: "conversations", kind: "message", T: Conversation, repeated: true },
    { no: 3, name: "entries", kind: "message", T: FeedEntry, repeated: true },
    { no: 4, name: "accounts", kind: "message", T: AccountStub, repeated: true },
    { no: 5, name: "startCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "hasMore", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFamilyActivityResponse {
    return new GetFamilyActivityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFamilyActivityResponse {
    return new GetFamilyActivityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFamilyActivityResponse {
    return new GetFamilyActivityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFamilyActivityResponse | PlainMessage<GetFamilyActivityResponse> | undefined, b: GetFamilyActivityResponse | PlainMessage<GetFamilyActivityResponse> | undefined): boolean {
    return proto3.util.equals(GetFamilyActivityResponse, a, b);
  }
}

/**
 * @generated from message protogen.ActivitySubscription
 */
export class ActivitySubscription extends Message<ActivitySubscription> {
  /**
   * @generated from field: string entityType = 1;
   */
  entityType = "";

  /**
   * @generated from field: string entityRef = 2;
   */
  entityRef = "";

  /**
   * @generated from field: bool pushEnabled = 3;
   */
  pushEnabled = false;

  /**
   * nullable
   *
   * @generated from field: protogen.FamilyStub family = 5;
   */
  family?: FamilyStub;

  constructor(data?: PartialMessage<ActivitySubscription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ActivitySubscription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "pushEnabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "family", kind: "message", T: FamilyStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivitySubscription {
    return new ActivitySubscription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivitySubscription {
    return new ActivitySubscription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivitySubscription {
    return new ActivitySubscription().fromJsonString(jsonString, options);
  }

  static equals(a: ActivitySubscription | PlainMessage<ActivitySubscription> | undefined, b: ActivitySubscription | PlainMessage<ActivitySubscription> | undefined): boolean {
    return proto3.util.equals(ActivitySubscription, a, b);
  }
}

/**
 * @generated from message protogen.ListActivitySubscriptionsRequest
 */
export class ListActivitySubscriptionsRequest extends Message<ListActivitySubscriptionsRequest> {
  constructor(data?: PartialMessage<ListActivitySubscriptionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListActivitySubscriptionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListActivitySubscriptionsRequest {
    return new ListActivitySubscriptionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListActivitySubscriptionsRequest {
    return new ListActivitySubscriptionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListActivitySubscriptionsRequest {
    return new ListActivitySubscriptionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListActivitySubscriptionsRequest | PlainMessage<ListActivitySubscriptionsRequest> | undefined, b: ListActivitySubscriptionsRequest | PlainMessage<ListActivitySubscriptionsRequest> | undefined): boolean {
    return proto3.util.equals(ListActivitySubscriptionsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListActivitySubscriptionsResponse
 */
export class ListActivitySubscriptionsResponse extends Message<ListActivitySubscriptionsResponse> {
  /**
   * @generated from field: repeated protogen.ActivitySubscription subscriptions = 1;
   */
  subscriptions: ActivitySubscription[] = [];

  constructor(data?: PartialMessage<ListActivitySubscriptionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListActivitySubscriptionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subscriptions", kind: "message", T: ActivitySubscription, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListActivitySubscriptionsResponse {
    return new ListActivitySubscriptionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListActivitySubscriptionsResponse {
    return new ListActivitySubscriptionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListActivitySubscriptionsResponse {
    return new ListActivitySubscriptionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListActivitySubscriptionsResponse | PlainMessage<ListActivitySubscriptionsResponse> | undefined, b: ListActivitySubscriptionsResponse | PlainMessage<ListActivitySubscriptionsResponse> | undefined): boolean {
    return proto3.util.equals(ListActivitySubscriptionsResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateAdvisorRequest
 */
export class UpdateAdvisorRequest extends Message<UpdateAdvisorRequest> {
  /**
   * @generated from field: string advisorRef = 1;
   */
  advisorRef = "";

  /**
   * @generated from field: string updatedFirstName = 2;
   */
  updatedFirstName = "";

  /**
   * @generated from field: string updatedLastName = 3;
   */
  updatedLastName = "";

  /**
   * @generated from field: string updatedPersonalEmail = 4;
   */
  updatedPersonalEmail = "";

  /**
   * @generated from field: string updatedPersonalPhone = 5;
   */
  updatedPersonalPhone = "";

  /**
   * @generated from field: protogen.ContentUpdates body = 6;
   */
  body?: ContentUpdates;

  /**
   * @generated from field: protogen.UploadAttachment profileImage = 7;
   */
  profileImage?: UploadAttachment;

  /**
   * @generated from field: bool isProfilePublic = 8;
   */
  isProfilePublic = false;

  /**
   * @generated from field: bool shouldUpdateProfileImage = 9;
   */
  shouldUpdateProfileImage = false;

  /**
   * @generated from field: repeated protogen.ExternalLinks externalLinks = 10;
   */
  externalLinks: ExternalLinks[] = [];

  /**
   * @generated from field: string updatedTimezone = 11;
   */
  updatedTimezone = "";

  /**
   * @generated from field: repeated string updatedSpecialties = 12;
   */
  updatedSpecialties: string[] = [];

  /**
   * @generated from field: protogen.AdvisorStatus updatedStatus = 13;
   */
  updatedStatus = AdvisorStatus.NOT_SET;

  /**
   * @generated from field: string updatedAddress = 14;
   */
  updatedAddress = "";

  /**
   * @generated from field: bool shouldUpdateEmailNotifications = 15;
   */
  shouldUpdateEmailNotifications = false;

  /**
   * @generated from field: bool emailNotificationsEnabled = 16;
   */
  emailNotificationsEnabled = false;

  /**
   * @generated from field: bool shouldUpdateMetadata = 17;
   */
  shouldUpdateMetadata = false;

  /**
   * @generated from field: protogen.EntityMetadata updatedMetadata = 18;
   */
  updatedMetadata?: EntityMetadata;

  constructor(data?: PartialMessage<UpdateAdvisorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateAdvisorRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedFirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updatedLastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updatedPersonalEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updatedPersonalPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "body", kind: "message", T: ContentUpdates },
    { no: 7, name: "profileImage", kind: "message", T: UploadAttachment },
    { no: 8, name: "isProfilePublic", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "shouldUpdateProfileImage", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "externalLinks", kind: "message", T: ExternalLinks, repeated: true },
    { no: 11, name: "updatedTimezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "updatedSpecialties", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "updatedStatus", kind: "enum", T: proto3.getEnumType(AdvisorStatus) },
    { no: 14, name: "updatedAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "shouldUpdateEmailNotifications", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "emailNotificationsEnabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "shouldUpdateMetadata", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "updatedMetadata", kind: "message", T: EntityMetadata },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAdvisorRequest {
    return new UpdateAdvisorRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAdvisorRequest {
    return new UpdateAdvisorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAdvisorRequest {
    return new UpdateAdvisorRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAdvisorRequest | PlainMessage<UpdateAdvisorRequest> | undefined, b: UpdateAdvisorRequest | PlainMessage<UpdateAdvisorRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAdvisorRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateAdvisorResponse
 */
export class UpdateAdvisorResponse extends Message<UpdateAdvisorResponse> {
  /**
   * @generated from field: protogen.Advisor advisor = 1;
   */
  advisor?: Advisor;

  constructor(data?: PartialMessage<UpdateAdvisorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateAdvisorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisor", kind: "message", T: Advisor },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAdvisorResponse {
    return new UpdateAdvisorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAdvisorResponse {
    return new UpdateAdvisorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAdvisorResponse {
    return new UpdateAdvisorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAdvisorResponse | PlainMessage<UpdateAdvisorResponse> | undefined, b: UpdateAdvisorResponse | PlainMessage<UpdateAdvisorResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAdvisorResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateActivitySubscriptionRequest
 */
export class UpdateActivitySubscriptionRequest extends Message<UpdateActivitySubscriptionRequest> {
  /**
   * @generated from field: string entityType = 1;
   */
  entityType = "";

  /**
   * @generated from field: string entityRef = 2;
   */
  entityRef = "";

  /**
   * @generated from field: bool updatePush = 3;
   */
  updatePush = false;

  /**
   * @generated from field: bool pushEnabled = 4;
   */
  pushEnabled = false;

  constructor(data?: PartialMessage<UpdateActivitySubscriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateActivitySubscriptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updatePush", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "pushEnabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateActivitySubscriptionRequest {
    return new UpdateActivitySubscriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateActivitySubscriptionRequest {
    return new UpdateActivitySubscriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateActivitySubscriptionRequest {
    return new UpdateActivitySubscriptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateActivitySubscriptionRequest | PlainMessage<UpdateActivitySubscriptionRequest> | undefined, b: UpdateActivitySubscriptionRequest | PlainMessage<UpdateActivitySubscriptionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateActivitySubscriptionRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateActivitySubscriptionResponse
 */
export class UpdateActivitySubscriptionResponse extends Message<UpdateActivitySubscriptionResponse> {
  /**
   * @generated from field: protogen.ActivitySubscription subscription = 1;
   */
  subscription?: ActivitySubscription;

  constructor(data?: PartialMessage<UpdateActivitySubscriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateActivitySubscriptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subscription", kind: "message", T: ActivitySubscription },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateActivitySubscriptionResponse {
    return new UpdateActivitySubscriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateActivitySubscriptionResponse {
    return new UpdateActivitySubscriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateActivitySubscriptionResponse {
    return new UpdateActivitySubscriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateActivitySubscriptionResponse | PlainMessage<UpdateActivitySubscriptionResponse> | undefined, b: UpdateActivitySubscriptionResponse | PlainMessage<UpdateActivitySubscriptionResponse> | undefined): boolean {
    return proto3.util.equals(UpdateActivitySubscriptionResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorProfileRequest
 */
export class FetchAdvisorProfileRequest extends Message<FetchAdvisorProfileRequest> {
  /**
   * @generated from field: string advisorRef = 1;
   */
  advisorRef = "";

  constructor(data?: PartialMessage<FetchAdvisorProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorProfileRequest {
    return new FetchAdvisorProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorProfileRequest {
    return new FetchAdvisorProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorProfileRequest {
    return new FetchAdvisorProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorProfileRequest | PlainMessage<FetchAdvisorProfileRequest> | undefined, b: FetchAdvisorProfileRequest | PlainMessage<FetchAdvisorProfileRequest> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorProfileRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchAdvisorProfileResponse
 */
export class FetchAdvisorProfileResponse extends Message<FetchAdvisorProfileResponse> {
  /**
   * @generated from field: protogen.Advisor advisor = 1;
   */
  advisor?: Advisor;

  constructor(data?: PartialMessage<FetchAdvisorProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchAdvisorProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisor", kind: "message", T: Advisor },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAdvisorProfileResponse {
    return new FetchAdvisorProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAdvisorProfileResponse {
    return new FetchAdvisorProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAdvisorProfileResponse {
    return new FetchAdvisorProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAdvisorProfileResponse | PlainMessage<FetchAdvisorProfileResponse> | undefined, b: FetchAdvisorProfileResponse | PlainMessage<FetchAdvisorProfileResponse> | undefined): boolean {
    return proto3.util.equals(FetchAdvisorProfileResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetConversationFeedRequest
 */
export class GetConversationFeedRequest extends Message<GetConversationFeedRequest> {
  /**
   * @generated from field: string conversationRef = 1;
   */
  conversationRef = "";

  /**
   * Need to enrich this.
   *
   * @generated from field: string startCursor = 2;
   */
  startCursor = "";

  /**
   * @generated from field: bool markConversationsRead = 3;
   */
  markConversationsRead = false;

  constructor(data?: PartialMessage<GetConversationFeedRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetConversationFeedRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "conversationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "startCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "markConversationsRead", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConversationFeedRequest {
    return new GetConversationFeedRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConversationFeedRequest {
    return new GetConversationFeedRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConversationFeedRequest {
    return new GetConversationFeedRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetConversationFeedRequest | PlainMessage<GetConversationFeedRequest> | undefined, b: GetConversationFeedRequest | PlainMessage<GetConversationFeedRequest> | undefined): boolean {
    return proto3.util.equals(GetConversationFeedRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetConversationFeedResponse
 */
export class GetConversationFeedResponse extends Message<GetConversationFeedResponse> {
  /**
   * @generated from field: repeated protogen.FeedEntry entries = 1;
   */
  entries: FeedEntry[] = [];

  /**
   * @generated from field: repeated protogen.AccountStub accounts = 2;
   */
  accounts: AccountStub[] = [];

  /**
   * @generated from field: string startCursor = 3;
   */
  startCursor = "";

  /**
   * @generated from field: string nextCursor = 4;
   */
  nextCursor = "";

  /**
   * @generated from field: bool hasMore = 5;
   */
  hasMore = false;

  /**
   * @generated from field: protogen.AccountStub contact = 6;
   */
  contact?: AccountStub;

  constructor(data?: PartialMessage<GetConversationFeedResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetConversationFeedResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: FeedEntry, repeated: true },
    { no: 2, name: "accounts", kind: "message", T: AccountStub, repeated: true },
    { no: 3, name: "startCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "hasMore", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "contact", kind: "message", T: AccountStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConversationFeedResponse {
    return new GetConversationFeedResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConversationFeedResponse {
    return new GetConversationFeedResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConversationFeedResponse {
    return new GetConversationFeedResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetConversationFeedResponse | PlainMessage<GetConversationFeedResponse> | undefined, b: GetConversationFeedResponse | PlainMessage<GetConversationFeedResponse> | undefined): boolean {
    return proto3.util.equals(GetConversationFeedResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchPhoneInboxRequest
 */
export class FetchPhoneInboxRequest extends Message<FetchPhoneInboxRequest> {
  /**
   * @generated from field: string cursor = 1;
   */
  cursor = "";

  constructor(data?: PartialMessage<FetchPhoneInboxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchPhoneInboxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchPhoneInboxRequest {
    return new FetchPhoneInboxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchPhoneInboxRequest {
    return new FetchPhoneInboxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchPhoneInboxRequest {
    return new FetchPhoneInboxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchPhoneInboxRequest | PlainMessage<FetchPhoneInboxRequest> | undefined, b: FetchPhoneInboxRequest | PlainMessage<FetchPhoneInboxRequest> | undefined): boolean {
    return proto3.util.equals(FetchPhoneInboxRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchPhoneInboxResponse
 */
export class FetchPhoneInboxResponse extends Message<FetchPhoneInboxResponse> {
  /**
   * @generated from field: repeated protogen.FetchPhoneInboxResponse.InboxEntry entries = 1;
   */
  entries: FetchPhoneInboxResponse_InboxEntry[] = [];

  /**
   * @generated from field: string nextCursor = 2;
   */
  nextCursor = "";

  constructor(data?: PartialMessage<FetchPhoneInboxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchPhoneInboxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: FetchPhoneInboxResponse_InboxEntry, repeated: true },
    { no: 2, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchPhoneInboxResponse {
    return new FetchPhoneInboxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchPhoneInboxResponse {
    return new FetchPhoneInboxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchPhoneInboxResponse {
    return new FetchPhoneInboxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchPhoneInboxResponse | PlainMessage<FetchPhoneInboxResponse> | undefined, b: FetchPhoneInboxResponse | PlainMessage<FetchPhoneInboxResponse> | undefined): boolean {
    return proto3.util.equals(FetchPhoneInboxResponse, a, b);
  }
}

/**
 * @generated from enum protogen.FetchPhoneInboxResponse.InboxEntryType
 */
export enum FetchPhoneInboxResponse_InboxEntryType {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: TEXT = 1;
   */
  TEXT = 1,

  /**
   * @generated from enum value: CALL = 2;
   */
  CALL = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(FetchPhoneInboxResponse_InboxEntryType)
proto3.util.setEnumType(FetchPhoneInboxResponse_InboxEntryType, "protogen.FetchPhoneInboxResponse.InboxEntryType", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "TEXT" },
  { no: 2, name: "CALL" },
]);

/**
 * @generated from enum protogen.FetchPhoneInboxResponse.InboxTreatment
 */
export enum FetchPhoneInboxResponse_InboxTreatment {
  /**
   * @generated from enum value: NONE = 0;
   */
  NONE = 0,

  /**
   * @generated from enum value: UNREAD = 1;
   */
  UNREAD = 1,

  /**
   * @generated from enum value: MISSED = 2;
   */
  MISSED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(FetchPhoneInboxResponse_InboxTreatment)
proto3.util.setEnumType(FetchPhoneInboxResponse_InboxTreatment, "protogen.FetchPhoneInboxResponse.InboxTreatment", [
  { no: 0, name: "NONE" },
  { no: 1, name: "UNREAD" },
  { no: 2, name: "MISSED" },
]);

/**
 * @generated from message protogen.FetchPhoneInboxResponse.InboxEntry
 */
export class FetchPhoneInboxResponse_InboxEntry extends Message<FetchPhoneInboxResponse_InboxEntry> {
  /**
   * @generated from field: protogen.FetchPhoneInboxResponse.InboxEntryType type = 1;
   */
  type = FetchPhoneInboxResponse_InboxEntryType.UNKNOWN;

  /**
   * @generated from field: string ref = 2;
   */
  ref = "";

  /**
   * @generated from field: protogen.AccountStub sender = 3;
   */
  sender?: AccountStub;

  /**
   * @generated from field: protogen.AccountStub recipient = 4;
   */
  recipient?: AccountStub;

  /**
   * @generated from field: int64 timestampSec = 6;
   */
  timestampSec = protoInt64.zero;

  /**
   * @generated from field: protogen.FetchPhoneInboxResponse.InboxTreatment treatment = 7;
   */
  treatment = FetchPhoneInboxResponse_InboxTreatment.NONE;

  /**
   * @generated from field: int32 unreadCount = 8;
   */
  unreadCount = 0;

  /**
   * @generated from field: string title = 9;
   */
  title = "";

  /**
   * Only present if the thread is associated with a family.
   *
   * @generated from field: protogen.FamilyStub family = 10;
   */
  family?: FamilyStub;

  /**
   * @generated from field: bool isGroupConversation = 11;
   */
  isGroupConversation = false;

  constructor(data?: PartialMessage<FetchPhoneInboxResponse_InboxEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchPhoneInboxResponse.InboxEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(FetchPhoneInboxResponse_InboxEntryType) },
    { no: 2, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sender", kind: "message", T: AccountStub },
    { no: 4, name: "recipient", kind: "message", T: AccountStub },
    { no: 6, name: "timestampSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "treatment", kind: "enum", T: proto3.getEnumType(FetchPhoneInboxResponse_InboxTreatment) },
    { no: 8, name: "unreadCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "family", kind: "message", T: FamilyStub },
    { no: 11, name: "isGroupConversation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchPhoneInboxResponse_InboxEntry {
    return new FetchPhoneInboxResponse_InboxEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchPhoneInboxResponse_InboxEntry {
    return new FetchPhoneInboxResponse_InboxEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchPhoneInboxResponse_InboxEntry {
    return new FetchPhoneInboxResponse_InboxEntry().fromJsonString(jsonString, options);
  }

  static equals(a: FetchPhoneInboxResponse_InboxEntry | PlainMessage<FetchPhoneInboxResponse_InboxEntry> | undefined, b: FetchPhoneInboxResponse_InboxEntry | PlainMessage<FetchPhoneInboxResponse_InboxEntry> | undefined): boolean {
    return proto3.util.equals(FetchPhoneInboxResponse_InboxEntry, a, b);
  }
}

/**
 * @generated from message protogen.StartExternalConversationRequest
 */
export class StartExternalConversationRequest extends Message<StartExternalConversationRequest> {
  /**
   * @generated from field: string phoneNumber = 1;
   */
  phoneNumber = "";

  /**
   * @generated from field: string displayName = 2;
   */
  displayName = "";

  /**
   * @generated from field: string firstName = 3;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 4;
   */
  lastName = "";

  constructor(data?: PartialMessage<StartExternalConversationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.StartExternalConversationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phoneNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "displayName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartExternalConversationRequest {
    return new StartExternalConversationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartExternalConversationRequest {
    return new StartExternalConversationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartExternalConversationRequest {
    return new StartExternalConversationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartExternalConversationRequest | PlainMessage<StartExternalConversationRequest> | undefined, b: StartExternalConversationRequest | PlainMessage<StartExternalConversationRequest> | undefined): boolean {
    return proto3.util.equals(StartExternalConversationRequest, a, b);
  }
}

/**
 * @generated from message protogen.StartExternalConversationResponse
 */
export class StartExternalConversationResponse extends Message<StartExternalConversationResponse> {
  /**
   * @generated from field: string conversationRef = 1;
   */
  conversationRef = "";

  /**
   * @generated from field: protogen.AccountStub contact = 2;
   */
  contact?: AccountStub;

  constructor(data?: PartialMessage<StartExternalConversationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.StartExternalConversationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "conversationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contact", kind: "message", T: AccountStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartExternalConversationResponse {
    return new StartExternalConversationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartExternalConversationResponse {
    return new StartExternalConversationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartExternalConversationResponse {
    return new StartExternalConversationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartExternalConversationResponse | PlainMessage<StartExternalConversationResponse> | undefined, b: StartExternalConversationResponse | PlainMessage<StartExternalConversationResponse> | undefined): boolean {
    return proto3.util.equals(StartExternalConversationResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetTextMessageEntryRequest
 */
export class GetTextMessageEntryRequest extends Message<GetTextMessageEntryRequest> {
  /**
   * @generated from field: string messageRef = 1;
   */
  messageRef = "";

  constructor(data?: PartialMessage<GetTextMessageEntryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetTextMessageEntryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "messageRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTextMessageEntryRequest {
    return new GetTextMessageEntryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTextMessageEntryRequest {
    return new GetTextMessageEntryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTextMessageEntryRequest {
    return new GetTextMessageEntryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTextMessageEntryRequest | PlainMessage<GetTextMessageEntryRequest> | undefined, b: GetTextMessageEntryRequest | PlainMessage<GetTextMessageEntryRequest> | undefined): boolean {
    return proto3.util.equals(GetTextMessageEntryRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetTextMessageEntryResponse
 */
export class GetTextMessageEntryResponse extends Message<GetTextMessageEntryResponse> {
  /**
   * @generated from field: protogen.FeedEntry entry = 1;
   */
  entry?: FeedEntry;

  constructor(data?: PartialMessage<GetTextMessageEntryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetTextMessageEntryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entry", kind: "message", T: FeedEntry },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTextMessageEntryResponse {
    return new GetTextMessageEntryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTextMessageEntryResponse {
    return new GetTextMessageEntryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTextMessageEntryResponse {
    return new GetTextMessageEntryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTextMessageEntryResponse | PlainMessage<GetTextMessageEntryResponse> | undefined, b: GetTextMessageEntryResponse | PlainMessage<GetTextMessageEntryResponse> | undefined): boolean {
    return proto3.util.equals(GetTextMessageEntryResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateExternalContactRequest
 */
export class UpdateExternalContactRequest extends Message<UpdateExternalContactRequest> {
  /**
   * @generated from field: string contactRef = 1;
   */
  contactRef = "";

  /**
   * string updatedFirstName = 3;
   * string updatedLastName = 4;
   *
   * @generated from field: string updatedDisplayName = 2;
   */
  updatedDisplayName = "";

  constructor(data?: PartialMessage<UpdateExternalContactRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateExternalContactRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contactRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedDisplayName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateExternalContactRequest {
    return new UpdateExternalContactRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateExternalContactRequest {
    return new UpdateExternalContactRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateExternalContactRequest {
    return new UpdateExternalContactRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateExternalContactRequest | PlainMessage<UpdateExternalContactRequest> | undefined, b: UpdateExternalContactRequest | PlainMessage<UpdateExternalContactRequest> | undefined): boolean {
    return proto3.util.equals(UpdateExternalContactRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateExternalContactResponse
 */
export class UpdateExternalContactResponse extends Message<UpdateExternalContactResponse> {
  /**
   * @generated from field: protogen.AccountStub contact = 1;
   */
  contact?: AccountStub;

  constructor(data?: PartialMessage<UpdateExternalContactResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateExternalContactResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact", kind: "message", T: AccountStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateExternalContactResponse {
    return new UpdateExternalContactResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateExternalContactResponse {
    return new UpdateExternalContactResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateExternalContactResponse {
    return new UpdateExternalContactResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateExternalContactResponse | PlainMessage<UpdateExternalContactResponse> | undefined, b: UpdateExternalContactResponse | PlainMessage<UpdateExternalContactResponse> | undefined): boolean {
    return proto3.util.equals(UpdateExternalContactResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchInvoiceHistoryRequest
 */
export class FetchInvoiceHistoryRequest extends Message<FetchInvoiceHistoryRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  constructor(data?: PartialMessage<FetchInvoiceHistoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchInvoiceHistoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchInvoiceHistoryRequest {
    return new FetchInvoiceHistoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchInvoiceHistoryRequest {
    return new FetchInvoiceHistoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchInvoiceHistoryRequest {
    return new FetchInvoiceHistoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchInvoiceHistoryRequest | PlainMessage<FetchInvoiceHistoryRequest> | undefined, b: FetchInvoiceHistoryRequest | PlainMessage<FetchInvoiceHistoryRequest> | undefined): boolean {
    return proto3.util.equals(FetchInvoiceHistoryRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchInvoiceHistoryResponse
 */
export class FetchInvoiceHistoryResponse extends Message<FetchInvoiceHistoryResponse> {
  /**
   * @generated from field: repeated protogen.FetchInvoiceHistoryResponse.InvoiceMonth invoices = 1;
   */
  invoices: FetchInvoiceHistoryResponse_InvoiceMonth[] = [];

  constructor(data?: PartialMessage<FetchInvoiceHistoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchInvoiceHistoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invoices", kind: "message", T: FetchInvoiceHistoryResponse_InvoiceMonth, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchInvoiceHistoryResponse {
    return new FetchInvoiceHistoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchInvoiceHistoryResponse {
    return new FetchInvoiceHistoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchInvoiceHistoryResponse {
    return new FetchInvoiceHistoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchInvoiceHistoryResponse | PlainMessage<FetchInvoiceHistoryResponse> | undefined, b: FetchInvoiceHistoryResponse | PlainMessage<FetchInvoiceHistoryResponse> | undefined): boolean {
    return proto3.util.equals(FetchInvoiceHistoryResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchInvoiceHistoryResponse.InvoiceMonthTaskChunk
 */
export class FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk extends Message<FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk> {
  /**
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * @generated from field: string date = 2;
   */
  date = "";

  /**
   * @generated from field: int32 minutes = 3;
   */
  minutes = 0;

  constructor(data?: PartialMessage<FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchInvoiceHistoryResponse.InvoiceMonthTaskChunk";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "minutes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk {
    return new FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk {
    return new FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk {
    return new FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk().fromJsonString(jsonString, options);
  }

  static equals(a: FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk | PlainMessage<FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk> | undefined, b: FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk | PlainMessage<FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk> | undefined): boolean {
    return proto3.util.equals(FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk, a, b);
  }
}

/**
 * @generated from message protogen.FetchInvoiceHistoryResponse.InvoiceMonthTask
 */
export class FetchInvoiceHistoryResponse_InvoiceMonthTask extends Message<FetchInvoiceHistoryResponse_InvoiceMonthTask> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: repeated protogen.FetchInvoiceHistoryResponse.InvoiceMonthTaskChunk chunks = 3;
   */
  chunks: FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk[] = [];

  constructor(data?: PartialMessage<FetchInvoiceHistoryResponse_InvoiceMonthTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchInvoiceHistoryResponse.InvoiceMonthTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "chunks", kind: "message", T: FetchInvoiceHistoryResponse_InvoiceMonthTaskChunk, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchInvoiceHistoryResponse_InvoiceMonthTask {
    return new FetchInvoiceHistoryResponse_InvoiceMonthTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchInvoiceHistoryResponse_InvoiceMonthTask {
    return new FetchInvoiceHistoryResponse_InvoiceMonthTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchInvoiceHistoryResponse_InvoiceMonthTask {
    return new FetchInvoiceHistoryResponse_InvoiceMonthTask().fromJsonString(jsonString, options);
  }

  static equals(a: FetchInvoiceHistoryResponse_InvoiceMonthTask | PlainMessage<FetchInvoiceHistoryResponse_InvoiceMonthTask> | undefined, b: FetchInvoiceHistoryResponse_InvoiceMonthTask | PlainMessage<FetchInvoiceHistoryResponse_InvoiceMonthTask> | undefined): boolean {
    return proto3.util.equals(FetchInvoiceHistoryResponse_InvoiceMonthTask, a, b);
  }
}

/**
 * @generated from message protogen.FetchInvoiceHistoryResponse.InvoiceMonth
 */
export class FetchInvoiceHistoryResponse_InvoiceMonth extends Message<FetchInvoiceHistoryResponse_InvoiceMonth> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string startDate = 2;
   */
  startDate = "";

  /**
   * @generated from field: string endDate = 3;
   */
  endDate = "";

  /**
   * @generated from field: int32 amountCents = 4;
   */
  amountCents = 0;

  /**
   * @generated from field: int32 totalMinutes = 5;
   */
  totalMinutes = 0;

  /**
   * @generated from field: int32 tasksCount = 6;
   */
  tasksCount = 0;

  /**
   * @generated from field: repeated protogen.FetchInvoiceHistoryResponse.InvoiceMonthTask tasks = 7;
   */
  tasks: FetchInvoiceHistoryResponse_InvoiceMonthTask[] = [];

  constructor(data?: PartialMessage<FetchInvoiceHistoryResponse_InvoiceMonth>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchInvoiceHistoryResponse.InvoiceMonth";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "endDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "amountCents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "totalMinutes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "tasksCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "tasks", kind: "message", T: FetchInvoiceHistoryResponse_InvoiceMonthTask, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchInvoiceHistoryResponse_InvoiceMonth {
    return new FetchInvoiceHistoryResponse_InvoiceMonth().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchInvoiceHistoryResponse_InvoiceMonth {
    return new FetchInvoiceHistoryResponse_InvoiceMonth().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchInvoiceHistoryResponse_InvoiceMonth {
    return new FetchInvoiceHistoryResponse_InvoiceMonth().fromJsonString(jsonString, options);
  }

  static equals(a: FetchInvoiceHistoryResponse_InvoiceMonth | PlainMessage<FetchInvoiceHistoryResponse_InvoiceMonth> | undefined, b: FetchInvoiceHistoryResponse_InvoiceMonth | PlainMessage<FetchInvoiceHistoryResponse_InvoiceMonth> | undefined): boolean {
    return proto3.util.equals(FetchInvoiceHistoryResponse_InvoiceMonth, a, b);
  }
}

/**
 * @generated from message protogen.ActivateFamilyRequest
 */
export class ActivateFamilyRequest extends Message<ActivateFamilyRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string startDate = 2;
   */
  startDate = "";

  constructor(data?: PartialMessage<ActivateFamilyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ActivateFamilyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivateFamilyRequest {
    return new ActivateFamilyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivateFamilyRequest {
    return new ActivateFamilyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivateFamilyRequest {
    return new ActivateFamilyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ActivateFamilyRequest | PlainMessage<ActivateFamilyRequest> | undefined, b: ActivateFamilyRequest | PlainMessage<ActivateFamilyRequest> | undefined): boolean {
    return proto3.util.equals(ActivateFamilyRequest, a, b);
  }
}

/**
 * @generated from message protogen.ActivateFamilyResponse
 */
export class ActivateFamilyResponse extends Message<ActivateFamilyResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  constructor(data?: PartialMessage<ActivateFamilyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ActivateFamilyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivateFamilyResponse {
    return new ActivateFamilyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivateFamilyResponse {
    return new ActivateFamilyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivateFamilyResponse {
    return new ActivateFamilyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ActivateFamilyResponse | PlainMessage<ActivateFamilyResponse> | undefined, b: ActivateFamilyResponse | PlainMessage<ActivateFamilyResponse> | undefined): boolean {
    return proto3.util.equals(ActivateFamilyResponse, a, b);
  }
}

