const convertHEICImages = async (files: File[]): Promise<File[]> => {
  const promises = files.map(async (file) => {
    if (file.type === "image/heic") {
      const heic2any = (await import("heic2any")).default;
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/png",
        });
        const imageBlob = Array.isArray(convertedBlob)
          ? convertedBlob[0]
          : convertedBlob;

        // Convert Blob to File
        const imageFile = new File(
          [imageBlob],
          file.name.replace(/\.heic$/i, ".png"),
          {
            type: "image/jpng",
            lastModified: file.lastModified,
          },
        );
        return imageFile;
      } catch (error) {
        console.error("Error converting HEIC image:", error);
        return file;
      }
    }
    return file;
  });

  return Promise.all(promises);
};

interface ImageDownsizeResult {
  file: File;
  resized: boolean;
}

const downsizeImages = async (
  files: File[],
  maxWidth: number | null = null,
  maxHeight: number | null = null,
  maxSizeKB: number | null = null,
): Promise<ImageDownsizeResult[]> => {
  const promises = files.map((file) =>
    downsizeImage(file, maxWidth, maxHeight, maxSizeKB),
  );

  return Promise.all(promises);
};

const downsizeImage = (
  file: File,
  maxWidth: number | null = null,
  maxHeight: number | null = null,
  maxSizeKB: number | null = null,
): Promise<ImageDownsizeResult> => {
  return new Promise((resolve) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      const result = e.target?.result;
      if (typeof result === "string") {
        img.src = result;
      } else {
        resolve({ file, resized: false });
      }
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        resolve({ file, resized: false });
        return;
      }

      let { width, height } = img;
      let resized = false;

      if (maxWidth && width > maxWidth) {
        height = (maxWidth / width) * height;
        width = maxWidth;
        resized = true;
      }
      if (maxHeight && height > maxHeight) {
        width = (maxHeight / height) * width;
        height = maxHeight;
        resized = true;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      if (!maxSizeKB) {
        if (!resized) {
          resolve({ file, resized: false });
          return;
        }
        canvas.toBlob((blob) => {
          if (!blob) {
            resolve({ file, resized: false });
            return;
          }
          const imageFile = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          resolve({ file: imageFile, resized: true });
        });
        return;
      }

      let quality = 1;
      const stepSize = 0.05;
      const maxSteps = 10;
      let steps = 0;

      const compressAndCheck = () => {
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              resolve({ file, resized: false });
              return;
            }

            const sizeKB = blob.size / 1024;

            if (
              (maxSizeKB && sizeKB <= maxSizeKB) ||
              steps >= maxSteps ||
              quality <= 0.1
            ) {
              const imageFile = new File([blob], file.name, {
                type: file.type,
                lastModified: file.lastModified,
              });
              resolve({ file: imageFile, resized: steps > 0 });
            } else {
              quality -= stepSize;
              steps += 1;
              compressAndCheck();
            }
          },
          file.type === "image/png" ? "image/jpeg" : file.type,
          quality,
        );
      };

      compressAndCheck();
    };

    reader.onerror = () => {
      resolve({ file, resized: false });
    };

    reader.readAsDataURL(file);
  });
};
export { convertHEICImages, downsizeImages, downsizeImage };
