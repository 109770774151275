import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
  useDeleteCalendarEvent,
  useGetCalendarEvent,
  useGetMemberCalendarEvent,
  useDeleteMemberCalendarEvent,
} from "services/calendar";
import {
  CalendarEvent,
  EventNotice,
  EventNotice_NoticeType,
} from "protogen/calendar_pb";
import {
  subtitle,
  recurringSubtitle,
  getAttendeeStatusLabel,
  getAttendeeStatusLabelColor,
  getAttendeeStatusLabelBackgroundColor,
} from "./utils";
import DocumentViewer from "../editor/DocumentViewer";
import useIsMobile from "../hooks/useIsMobile";
import LinkRouter from "components/navigation/LinkRouter";
import { MapPin } from "lucide-react";
interface Props {
  eventRef: string;
  onClose: (refresh?: boolean) => void;
  primaryAction: (eventRef: string) => void;
  onDelete?: (eventRef?: string) => void;
  accountType?: "advisor" | "member";
  eventNotice?: EventNotice;
}

const EventLocation = ({ location }: { location: string }) => {
  let formattedAddress;
  try {
    const locationData = JSON.parse(location);
    formattedAddress = locationData.formattedAddress;
  } catch (e) {
    formattedAddress = location;
  }
  const mapUrl = (addr: string) =>
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      addr,
    )}`;

  return (
    <LinkRouter
      to={mapUrl(formattedAddress)}
      targetNew={true}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        alignItems: "center",
      }}
    >
      <MapPin color="#198282" size={20} />
      <Typography variant="body" color="#198282" sx={{ fontWeight: 600 }}>
        {formattedAddress}
      </Typography>
    </LinkRouter>
  );
};

const Header = (notice: EventNotice, event: CalendarEvent) => {
  const isExternalEvent =
    notice.noticeType === EventNotice_NoticeType.NoticeType_EXTERNAL_EVENT;
  const location = event?.location || notice.locationString;
  return (
    <Box>
      <Typography sx={{ color: "#198282", fontWeight: "600" }} variant="body">
        Event
      </Typography>
      <Typography variant="title">{notice.title}</Typography>
      <Typography variant="bodyHeavy" sx={{ color: "#6B6E7B" }}>
        {subtitle(notice, true)}
      </Typography>
      {isExternalEvent ? (
        <Typography variant="body">{notice.externalCalendarName}</Typography>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <img
            style={{ border: "1px solid #198282", borderRadius: "50%" }}
            width="16px"
            height="16px"
            src="../../../../assets/images/faye-mini-icon.png"
            background-size="contain"
            background-repeat="no-repeat"
          />
          <Typography variant="body">{"Faye event"}</Typography>
        </Box>
      )}
      {location && <EventLocation location={location} />}
    </Box>
  );
};

const Details = (event: CalendarEvent) => {
  const content = event?.details?.payload
    ? JSON.parse(event?.details?.payload)
    : event?.details?.textContent;
  const emptyContent = !content && !event?.details?.attachments?.length;
  if (emptyContent) return <Typography variant="body">No details</Typography>;
  return (
    <DocumentViewer
      content={content}
      attachments={event?.details?.attachments!}
      minHeight="20px"
      maxHeight="288px"
    />
  );
};

const TextDetails = (notice: EventNotice) => {
  if (!notice.textOnlyDetails.trim()) {
    return <Typography variant="body">No details</Typography>;
  }
  return (
    <Typography
      variant="body"
      sx={{
        a: {
          textDecoration: "none",
          color: "#198282",
        },
      }}
    >
      <DocumentViewer
        content={notice.textOnlyDetails}
        minHeight="20px"
        maxHeight="288px"
      />
    </Typography>
  );
};

const Attendees = (event: CalendarEvent | EventNotice, isMobile: boolean) => {
  if (event.attendees.length === 0) return <></>;
  return (
    <Box>
      <Typography sx={{ marginBottom: "12px" }} variant="h4">
        Guests
      </Typography>
      {event.attendees.map((a) => {
        return (
          <Box
            key={a.email}
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "7px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "12px",
                maxWidth: "340px",
              }}
            >
              <Typography
                variant="body"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {a.firstName && a.lastName
                  ? `${a.firstName} ${a.lastName}`
                  : a.email}
              </Typography>
              {a.rsvpStatus === 1 || a.rsvpStatus === 2 ? (
                <Chip
                  label={getAttendeeStatusLabel(a.rsvpStatus)}
                  sx={{
                    height: isMobile ? "28px" : "35px",
                    width: isMobile ? "64px" : "87px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: getAttendeeStatusLabelColor(a.rsvpStatus),
                    backgroundColor: getAttendeeStatusLabelBackgroundColor(
                      a.rsvpStatus,
                    ),
                  }}
                />
              ) : (
                <Typography color="text.secondary">
                  {getAttendeeStatusLabel(a.rsvpStatus)}
                </Typography>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const Settings = (notice: EventNotice) => {
  return (
    <Box>
      <Typography sx={{}} variant="h4">
        Settings
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body">Repeats</Typography>
          <Typography color="text.secondary" fontSize="14px" fontWeight="500">
            {" "}
            {notice.recurringUnit !== 0 && notice.recurringInterval
              ? recurringSubtitle(notice, false)
              : notice.recurringUnit === 0 && notice.recurringInterval
                ? "Repeats - details unknown"
                : "Does not repeat"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const EditEventButton = (
  event: CalendarEvent,
  handleClick: (eventRef: string) => void,
) => {
  return (
    <Box>
      <Button
        variant="outlined"
        onClick={() => {
          handleClick(event.ref!);
        }}
      >
        Edit event
      </Button>
    </Box>
  );
};

const DeleteEventButton = (
  event: CalendarEvent,
  disabled: boolean,
  handleClick: (eventRef: string) => void,
) => {
  return (
    <Box>
      <Button
        variant="text"
        disabled={disabled}
        sx={(theme) => ({
          color: theme.palette.secondary.dark,
        })}
        onClick={() => {
          handleClick(event.ref!);
        }}
      >
        Delete event
      </Button>
    </Box>
  );
};
export default ({
  eventRef,
  onClose,
  primaryAction,
  onDelete,
  accountType = "advisor",
  eventNotice,
}: Props) => {
  const [event, setEvent] = useState<CalendarEvent | undefined>(undefined);
  const [notice, setNotice] = useState<EventNotice | undefined>(eventNotice);
  const isExternalEvent =
    eventNotice?.noticeType ===
    EventNotice_NoticeType.NoticeType_EXTERNAL_EVENT;
  const isMobile = useIsMobile();
  const getCalendarEventRequestFn =
    accountType === "advisor" ? useGetCalendarEvent : useGetMemberCalendarEvent;
  const { request, loading: eventLoading } = getCalendarEventRequestFn((r) => {
    setEvent(r?.event);
    setNotice(r?.notice);
  });
  const deleteCalendarEventRequestFn =
    accountType === "advisor"
      ? useDeleteCalendarEvent
      : useDeleteMemberCalendarEvent;
  const { request: deleteRequest, loading: deleteLoading } =
    deleteCalendarEventRequestFn();
  const loading = eventLoading || deleteLoading;

  const deleteEvent = async (eventRef: string) => {
    await deleteRequest({ eventRef: eventRef });
    onDelete && onDelete(eventRef);
    onClose(true);
  };

  useEffect(() => {
    // External events do no thave a "Faye" ref
    if (isExternalEvent) return;
    request({ eventRef: eventRef });
  }, [open]);

  if (loading || (!event && !isExternalEvent)) return <></>;
  return (
    <Box sx={{ width: "100%" }}>
      {Header(notice!, event!)}
      {isMobile && !isExternalEvent && (
        <Box
          sx={{ marginTop: "29px" }}
          display="flex"
          flexDirection="row"
          gap="8px"
        >
          {EditEventButton(event!, primaryAction)}
          {DeleteEventButton(event!, deleteLoading, deleteEvent)}
        </Box>
      )}
      <Divider sx={{ marginTop: "22px", marginBottom: "22px" }}></Divider>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : undefined,
          gap: isMobile ? undefined : "64px",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{}}>
            <Typography sx={{ marginBottom: "12px" }} variant="h4">
              Details
            </Typography>
            {isExternalEvent ? TextDetails(notice!) : Details(event!)}
          </Box>
          {Settings(notice!)}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: "270px",
          }}
        >
          {Attendees(isExternalEvent ? eventNotice : event!, isMobile)}
        </Box>
      </Box>
      {!isMobile && !isExternalEvent && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ marginTop: "20px" }}
        >
          {EditEventButton(event!, primaryAction)}
          {DeleteEventButton(event!, deleteLoading, deleteEvent)}
        </Box>
      )}
      {isMobile && (
        <Box sx={{ marginTop: "24px" }}>
          <Button
            sx={{ paddingLeft: "0px" }}
            startIcon={
              <ArrowLeftIcon height="20px" width="20px" color="##198282" />
            }
            variant="text"
            onClick={() => onClose()}
          >
            Back
          </Button>
        </Box>
      )}
    </Box>
  );
};
