import { AccountStub } from "protogen/common_pb";
import { Family } from "protogen/advisors_service_pb";
import useIsMobile from "../hooks/useIsMobile";
import React, { useEffect } from "react";
import { useAdminListAllAdvisors, useListAllFamilies } from "services/admin";
import { Box } from "@mui/material";
import CheckedDropDown from "../common/CheckedDropDown";
import { TIME_RANGES } from "../creation/TimeRangeSelect";

const statusOptions = () => {
  return [
    {
      label: "Any status",
      value: "",
      mutuallyExclusive: true,
    },
    {
      label: "Pending Approval",
      value: "pending_approval",
      mutuallyExclusive: true,
    },
    {
      label: "Approved",
      value: "approved",
      mutuallyExclusive: true,
    },
    {
      label: "Paid",
      value: "paid",
      mutuallyExclusive: true,
    },
    {
      label: "Deferred",
      value: "deferred",
      mutuallyExclusive: true,
    },
  ];
};

const timeRangeToSecondsAgo = (timeRange: string) => {
  const daysAgo = (days: number) =>
    Math.floor(Date.now() / 1000) - days * 24 * 60 * 60;
  const monthsAgo = (months: number) =>
    Math.floor(Date.now() / 1000) - months * 30 * 24 * 60 * 60;

  // Parse the timeRange string into a tuple
  const parseTimeRange = (range: string): [string, number] | null => {
    const match = range.match(/(\w+)\(([-\d]+)\)/);
    return match ? [match[1], parseInt(match[2], 10)] : null;
  };

  const parsed = parseTimeRange(timeRange);

  if (!parsed) return null;

  const [unit, value] = parsed;

  switch (unit) {
    case "days":
      return daysAgo(-value);
    case "months":
      return monthsAgo(-value);
    default:
      return null;
  }
};

const advisorOptions = (advisors: AccountStub[]) => {
  const options = advisors
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
    .map((advisor: AccountStub) => ({
      label: advisor.displayName,
      value: advisor.ref,
      mutuallyExclusive: true,
    }));
  options.unshift({
    label: "Any advisor",
    value: "",
    mutuallyExclusive: true,
  });
  return options;
};

const familyOptions = (families: Family[]) => {
  const options = families
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((family: Family) => ({
      label: family.name,
      value: family.ref || "",
      mutuallyExclusive: true,
    }));
  options.unshift({
    label: "Any family",
    value: "",
    mutuallyExclusive: true,
  });
  return options;
};

export type InvoiceFilter = {
  statuses: string[] | null;
  createdSinceSeconds: number | null;
  familyRef: string | null;
  advisorRef: string | null;
};

export default ({
  loading,
  setFilter,
  filter,
}: {
  loading: boolean;
  setFilter: (f: InvoiceFilter) => void;
  filter: InvoiceFilter;
}) => {
  const isMobile = useIsMobile();
  const {
    request: advisorsRequest,
    data: advisorsData,
    loading: advisorLoading,
  } = useAdminListAllAdvisors();
  const {
    request: familiesRequest,
    data: familiesData,
    loading: familyLoading,
  } = useListAllFamilies();
  useEffect(() => {
    advisorsRequest();
    familiesRequest();
  }, []);
  return (
    <Box
      display={"flex"}
      flexDirection="row"
      sx={{ overflowX: "scroll" }}
      justifyContent={!isMobile ? "end" : undefined}
    >
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent="end"
        gap="10px"
        mb={isMobile ? "20px" : undefined}
      >
        <CheckedDropDown
          disabled={loading || advisorLoading}
          radio={true}
          options={advisorOptions(advisorsData?.advisors || [])}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            (filter.advisorRef &&
              advisorsData?.advisors.find((a) => a.ref === filter.advisorRef)
                ?.displayName) ||
            "Advisor"
          }
          onChange={(f) => {
            setFilter({
              ...filter,
              advisorRef: f.length === 1 ? f[0].value : null,
            });
          }}
        />
        <CheckedDropDown
          disabled={loading || familyLoading}
          radio={true}
          options={familyOptions(familiesData?.families || [])}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            (filter.familyRef &&
              familiesData?.families.find((a) => a.ref === filter.familyRef)
                ?.name) ||
            "Family"
          }
          onChange={(f) => {
            setFilter({
              ...filter,
              familyRef: f.length === 1 ? f[0].value : null,
            });
          }}
        />
        <CheckedDropDown
          disabled={loading}
          radio={true}
          options={Object.values(TIME_RANGES).map((pt) => ({
            label: pt.label,
            value: pt.value || "",
            mutuallyExclusive: true,
          }))}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          displayValue={
            Object.values(TIME_RANGES).find(
              (t) =>
                t.value &&
                timeRangeToSecondsAgo(t.value) === filter.createdSinceSeconds,
            )?.label || "Date"
          }
          onChange={(r) => {
            setFilter({
              ...filter,
              createdSinceSeconds:
                r.length === 1 && r[0].value
                  ? timeRangeToSecondsAgo(r[0].value)
                  : null,
            });
          }}
        />
        <CheckedDropDown
          disabled={loading}
          radio={true}
          options={statusOptions()}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            statusOptions().find((s) => s.value === filter?.statuses?.[0])
              ?.label || "Any Status"
          }
          onChange={(s) => {
            setFilter({
              ...filter,
              statuses: s.length === 1 && s[0].value ? [s[0].value] : null,
            });
          }}
        />
      </Box>
    </Box>
  );
};
