import { ReactNode, useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import { useCreateCalendarIntegration } from "../../../services/calendar";
import useIsMobile from "components/hooks/useIsMobile";

interface AppleCalendarProps {
  instructions: ReactNode;
  onSuccess: (url: string) => void;
  onFailure: (error: string) => void;
}

export default ({ instructions, onSuccess, onFailure }: AppleCalendarProps) => {
  const [url, setUrl] = useState("");
  const {
    request: createRequest,
    error: createError,
    loading: createLoading,
  } = useCreateCalendarIntegration();

  const handleAddCalendar = async () => {
    if (isValidURL(url)) {
      const resp = await createRequest({
        calendarType: "url",
        accessCode: "",
        accessScope: "",
        accessRedirectUri: "",
        calendarUrl: url,
      });
      if (resp) {
        onSuccess(url);
      }
    } else {
      onFailure("Invalid URL");
    }
  };

  const isValidURL = (urlString: string) => {
    try {
      new URL(urlString);
      return true;
    } catch (_) {
      return false;
    }
  };
  const isValid = url && isValidURL(url);
  const isMobile = useIsMobile();

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      {instructions}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <TextField
          label="Calendar URL"
          variant="outlined"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          sx={{
            flex: 1,
          }}
          disabled={createLoading}
          error={!!createError}
        />
        <Button
          sx={{ width: isMobile ? "100%" : "auto" }}
          disabled={createLoading || !isValid}
          variant="contained"
          color="primary"
          onClick={handleAddCalendar}
        >
          Add calendar
        </Button>
      </Box>
    </Box>
  );
};
