import React, { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import ReactiveDialog from "../common/ReactiveDialog";
import { CalendarDays } from "lucide-react";
import StaticDateTimePicker from "../calendar/StaticDateTimePicker";
import useIsMobile from "../hooks/useIsMobile";

const nearestHour = () => {
  const newDate = new Date();
  // Increment the hour by 1
  newDate.setHours(newDate.getHours() + 1);
  // Set minutes, seconds, and milliseconds to zero
  newDate.setMinutes(0, 0, 0);
  return newDate;
};

const RelativeOption = ({
  title,
  time,
  onClick,
  disabled,
}: {
  disabled: boolean;
  title: string;
  time: Date;
  onClick: (d: Date) => void;
}) => {
  const isMobile = useIsMobile();
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short",
  };
  const dateStr = Intl.DateTimeFormat("en-US", options).format(time);
  return (
    <Box
      onClick={() => !disabled && onClick(time)}
      sx={{
        cursor: "pointer",
        display: "flex",
        padding: "16px 24px",
        justifyContent: "space-between",
        alignSelf: "stretch",
        borderRadius: "50px",
        border: "2px solid #F1F1F1",
        ...(isMobile
          ? {
              flexDirection: "column",
              alignItems: "flex-start",
            }
          : {
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }),
      }}
    >
      <Typography
        variant="body"
        color="#000"
        fontWeight={isMobile ? 700 : undefined}
      >
        {title}
      </Typography>
      <Typography variant="body" color="#000">
        {dateStr}
      </Typography>
    </Box>
  );
};

interface ScheduleSendProps {
  open: boolean;
  value?: Date;
  onClose: () => void;
  onUpdate: (v: Date) => Promise<void>;
}

export default function ScheduleSend({
  open,
  value,
  onClose,
  onUpdate,
}: ScheduleSendProps) {
  const [customDate, setCustomDate] = useState<Date | null>(value || null);
  const [disabled, setDisabled] = useState(false);
  const handleClose = async (d?: Date | null) => {
    setCustomDate(null);
    if (d) {
      setDisabled(true);
      await onUpdate(d || undefined);
      setDisabled(false);
    }
    onClose();
  };
  return (
    <ReactiveDialog
      open={open}
      primaryActionEnabled={false}
      onClose={() => handleClose()}
      title={customDate ? "Choose date & time" : "Schedule send"}
      fullWidthSize="sm"
    >
      {!customDate && (
        <Box display="flex" flexDirection="column" gap="12px">
          <RelativeOption
            title="Tomorrow at 9:00 AM"
            time={(() => {
              const now = new Date();
              return new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 1,
                9, // Hours: 9 AM
                0, // Minutes: 0
                0, // Seconds: 0
                0, // Milliseconds: 0
              );
            })()}
            disabled={disabled}
            onClick={handleClose}
          />
          <RelativeOption
            disabled={disabled}
            title="Next Monday at 9:00 AM"
            time={(() => {
              const now = new Date();
              const currentDay = now.getDay(); // 0 (Sunday) to 6 (Saturday)
              const daysUntilNextMonday = (8 - currentDay) % 7 || 7; // Days until next Monday
              return new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + daysUntilNextMonday,
                9, // Hours: 9 AM
                0, // Minutes: 0
                0, // Seconds: 0
                0, // Milliseconds: 0
              );
            })()}
            onClick={handleClose}
          />
          <Box
            onClick={() => !disabled && setCustomDate(nearestHour())}
            sx={{
              cursor: "pointer",
              display: "flex",
              padding: "16px 24px",
              justifyContent: "start",
              gap: "8px",
              alignItems: "center",
              alignSelf: "stretch",
              borderRadius: "50px",
              border: "2px solid #F1F1F1",
            }}
          >
            <CalendarDays />
            <Typography variant="body" color="#000">
              Choose date & time
            </Typography>
          </Box>
        </Box>
      )}
      {customDate && (
        <Box display="flex" flexDirection="column" gap="12px">
          <StaticDateTimePicker
            disabled={disabled}
            defaultValue={customDate}
            updateValue={(v) => {
              setCustomDate(v);
            }}
          />
          <Box
            display="flex"
            flexDirection="row"
            gap="12px"
            justifyContent="end"
          >
            <Button
              variant="text"
              onClick={() => setCustomDate(null)}
              disabled={disabled}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => handleClose(customDate)}
              disabled={disabled}
            >
              Schedule send
            </Button>
          </Box>
        </Box>
      )}
    </ReactiveDialog>
  );
}
