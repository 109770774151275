import { useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { PRIORITY_STATES } from "components/creation/PrioritySelect";
import Checkbox from "components/common/Checkbox";
import { TASK_STATES } from "components/tasks/constants";
type CheckboxFilterProps = {
  options: {
    label: string;
    value: string;
    icon?: React.ReactNode;
  }[];
  title: string;
  fieldName: string;
  onChange: (field: string, value: string, isChecked: boolean) => void;
  initial?: string[];
};

const CheckboxFilter = ({
  options,
  title,
  fieldName,
  onChange,
  initial = [],
}: CheckboxFilterProps) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(initial);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>{<Typography variant="h4">{title}</Typography>}</Box>
      {options.map(({ value, label }, idx) => (
        <FormControlLabel
          key={idx}
          label={label}
          control={
            <Checkbox
              checked={checkedValues.includes(value)}
              onChange={(e: { target: { checked: any } }) => {
                const isChecked = e.target.checked;
                setCheckedValues((prevCheckedValues) => {
                  return isChecked
                    ? [...prevCheckedValues, value]
                    : prevCheckedValues.filter((v) => v !== value);
                });
                onChange(fieldName, value, isChecked);
              }}
            />
          }
        />
      ))}
    </Box>
  );
};

export const TaskFilterDrawer = ({
  open,
  onClose,
  onChange,
  initialPriorites = [],
  initialStates = [],
  initialFamilies = [],
  families = [],
  showFamilyFilter = true,
}: {
  open: boolean;
  onClose: () => void;
  onChange: (field: string, value: string, isChecked: boolean) => void;
  initialPriorites?: string[];
  initialStates?: string[];
  initialFamilies?: string[];
  families?: { label: string; value: string }[];
  showFamilyFilter?: boolean;
}) => {
  return (
    <Box>
      <Drawer anchor={"right"} open={open} onClose={onClose}>
        <Box
          sx={{
            width: "348px",
            display: "flex",
            flexDirection: "column",
            padding: "48px 32px",
            gap: "52px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h2Serif">Filters</Typography>
            <IconButton
              onClick={onClose}
              sx={{
                height: "34px",
                width: "34px",
                padding: 0,
                border: "2px solid #EDEDED",
                borderRadius: "100px",
              }}
            >
              <CloseIcon height="12px" width="12px" stroke="#8E9598" />
            </IconButton>
          </Box>
          <CheckboxFilter
            title="Priority"
            fieldName="priority"
            initial={initialPriorites}
            options={PRIORITY_STATES.map((s) => ({
              label: s.label,
              value: s.value.toString(),
              icon: s.icon,
            }))}
            onChange={onChange}
          />
          <CheckboxFilter
            title="Status"
            fieldName="status"
            initial={initialStates}
            options={TASK_STATES.map((s) => ({
              label: s.label,
              value: s.value.toString(),
              icon: s.icon,
            }))}
            onChange={onChange}
          />
          {showFamilyFilter && (
            <CheckboxFilter
              options={families}
              title="Family"
              fieldName="family"
              initial={initialFamilies}
              onChange={onChange}
            />
          )}
        </Box>
      </Drawer>
    </Box>
  );
};
