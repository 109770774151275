import { FactStub } from "protogen/facts_pb";
import { Family } from "protogen/advisors_service_pb";
import { Member } from "protogen/common_pb";
import { formatUSPhoneNumber } from "../../common/utils";
import ReactiveDialog from "../common/ReactiveDialog";
import { Box, Button } from "@mui/material";
import LinkRouter from "../navigation/LinkRouter";
import CopyTextField from "../common/CopyTextField";
import { ArrowRight } from "lucide-react";
import { userReferralLink } from "../referrals/utils";

export const FamilyFactDialog = ({
  family,
  open,
  onClose,
}: {
  family: Family;
  open: boolean;
  onClose: () => void;
}) => {
  const parsedAddress = family.address ? JSON.parse(family.address) : null;
  return (
    <ReactiveDialog
      fullWidthSize="sm"
      open={open}
      onClose={onClose}
      title={`Family details`}
    >
      <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
        <CopyTextField
          label="Family name"
          value={family.name}
          sx={{
            border: "1px solid #D4D4D4",
            backgroundColor: "#FFF",
          }}
        />
        <CopyTextField
          label="Address"
          value={parsedAddress?.formattedAddress || ""}
          sx={{
            border: "1px solid #D4D4D4",
            backgroundColor: "#FFF",
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{ marginTop: "24px" }}
        justifyContent="end"
      >
        <LinkRouter to={`/families/${encodeURIComponent(family.ref)}#tab-4`}>
          <Button variant="text" endIcon={<ArrowRight />}>
            Edit family in settings
          </Button>
        </LinkRouter>
      </Box>
    </ReactiveDialog>
  );
};

export const MemberFactDialog = ({
  member,
  family,
  open,
  onClose,
}: {
  member?: Member;
  family: Family;
  open: boolean;
  onClose: () => void;
}) => {
  if (!member) {
    return null;
  }
  return (
    <ReactiveDialog
      fullWidthSize="sm"
      open={open}
      onClose={onClose}
      title={`Member details`}
    >
      <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
        <Box display={"flex"} flexDirection={"row"} gap={"24px"}>
          <CopyTextField
            label="First name"
            value={member.firstName}
            sx={{
              border: "1px solid #D4D4D4",
              backgroundColor: "#FFF",
            }}
          />
          <CopyTextField
            label="Last name"
            value={member.lastName}
            sx={{
              border: "1px solid #D4D4D4",
              backgroundColor: "#FFF",
            }}
          />
        </Box>
        <CopyTextField
          label="Phone number"
          value={formatUSPhoneNumber(member.primaryPhone)}
          sx={{
            border: "1px solid #D4D4D4",
            backgroundColor: "#FFF",
          }}
        />
        <CopyTextField
          label="Email address"
          value={member.primaryEmail}
          sx={{
            border: "1px solid #D4D4D4",
            backgroundColor: "#FFF",
          }}
        />
        {member.alternateEmails.length > 0 && (
          <CopyTextField
            label="Alternate emails"
            value={member.alternateEmails.join(", ")}
            sx={{
              border: "1px solid #D4D4D4",
              backgroundColor: "#FFF",
            }}
          />
        )}
        <CopyTextField
          label="Referral link"
          value={userReferralLink(member.ref)}
          sx={{
            border: "1px solid #D4D4D4",
            backgroundColor: "#FFF",
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{ marginTop: "24px" }}
        justifyContent="end"
      >
        <LinkRouter to={`/families/${encodeURIComponent(family.ref)}#tab-4`}>
          <Button variant="text" endIcon={<ArrowRight />}>
            Edit member in settings
          </Button>
        </LinkRouter>
      </Box>
    </ReactiveDialog>
  );
};

export const createMemberFactStubs = (family: Family): FactStub[] => {
  const memberFacts = family.familyMembers.map((member) => {
    const values = [
      `Phone: ${formatUSPhoneNumber(member.primaryPhone)}`,
      `Email: ${member.primaryEmail}`,
      `Alternate Emails: ${member.alternateEmails.join(", ")}`,
      `Timezone: ${member.timezone}`,
    ];
    return new FactStub({
      ref: member.ref,
      name: `${member.firstName} ${member.lastName}`,
      valueType: "member",
      value: values.join("\n"),
      attachments: [],
    });
  });

  return memberFacts;
};
