import { CSSProperties, ReactNode, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { Attachment as AttachmentProto } from "protogen/common_pb";
import MessageBubble from "./MessageBubble";
import AttachmentComp from "../common/AttachmentComp";
import CustomLinkify from "../common/CustomLinkify";
import * as React from "react";
import { Info } from "lucide-react";

// export const errorMessageForCode = (twilioErrorCode?: number) => {
//   // https://www.twilio.com/docs/api/errors#30000-39999
//   switch (twilioErrorCode) {
//     // case 20001:
//     //   return "Account is not active. Please contact Twilio support.";
//     case 21601:
//       return "Phone number is invalid.";
//     // case 21602:
//     //   return "The 'From' phone number is invalid.";
//     case 21604:
//       return "The provided phone number is not a valid mobile or landline.";
//     case 21610:
//       return "The recipient has opted out of receiving messages from this number.";
//     case 21614:
//       return "The number is not a valid mobile number.";
//     case 30002:
//       return "Message delivery failed due to an invalid number.";
//     case 30003:
//       return "Message delivery failed due to an unreachable recipient.";
//     case 30004:
//       return "Message delivery blocked.";
//
//     case 30005:
//       return "Message delivery failed due to a blocked number.";
//     case 30006:
//       return "Message delivery failed due to a landline number.";
//     case 30001: // "Message was rejected by the carrier. Please check the recipient's number.";
//     case 30007:
//       return "Message flagged by carrier, try removing common spam terms.";
//     case 30008: // "Message was temporarily failed. Retry later.";
//     case 30009:
//       return "Message failed due to unknown carrier issues.";
//     // case 21620:
//     //   return "The 'From' number is not allowed to send messages to this country.";
//     // case 21627:
//     //   return "The 'From' number does not have permission to send SMS.";
//     default:
//       return "Message failed to send.";
//   }
// };

export const errorMessageForCode = (twilioErrorCode?: number) => {
  // https://www.twilio.com/docs/api/errors#30000-39999
  switch (twilioErrorCode) {
    case 21604:
      return "The number is not a valid mobile or landline.";
    case 21610:
      return "The number has opted out of receiving messages from this number.";
    case 21614:
      return "The number is not a valid mobile number.";
    case 30001:
      return "Too many messages sent in a short period.";
    // case 30002:
    //   return "Account suspended. Please contact Twilio support.";
    case 30003:
    case 30005: // "Unknown destination handset. The device may not be registered on the network.";
      return "Unreachable number. The device may be switched off or out of the coverage area.";
    case 30004:
      return "Message blocked. The destination number is unable to receive this message.";
    //   //       return "Message flagged by carrier, try removing common spam terms.";
    case 30006:
      return "The number may be a landline or the carrier is unreachable.";
    case 30007:
      return "Carrier violation. The message content may have been flagged by the carrier.";
    // case 30008:
    //   return "Unknown error. An unexpected error occurred.";
    // case 30009:
    //   return "Missing segment. Part of a multi-part message was not received.";
    // case 30010:
    //   return "Message delivery failed due to a carrier's internal error.";
    case 30034:
      return "Message blocked due to invalid destination number.";
    case 30035:
      return "Message blocked due to destination number opt-out.";
    case 30036:
      return "Message blocked due to account suspension.";
    case 30037:
      return "Message blocked due to invalid sender ID.";
    case 30038:
      return "Message blocked due to unsupported message type.";
    case 30039:
      return "Message blocked to prevent message loops.";
    default:
      return "Message failed to send.";
  }
};

export const errorRetryable = (twilioErrorCode?: number) => {
  switch (twilioErrorCode) {
    case 21610:
    case 30001:
    case 30002:
    case 30004:
    case 30007:
    case 30035:
    case 30036:
    case 30039:
      return false;
    default:
      return true;
  }
};

export const errorExtraInfo = (twilioErrorCode?: number) => {
  switch (twilioErrorCode) {
    case 30007: // Message flagged by carrier, try removing spam terms.
    case 30039: // Message flagged by carrier, try removing spam terms.
      return "Terms common in spam messages can trigger this. Try rephrasing your message: Avoid non-faye URL shorteners, words like 'Venmo', 'Paypal', or other words that a spammer may use.";
    default:
      return undefined;
  }
};

interface TextMessageProps {
  loading?: boolean;
  error?: string | undefined;
  errorExtraInfo?: string | undefined;
  content: string | null;
  attachments: AttachmentProto[];
  alignLeft: boolean;
  openAttachment: (a: AttachmentProto) => void;
  sx?: CSSProperties;
  handleRetry?: () => void;
  byline?: ReactNode;
}

export default ({
  loading,
  error,
  errorExtraInfo,
  content,
  attachments,
  alignLeft,
  openAttachment,
  sx,
  handleRetry,
  byline,
}: TextMessageProps) => {
  const [retryDisabled, setRetryDisabled] = useState(false);
  return (
    <Box>
      {content && (
        <MessageBubble
          loading={loading}
          alignLeft={alignLeft}
          sx={{
            ...sx,
            ...(error
              ? { border: "2px solid red", boxShadow: "0 0 3px red" }
              : {}),
            a: {
              textDecoration: "none",
            },
          }}
        >
          <CustomLinkify>{content}</CustomLinkify>
        </MessageBubble>
      )}
      {attachments.length > 0 && (
        <div
          style={{
            gap: "8px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
          }}
        >
          {attachments.map((attachment, i) => (
            <AttachmentComp
              key={i}
              attachment={attachment}
              openAttachment={openAttachment}
              loading={loading}
              error={error}
            />
          ))}
        </div>
      )}
      {error && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginLeft: "4px",
            flexDirection: "row",
            marginTop: "4px",
            justifyContent: alignLeft ? "flex-end" : "flex-start",
          }}
        >
          <span style={{ color: "red", fontSize: "11px", marginLeft: "3px" }}>
            {errorExtraInfo ? (
              <Tooltip title={errorExtraInfo}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "row",
                  }}
                >
                  <Info
                    size={14}
                    style={{
                      color: "red",
                      fontSize: "11px",
                      marginRight: "3px",
                      marginTop: "2px",
                    }}
                  />
                  {error}
                </div>
              </Tooltip>
            ) : (
              error
            )}
            {handleRetry && (
              <>
                {"  "}
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: 600,
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    if (retryDisabled) return;
                    setRetryDisabled(true);
                    handleRetry();
                  }}
                >
                  Try again?
                </span>{" "}
              </>
            )}
          </span>
        </div>
      )}
      {byline}
    </Box>
  );
};
