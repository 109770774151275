import { useEffect, useState } from "react";
import { FormControl, TextField, Box, Typography } from "@mui/material";
import { Check, X } from "lucide-react";

const MIN_LENGTH = 8;
const MAX_LENGTH = 20;

// The list maps with the validation list below
const passwordRequirements: string[] = [
  `Between ${MIN_LENGTH} and ${MAX_LENGTH} characters.`,
  "Contains at least one uppercase letter.",
  "Contains at least one digit.",
  "Contains at least one special character.",
];

const validatePassword = (
  password: string,
  minLen: number = MIN_LENGTH,
  maxLen: number = MAX_LENGTH,
): boolean[] => {
  return [
    // Check for length
    password.length > minLen && password.length < maxLen,
    // Check for at least one uppercase letter
    /[A-Z]/.test(password),
    // Check for at least one digit
    /[0-9]/.test(password),
    // Check for at least one special character (e.g., !@#$%^&*()_+-=[]{}|;:'",.<>/?)
    /[!@#$%^&*()_+\-=\[\]{}|;:'",.<>\/?]/.test(password),
  ];
};

const PasswordRequirement = ({
  requirement,
  isPassed,
}: {
  requirement: string;
  isPassed: boolean;
}) => {
  return (
    <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      {isPassed ? <Check size={12} color="#198282" /> : <X size={12} />}
      <Typography variant="bodySmall" color={isPassed ? "#3D3D3D " : "#8E9598"}>
        {requirement}
      </Typography>
    </Box>
  );
};
export default ({
  password,
  onChange,
  sx,
}: {
  password: string;
  onChange: (password: string) => void;
  sx?: React.CSSProperties;
}) => {
  const [passwordValidation, setPasswordValidation] = useState<boolean[]>([]);
  useEffect(() => {
    setPasswordValidation(validatePassword(password));
  }, [password]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", ...sx }}>
      <FormControl fullWidth>
        <TextField
          sx={{ flexGrow: 1 }}
          label="Password"
          name="password"
          type="password"
          autoComplete="on"
          value={password}
          onChange={(event) => {
            onChange(event?.target?.value || "");
          }}
        />
      </FormControl>
      {passwordRequirements.map((requirement, i) => {
        return (
          <PasswordRequirement
            key={i}
            requirement={requirement}
            isPassed={passwordValidation[i]}
          />
        );
      })}
    </Box>
  );
};
