import { ReactNode, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet, useLocation } from "react-router-dom";
import { AppBar } from "@mui/material";
import RequireAuth from "../context/RequireAuth";
import NotificationPrompt from "../prompts/NotificationPrompt";
import ResponsiveDrawer from "./ResponsiveDrawer";
import HttpErrorProvider from "../context/HttpErrorProvider";
import useIsMobile from "../hooks/useIsMobile";
import CheckTerms from "../context/CheckTerms";
import { AccountType } from "protogen/common_pb";
import { ReactComponent as FayeIcon } from "../../icons/FayeBrand.svg";
import ContextAggregator, {
  ContextComponent,
} from "../helpers/ContextAggregator";
import LinkRouter from "../navigation/LinkRouter";
import { useAppBarSx } from "../context/AppBarContext";
import ActiveCallModule from "../phone/ActiveCallModule";
import NewPasswordPrompt from "../context/NewPasswordPrompt";
import { isStandaloneWebapp } from "../../common/utils";

export const FayeAppBar = ({ rightAction }: { rightAction?: ReactNode }) => {
  const { sx } = useAppBarSx();
  return (
    <AppBar
      sx={{
        height: "50px",
        background: "#FDFAF7",
        boxShadow: "none",
        borderBottom: "1px solid #E8E8E8",
        ...sx,
      }}
    >
      <Toolbar>
        <LinkRouter to="/">
          <FayeIcon
            style={{
              height: "26px",
              margin: "12px 0px",
            }}
          />
        </LinkRouter>
        <Box sx={{ flexGrow: 1 }} />
        {rightAction}
      </Toolbar>
    </AppBar>
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerContent = ({
  isPersistent,
  open,
  setOpen,
  content,
}: {
  isPersistent: boolean;
  open: boolean;
  setOpen: (b: boolean) => void;
  content: React.ReactNode;
}) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  let selectedTab = location.pathname;
  if (selectedTab.endsWith("/")) {
    selectedTab = selectedTab.slice(0, -1);
  }
  const setClosed = () => setOpen(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        paddingBottom: "10px",
        backgroundColor: "#FDFAF7",
      }}
    >
      {typeof content === "function" &&
        content({ open, isPersistent, selectedTab, setClosed, isMobile })}
    </Box>
  );
};

const NoBadge = ({ children }: { children?: ReactNode }) =>
  children ? <>{children}</> : null;

type NavigationLayoutProps = {
  drawerContent: React.ReactNode;
  loginRedirectPath: string;
  tosAccountType?: AccountType;
  // Set of wrapper components that should be added within RequireAuth.
  authedContexts?: ContextComponent[];
  MobileBadgerComponent?: React.ComponentType;
};

export default ({
  drawerContent,
  loginRedirectPath,
  tosAccountType,
  authedContexts,
  MobileBadgerComponent = NoBadge,
}: NavigationLayoutProps) => {
  const [open, setOpen] = useState(false);
  const drawerWidth = 240;
  const persistentNavWidth = 240;
  const useAppBar = useIsMobile();
  const handleOpen = (o: boolean) => {
    if (!useAppBar) {
      return;
    }
    setOpen(o);
  };
  const drawerOpen = !useAppBar || open;
  return (
    <RequireAuth loginRedirectPath={loginRedirectPath}>
      <HttpErrorProvider>
        {(tosAccountType === AccountType.ADVISOR ||
          (tosAccountType === AccountType.MEMBER && isStandaloneWebapp())) && (
          <NotificationPrompt accountType={tosAccountType} />
        )}
        {tosAccountType && <CheckTerms accountType={tosAccountType} />}
        {tosAccountType === AccountType.MEMBER && <NewPasswordPrompt />}
        <ContextAggregator contexts={authedContexts}>
          {tosAccountType === AccountType.ADVISOR && <ActiveCallModule />}
          <CssBaseline />
          {useAppBar && (
            <FayeAppBar
              rightAction={
                <MobileBadgerComponent>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => handleOpen(true)}
                    sx={{
                      ...(open && { display: "none" }),
                    }}
                  >
                    <MenuIcon sx={{ color: "#198282" }} />
                  </IconButton>
                </MobileBadgerComponent>
              }
            />
          )}
          <ResponsiveDrawer
            open={drawerOpen}
            setOpen={(o) => handleOpen(o)}
            drawerWidth={drawerWidth}
          >
            <DrawerContent
              isPersistent={!useAppBar}
              open={drawerOpen}
              setOpen={(o) => handleOpen(o)}
              content={drawerContent}
            />
          </ResponsiveDrawer>
          <Box
            sx={{
              marginLeft: `${useAppBar ? 0 : persistentNavWidth}px`,
              // Drawer is hidden on print.
              "@media print": {
                marginLeft: 0,
              },
            }}
          >
            {/* Needed to make space for app bar. */}
            {useAppBar && <DrawerHeader />}
            <Outlet />
          </Box>
        </ContextAggregator>
      </HttpErrorProvider>
    </RequireAuth>
  );
};
