import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { Family, FormErrors } from "./types";
import AdvisorSelect from "../creation/AdvisorSelect";
import React, { useContext, useState } from "react";
import { CurrentUserContext } from "../context/RequireAuth";
import { hasRole, ROLE_ADMIN } from "../../common/userUtils";
import { AccountStub, Advisor } from "protogen/common_pb";
import { UserAvatar } from "../common/CurrentUserAvatar";
import Checkbox from "../common/Checkbox";
import { defaultPermissions } from "./utils";
import { ChevronDown } from "lucide-react";
import useIsMobile from "components/hooks/useIsMobile";

const AddDelegate = ({ onAdd }: { onAdd: (a: AccountStub) => void }) => {
  const [addingDelegate, setAddingDelegate] = useState<boolean>(false);
  // Add button
  // trigger drop down
  // add delegate with default permissions
  if (!addingDelegate) {
    return (
      <Button
        variant="text"
        sx={{ alignSelf: "start", height: "unset" }}
        onClick={() => setAddingDelegate(true)}
      >
        Add collaborator
      </Button>
    );
  }
  return (
    <AdvisorSelect
      title="Add Collaborator"
      disabled={false}
      error={null}
      selectedAdvisor={null}
      onChange={(v, a) => {
        setAddingDelegate(false);
        v && a && onAdd(a);
      }}
    />
  );
};

interface Props {
  familyData: Family;
  setFamilyData: (f: Family) => void;
  errors: FormErrors | null;
  primaryAdvisor: Advisor;
}

export default ({
  familyData,
  setFamilyData,
  errors,
  primaryAdvisor, // advisorPermissions,
}: Props) => {
  const currentUser = useContext(CurrentUserContext);
  const [selectedPermissionAdvisorRef, setSelectedPermissionAdvisorRef] =
    useState<string | null>(familyData?.permissions?.[0]?.advisorRef || null);
  const selectedPermission = familyData.permissions.find(
    (p) => (p.advisorRef || "") === selectedPermissionAdvisorRef,
  );
  const selectedPermissionActive =
    selectedPermission?.reactivate ||
    (selectedPermission?.active && !selectedPermission?.deactivate);
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant={"h3"}>Advisor details</Typography>
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={"16px"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        {primaryAdvisor && <UserAvatar user={primaryAdvisor} size={40} />}
        <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
          <Typography variant={"bodyHeavy"}>
            {primaryAdvisor?.displayName}
          </Typography>
          <Typography variant={"bodySmall"}>Primary Advisor</Typography>
        </Box>
      </Box>
      {hasRole(currentUser, ROLE_ADMIN) && (
        <AdvisorSelect
          defaultSelf={true}
          isAdmin={true}
          title="Primary Advisor"
          disabled={false}
          error={errors?.assigneeRef}
          selectedAdvisor={familyData.advisorRef || null}
          onChange={(v) => setFamilyData({ ...familyData, advisorRef: v! })}
        />
      )}
      {(hasRole(currentUser, ROLE_ADMIN) ||
        currentUser.ref === primaryAdvisor.ref) && (
        <>
          {selectedPermission && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                width: isMobile ? "100%" : "auto",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Select
                key={familyData.permissions.length}
                sx={{
                  width: isMobile ? "100%" : "250px",
                  ".MuiSelect-select": {
                    marginTop: 0,
                  },
                }}
                value={selectedPermissionAdvisorRef}
                renderValue={() => {
                  const permission = familyData.permissions.find(
                    (p) =>
                      (p.advisorRef || "") === selectedPermissionAdvisorRef,
                  );
                  return (
                    <Typography variant="body">
                      {permission?.account?.displayName}
                    </Typography>
                  );
                }}
                onChange={(e) =>
                  setSelectedPermissionAdvisorRef(e.target.value as string)
                }
                IconComponent={ChevronDown}
              >
                {familyData.permissions.map((permission) => {
                  const permissionActive =
                    permission.reactivate ||
                    (permission.active && !permission.deactivate);
                  return (
                    <MenuItem
                      value={permission.advisorRef || ""}
                      key={permission.advisorRef || ""}
                    >
                      {!permissionActive && "[Inactive] "}
                      {permission.account?.displayName}
                    </MenuItem>
                  );
                })}
              </Select>
              <Box
                sx={{
                  ...(!selectedPermissionActive && {
                    opacity: 0.7,
                  }),
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  minWidth: "1px",
                  flex: "1 1 1px",
                  gap: "12px",
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(selectedPermission.actions)
                  .sort()
                  .map((key) => (
                    <Box
                      key={key}
                      display="flex"
                      sx={{
                        width: "calc(50% - 6px)",
                      }}
                    >
                      <FormControlLabel
                        key={key}
                        label={key}
                        disabled={!selectedPermissionActive}
                        control={
                          <Checkbox
                            sx={{ padding: "0 10px 0 0" }}
                            checked={selectedPermission.actions[key]}
                            onChange={(e) => {
                              setFamilyData({
                                ...familyData,
                                permissions: familyData.permissions.map((p) => {
                                  if (
                                    p.advisorRef !==
                                    selectedPermission.advisorRef
                                  ) {
                                    return p;
                                  } else {
                                    return {
                                      ...p,
                                      actions: {
                                        ...p.actions,
                                        [key]: e.target.checked,
                                      },
                                    };
                                  }
                                }),
                              });
                            }}
                          />
                        }
                      />
                    </Box>
                  ))}
              </Box>
              {!isMobile && (
                <Switch
                  defaultChecked={selectedPermissionActive}
                  size="small"
                  onChange={(e) => {
                    setFamilyData({
                      ...familyData,
                      permissions: familyData.permissions.map((p) => {
                        if (p.advisorRef !== selectedPermission.advisorRef) {
                          return p;
                        } else {
                          return {
                            ...p,
                            ...(e.target.checked
                              ? { reactivate: true }
                              : { deactivate: true }),
                          };
                        }
                      }),
                    });
                  }}
                />
              )}
            </Box>
          )}
          <AddDelegate
            onAdd={(account: AccountStub) => {
              setFamilyData({
                ...familyData,
                permissions: [
                  ...familyData.permissions,
                  defaultPermissions(account),
                ],
              });
              setSelectedPermissionAdvisorRef(account.ref);
            }}
          />
        </>
      )}
    </Box>
  );
};
