import { useEffect, useState } from "react";
import GridPage from "components/layout/GridPage";
import {
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Loading from "components/common/Loading";
import { useListAuditRecords } from "services/admin";
import DateDisplay from "components/common/DateDisplay";
import useIsMobile from "components/hooks/useIsMobile";

export default () => {
  const isMobile = useIsMobile();
  const [modalData, setModalData] = useState<string | null>(null);
  const { data, loading, request } = useListAuditRecords();
  useEffect(() => {
    request();
  }, []);

  return (
    <GridPage
      sx={{
        padding: isMobile ? "32px 24px" : "64px 48px",
        margin: isMobile ? "" : "auto",
        maxWidth: "1000px",
      }}
    >
      <Typography variant="title" id="tableTitle">
        Audit Log
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="audit log table">
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Event Type</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              (data?.records || []).map((record) => (
                <TableRow
                  key={record.ref}
                  hover
                  onClick={() => setModalData(record.contextJson)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>
                    <DateDisplay
                      date={new Date(Number(record.timestampSec) * 1000)}
                    />
                  </TableCell>
                  <TableCell>{record.eventType}</TableCell>
                  <TableCell>{record.actorName}</TableCell>
                  <TableCell>{record.description}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <Loading />}
      <Modal
        open={modalData !== null}
        onClose={() => setModalData(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Context
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, whiteSpace: "pre" }}
          >
            {modalData}
          </Typography>
        </Box>
      </Modal>
    </GridPage>
  );
};
