import React, { useState } from "react";
import { Box, Chip, Menu, MenuItem } from "@mui/material";
import { ChevronDown } from "lucide-react";

export default ({
  status,
  setStatus,
  paymentDisabled = false,
  loading = false,
}: {
  status: string;
  paymentDisabled?: boolean;
  loading?: boolean;
  setStatus: (s: string) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const values = new Map<string, [string, string, string]>([
    ["pending_approval", ["Pending", "rgba(255, 235, 238, 0.95)", "#D32F2F"]], // Soft pink background, deep red text
    ["approved", ["Approved", "rgba(255, 249, 230, 0.95)", "#D48806"]], // Soft yellow background, deep amber text
    ["paid", ["Paid", "rgba(237, 247, 237, 0.95)", "#388E3C"]], // Soft green background, dark green text
    ["deferred", ["Deferred", "rgba(230, 243, 255, 0.95)", "#1976D2"]], // Soft blue background, deep blue text
    // Remove.
    // ["pending", ["Pending", "rgba(255, 249, 230, 0.95)", "#D48806"]], // Soft yellow background, deep amber text
    // ["unpaid", ["Unpaid", "rgba(255, 235, 238, 0.95)", "#D32F2F"]], // Soft pink background, deep red text
  ]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!loading) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: string) => {
    if (paymentDisabled && (value === "paid" || value === "approved")) return;
    setStatus(value);
    handleClose();
  };

  return (
    <>
      <Chip
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100px", // Fixed width for the Chip
              fontWeight: 500,
            }}
          >
            {values.get(status)?.[0] || "Select Status"}
            <ChevronDown size={16} style={{ marginLeft: "4px" }} />
          </Box>
        }
        onClick={handleClick}
        style={{
          backgroundColor: values.get(status)?.[1] || "#F5F5F5",
          color: values.get(status)?.[2] || "#000",
          width: "120px", // Fixed width for the entire Chip
        }}
        disabled={loading}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Array.from(values.entries()).map(([key, [label]]) => (
          <MenuItem key={key} onClick={() => handleSelect(key)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
