import { ArrowLeft } from "lucide-react";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import { ReactNode } from "react";
import useIsMobile from "../hooks/useIsMobile";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

type Props = {
  onBack?: () => void;
  children?: ReactNode;
};
export default ({ onBack, children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const onClick = () => {
    if (onBack) {
      onBack();
    } else if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      // If we're at the top of the history stack, navigate to the inbox
      const parts = location.pathname.split("/");
      const newPath = parts.slice(0, parts.length - 1).join("/");
      navigate(newPath);
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      width={"100%"}
      gap="8px"
      alignItems={"center"}
      sx={{
        padding: "20px 10px 0 10px",
      }}
    >
      {isMobile && (
        <IconButton onClick={onClick}>
          <ArrowLeft />
        </IconButton>
      )}
      <Box display="flex" gap="6px" flexWrap="wrap" width={"100%"}>
        {children}
      </Box>
    </Box>
  );
};
