import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import useIsMobile from "../hooks/useIsMobile";
import useIsDesktop from "../hooks/useIsDesktop";

type TileContent = {
  title: string;
  icon: ReactNode;
  // Takes precedence over path
  onclick?: () => void;
  path?: string;
};

const Tile = ({ tile }: { tile: TileContent }) => {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flex: isMobile
          ? undefined
          : tile.title === "Recommendations"
            ? "0 1 auto"
            : undefined,
        width: isDesktop ? "23%" : "47%",
        padding: "28px 16px",
        alignItems: "center",
        justifyContent: "start",
        cursor: "pointer",
        alignSelf: "stretch",
        border: "1px solid #D4D4D4",
        borderRadius: "12px",
        background: "#FFF",
      }}
      onClick={() => {
        if (tile.onclick) {
          tile.onclick();
        } else if (tile.path) {
          navigate(tile.path);
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        gap="8px"
        alignItems="center"
        justifyContent="start"
      >
        {!isMobile && tile.icon}
        <Typography
          variant="bodyHeavy"
          sx={{
            color: "#3D3D3D",
          }}
        >
          {tile.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default ({ tiles }: { tiles: TileContent[] }) => {
  if (!tiles.length) return null;
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: "16px",
        alignSelf: "stretch",
        marginBottom: "25px",
      }}
    >
      {tiles.map((tile, idx) => (
        <Tile key={idx} tile={tile} />
      ))}
    </Box>
  );
};
