import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  TextField,
  Radio,
  FormControlLabel,
} from "@mui/material";
import set from "lodash/set";
import auth from "components/nux/auth";
import { useAddMemberDetails } from "services/nux";
import { useGetMemberDetails, useUpdateMembersFamily } from "services/member";
import { getStepPath } from "components/nux/utils";
import NuxContentLayout from "components/nux/NuxContentLayout";
import useIsMobile from "components/hooks/useIsMobile";
import AddressAutocomplete, {
  Location,
} from "components/common/AddressAutocomplete";
import { USER_NUX_TYPE } from "components/nux/constants";
import PhoneInput from "components/creation/PhoneInput";
import { cleanPhone } from "../../../common/utils";
import { EphemeralMember } from "components/member/types";
import { FormErrors } from "components/member/types";
import { validateLocation, validateContact } from "components/member/utils";
import { validatePassword } from "common/utils";
import { Member } from "protogen/common_pb";
import { updates } from "components/member/types";
import AuthService from "services/auth";
import PasswordInput from "components/common/PasswordInput";

const PREFERRED_CONTACT_METHODS = ["Text", "Email", "Call"];

export default () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [member, setMember] = useState<Member>(new Member());
  const [formData, setFormData] = useState<EphemeralMember>({});
  const [familyAddress, setFamilyAddress] = useState<Location>();
  const [errors, setErrors] = useState<FormErrors>({});
  const [prefContactMethod, setPrefContactMethod] = useState<string>("text");
  const [prefContactTimes, setPrefContactTimes] = useState<string>("");
  const { request, loading: updateMemberDetailsLoading } = useAddMemberDetails(
    (r) => {
      if (r?.nuxDetails?.stepName) {
        const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.MEMBER);
        navigate(path);
      }
    },
  );
  const { request: updateFamilyRequest, loading: updateFamilyLoading } =
    useUpdateMembersFamily();
  const { request: updatePasswordRequest, loading: updatePasswordLoading } =
    AuthService.useUpdatePassword();

  const {
    request: getMemberRequest,
    loading,
    data,
  } = useGetMemberDetails((r) => {
    if (!r?.member) return;
    setMember(r.member);
    setFormData(r.member);
    const familyAddress = r?.family?.address || "";
    if (familyAddress && familyAddress != "") {
      setFamilyAddress(JSON.parse(familyAddress));
    }
  });

  const createChangeHandler = (
    path: string[],
    event: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const updatedFormData = { ...formData };
    const value = typeof event === "string" ? event : event.target.value;

    set(updatedFormData, path, value);
    setFormData(updatedFormData);
  };

  const onContinue = async () => {
    const errors = {
      ...validateLocation(JSON.stringify(familyAddress)),
      ...validateContact(formData),
      ...validatePassword(formData.password || ""),
    };
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (data?.family?.ref) {
      await updateFamilyRequest({
        familyRef: data?.family?.ref,
        updatedAddress: familyAddress ? JSON.stringify(familyAddress) : "",
        newMembers: [],
        updatedMembers: [updates(member, formData)],
      });
    }
    await updatePasswordRequest({ password: formData.password || "" });
    await request({
      token: auth.getAuthData(),
      prefContactMethod,
      prefContactTimes,
    });
  };

  useEffect(() => {
    getMemberRequest();
  }, []);

  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "36px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              alignSelf: isMobile ? "start" : "center",
            }}
            variant="title"
          >
            Let's start off with some of the basics
          </Typography>
          <Box
            sx={{
              alignSelf: "flex-start",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Typography variant="h3Serif">Your information</Typography>
            <Box sx={{ display: "flex", gap: "32px", flexWrap: "wrap" }}>
              <TextField
                sx={{ width: isMobile ? "100%" : "calc(50% - 16px)" }}
                margin="none"
                label="First name"
                type="text"
                variant="outlined"
                autoComplete="off"
                value={formData.firstName || ""}
                error={!!errors?.firstName}
                helperText={errors?.firstName}
                onChange={(e) => createChangeHandler(["firstName"], e)}
              />
              <TextField
                sx={{ width: isMobile ? "100%" : "calc(50% - 16px)" }}
                margin="none"
                label="Last name"
                type="text"
                variant="outlined"
                autoComplete="off"
                value={formData.lastName || ""}
                error={!!errors?.lastName}
                helperText={errors?.lastName}
                onChange={(e) => createChangeHandler(["lastName"], e)}
              />
              <PhoneInput
                value={formData.primaryPhone || ""}
                error={!!errors?.primaryPhone}
                helperText={errors?.primaryPhone}
                onChange={(phone) => {
                  createChangeHandler(
                    ["primaryPhone"],
                    cleanPhone(
                      typeof phone == "string" ? phone : phone.target.value,
                    ),
                  );
                }}
                label="Phone"
                size="small"
                sx={{
                  width: "100%",
                  flexGrow: 1,
                }}
                autoComplete="off"
              />
              <TextField
                sx={{ width: "100%" }}
                margin="none"
                label="Email"
                name="username"
                type="email"
                variant="outlined"
                autoComplete="on"
                value={formData.primaryEmail || ""}
              />
              <PasswordInput
                password={formData.password || ""}
                onChange={(pw) => {
                  createChangeHandler(["password"], pw);
                }}
                sx={{ width: "100%" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              alignSelf: "flex-start",

              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Typography variant="h3Serif">Home address</Typography>
            {!loading && (
              <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
                <AddressAutocomplete
                  label={"Home address"}
                  error={errors?.location}
                  initialValue={familyAddress?.formattedAddress || ""}
                  setValue={(location) => {
                    setFamilyAddress(location);
                  }}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              alignSelf: "flex-start",

              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography variant="h3Serif">Contact preferences</Typography>
              <Typography variant="body" color="#3D3D3D">
                If we need to reach you ASAP, what's your preferred way to be
                contacted?
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {PREFERRED_CONTACT_METHODS.map((method) => {
                  return (
                    <FormControlLabel
                      key={method}
                      value={method}
                      label={method}
                      control={
                        <Radio
                          sx={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            paddingRight: "12px",
                          }}
                          checked={prefContactMethod === method}
                          onChange={() => {
                            setPrefContactMethod(method);
                          }}
                          inputProps={{ "aria-label": "B" }}
                        />
                      }
                    />
                  );
                })}
              </Box>
            </Box>
            <Box sx={{ marginTop: "16px", marginBottom: "8px" }}>
              <Typography variant="body" color="#3D3D3D">
                When are the best times to contact you?
              </Typography>
            </Box>
            <TextField
              sx={{ marginTop: "0px" }}
              onChange={(e) => {
                setPrefContactTimes(e.target.value);
              }}
              value={prefContactTimes}
              multiline
              rows={4}
            />
          </Box>
          <Box
            sx={{ alignSelf: "flex-end", width: isMobile ? "100%" : "auto" }}
          >
            <Button
              sx={{ width: "100%" }}
              onClick={onContinue}
              disabled={
                updateMemberDetailsLoading ||
                updateFamilyLoading ||
                updatePasswordLoading
              }
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
