import { Box, Typography, Button } from "@mui/material";
import useLocalStorageState from "components/hooks/useLocalStorageState";
import LinkRouter from "components/navigation/LinkRouter";
import { Check } from "lucide-react";
import { useEffect } from "react";
import {
  useGetAdvisorCalendarIntegrations,
  useGetMemberCalendarIntegrations,
} from "services/calendar";

const Todo = ({
  title,
  description,
  done,
  url,
  cta,
}: {
  title: string;
  description: string;
  done: boolean;
  url: string;
  cta: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: "16px",
      }}
    >
      <div
        style={{
          flexShrink: 0,
          marginTop: "4px",
        }}
      >
        {done ? (
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "#198282",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            <Check size={16} />
          </div>
        ) : (
          <div
            style={{
              width: "24px",
              height: "24px",
              border: "2px solid #e5e7eb",
              backgroundColor: "white",
              borderRadius: "50%",
            }}
          />
        )}
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h4">
          <LinkRouter to={url} inline={true}>
            {title}
          </LinkRouter>
        </Typography>

        <Typography variant="body">{description}</Typography>

        {!done ? (
          <Button size="small" sx={{ marginTop: "8px", flex: 1 }}>
            <LinkRouter to={url} inline={true}>
              {cta}
            </LinkRouter>
          </Button>
        ) : (
          <Typography
            variant="bodySmallHeavy"
            sx={{ color: "#198282", lineHeight: "48px", textAlign: "center" }}
          >
            Completed!
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const Todos = ({ children }: { children: React.ReactNode }) => {
  const [dismissed, setDismissed] = useLocalStorageState(
    "calendarImportExportCTA",
    false,
  );
  if (dismissed) return null;
  return (
    <Box sx={{ marginBottom: "16px" }}>
      <div
        style={{
          background:
            "linear-gradient(343deg, rgb(232, 248, 248), rgb(237, 248, 246))",
          padding: "24px 20px",
          borderRadius: "12px",
          marginBottom: "14px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box sx={{ display: "flex", gap: "16px" }}>
          {children}
          <Button
            variant="text"
            size="small"
            sx={{ height: "20px" }}
            onClick={() => setDismissed(true)}
          >
            Dismiss
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export const MemberCalendarTodos = ({}: {}) => {
  const { request, data } = useGetMemberCalendarIntegrations();
  useEffect(() => {
    request({
      includeMemberEmailProviders: true,
    });
  }, []);
  if (!data) {
    return null;
  }
  const hasImports = data.importIntegrations.length > 0;
  const hasExports = data.exportIntegrations.length > 0;
  if (hasImports && hasExports) return null;
  return (
    <Todos>
      <Todo
        title="Sync your calendars to Faye"
        description="Link your calendar to Faye so your Advisor can see availability and anticipate upcoming needs."
        done={hasImports}
        url="/settings/calendars?import=true"
        cta="Import calendars"
      />
      <Todo
        title="Export the Faye calendar"
        description="View and manage your Faye Family calendar from any client you prefer."
        done={hasExports}
        url="/settings/calendars?export=true"
        cta="Export Faye calendar"
      />
    </Todos>
  );
};

export const AdvisorCalendarTodos = ({}: {}) => {
  const { request, data } = useGetAdvisorCalendarIntegrations();
  useEffect(() => {
    request({
      includeMemberEmailProviders: true,
    });
  }, []);
  if (!data) {
    return null;
  }
  const hasImports = data.importIntegrations.length > 0;
  if (hasImports) return null;
  return (
    <Todos>
      <Todo
        title="Sync your calendars to Faye"
        description="Link your calendar to Faye so you can manage your own availability."
        done={hasImports}
        url="/settings/calendars?import=true"
        cta="Import calendars"
      />
    </Todos>
  );
};
