import useProtoMethod from "./useProtoMethod";

import {
  DeletePhoneCallRequest,
  DeletePhoneCallResponse,
  GetPhoneCallRequest,
  GetPhoneCallResponse,
  GetTwilioTokenRequest,
  GetTwilioTokenResponse,
  InitiateCallRequest,
  InitiateCallResponse,
  StartTranscriptionRequest,
  StartTranscriptionResponse,
  TransferCallDeviceRequest,
  TransferCallDeviceResponse,
  LiveTranscriptionsRequest,
  LiveTranscriptionsResponse,
} from "../protogen/phone_service_pb";

const useGetTwilioToken = (callback?: (r: GetTwilioTokenResponse) => void) => {
  return useProtoMethod<GetTwilioTokenRequest, GetTwilioTokenResponse>(
    "GetTwilioToken",
    GetTwilioTokenResponse,
    { callback },
  );
};

const useInitiateCall = (callback?: (r: InitiateCallResponse) => void) => {
  return useProtoMethod<InitiateCallRequest, InitiateCallResponse>(
    "InitiateCall",
    InitiateCallResponse,
    { callback },
  );
};

const useTransferCallDevice = (
  callback?: (r: TransferCallDeviceResponse) => void,
) => {
  return useProtoMethod<TransferCallDeviceRequest, TransferCallDeviceResponse>(
    "TransferCallDevice",
    TransferCallDeviceResponse,
    { callback },
  );
};

const useGetPhoneCall = (callback?: (r: GetPhoneCallResponse) => void) => {
  return useProtoMethod<GetPhoneCallRequest, GetPhoneCallResponse>(
    "GetPhoneCall",
    GetPhoneCallResponse,
    {
      callback,
    },
  );
};

const useDeletePhoneCall = (
  callback?: (r: DeletePhoneCallResponse) => void,
) => {
  return useProtoMethod<DeletePhoneCallRequest, DeletePhoneCallResponse>(
    "DeletePhoneCall",
    DeletePhoneCallResponse,
    {
      callback,
    },
  );
};

const useStartTranscription = (
  callback?: (r: StartTranscriptionResponse) => void,
) => {
  return useProtoMethod<StartTranscriptionRequest, StartTranscriptionResponse>(
    "StartTranscription",
    StartTranscriptionResponse,
    {
      callback,
    },
  );
};

const useDeepgramLiveTranscriptions = (
  callback?: (r: LiveTranscriptionsResponse) => void,
) => {
  return useProtoMethod<LiveTranscriptionsRequest, LiveTranscriptionsResponse>(
    "GetCallTranscriptions",
    LiveTranscriptionsResponse,
    {
      callback,
    },
  );
};

export {
  useGetTwilioToken,
  useInitiateCall,
  useGetPhoneCall,
  useDeletePhoneCall,
  useTransferCallDevice,
  useStartTranscription,
  useDeepgramLiveTranscriptions,
};
