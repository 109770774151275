import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Fade,
} from "@mui/material";
import { Copy, Eye } from "lucide-react";
import { useState, useRef } from "react";

export default ({
  value,
  label,
  onReveal,
  sx,
}: {
  value?: string | null;
  label?: string | null;
  onReveal?: () => Promise<string>;
  sx?: React.CSSProperties;
}) => {
  const [revealedValue, setRevealeValue] = useState<string | null>(
    value || null,
  );
  const [copied, setCopied] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null); // Ref to access the input

  const handleCopy = async (selectText: boolean) => {
    if (revealedValue) {
      if (selectText && textFieldRef.current) {
        textFieldRef.current.select();
      }
      await navigator.clipboard.writeText(revealedValue);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500); // Hide "Copied" after 1.5 seconds
    }
  };

  const handleReveal = async () => {
    const revealed = await onReveal?.();
    setRevealeValue(revealed || null);
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      value={revealedValue || (onReveal ? "**********" : "")}
      inputRef={textFieldRef}
      label={label}
      InputProps={{
        readOnly: true,
        endAdornment:
          revealedValue || !onReveal ? (
            <InputAdornment
              position="end"
              sx={{
                paddingTop: "0px !important",
                paddingLeft: "15px !important",
                paddingRight: "12px",
              }}
            >
              <Box
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Fade in={!copied} timeout={300}>
                  <IconButton onClick={() => handleCopy(false)} edge="end">
                    <Copy width="20" />
                  </IconButton>
                </Fade>
                <Fade in={copied} timeout={300}>
                  <Typography
                    variant="body"
                    color="primary"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "700",
                      paddingRight: "10px",
                    }}
                  >
                    Copied
                  </Typography>
                </Fade>
              </Box>
            </InputAdornment>
          ) : (
            <InputAdornment
              position="end"
              sx={{
                paddingTop: "0px !important",
                paddingLeft: "15px !important",
                paddingRight: "12px",
              }}
            >
              <Box
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Fade in={!revealedValue} timeout={300}>
                  <IconButton
                    onClick={handleReveal}
                    edge="end"
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Eye width="20" />
                  </IconButton>
                </Fade>
              </Box>
            </InputAdornment>
          ),
      }}
      onClick={() => handleCopy(true)}
      sx={{
        bgcolor: "#FAF9FA",
        borderRadius: "12px",
        "& fieldset": { border: "none" },
        ".MuiInputBase-root input": {
          marginTop: "14px",
          padding: "0 0 0 20px",
        },
        ...sx,
      }}
    />
  );
};
