import GridPage from "components/layout/GridPage";
import { List, ListItem, Typography, Switch, Link } from "@mui/material";
import WithDividers from "components/helpers/WithDividers";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { FEATURE_FLAGS, FeatureFlag } from "components/helpers/GatedComponent";

import useIsMobile from "components/hooks/useIsMobile";

const Feature = ({ flag }: { flag: FeatureFlag }) => {
  const [checked, setChecked] = useState(!!localStorage.getItem(flag.value));
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      localStorage.setItem(flag.value, "on");
    } else {
      localStorage.removeItem(flag.value);
    }
    setChecked(event.target.checked);
  };
  return (
    <ListItem>
      <ListItemText
        id="switch-label-family-"
        primary={flag.title}
        secondary={flag.description}
      />
      <Switch
        edge="end"
        onChange={onChange}
        checked={checked}
        inputProps={{
          "aria-labelledby": "switch-label-family-",
        }}
      />
    </ListItem>
  );
};

export default () => {
  const isMobile = useIsMobile();
  return (
    <GridPage
      sx={{
        margin: isMobile ? "" : "64px auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Typography variant="title" id="tableTitle">
        Feature switches
      </Typography>
      <Typography variant={"body"}>
        Turn local features on and off. Changes may require a page{" "}
        <Link
          href={"#"}
          onClick={() => {
            window.location.reload();
          }}
        >
          refresh
        </Link>
        .
      </Typography>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <WithDividers>
          {Object.values(FEATURE_FLAGS).map((flag) => (
            <Feature key={flag.title} flag={flag} />
          ))}
        </WithDividers>
      </List>
    </GridPage>
  );
};
