import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";
import {
  useAssignEmailToInbox,
  useFetchUnassignedEmails,
} from "services/email";
import Loading from "components/common/Loading";
import { FetchUnassignedEmailsResponse_UnassignedEmail as UnassignedEmail } from "protogen/email_service_pb";
import DateDisplay from "components/common/DateDisplay";

import {
  Alert,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Family } from "protogen/advisors_service_pb";
import SnackPack, { SnackbarMessage } from "components/common/SnackPack";
import EmailAttachmentsTable from "components/activity/EmailAttachmentsTable";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useNavigate } from "react-router-dom";
import GridPage from "components/layout/GridPage";
import EmailLetter from "components/email/EmailLetter";
import { useAdminListAllAdvisors, useListAllFamilies } from "services/admin";
import { Advisor } from "protogen/common_pb";
import useIsMobile from "components/hooks/useIsMobile";

interface InboxOption {
  inboxType: string;
  inboxEntityRef: string;
}

const InboxAssigner = ({
  entry,
  advisors,
  families,
  addMessage,
}: {
  entry: UnassignedEmail;
  advisors: Advisor[];
  families: Family[];
  addMessage: (m: SnackbarMessage) => void;
}) => {
  const [inbox, setInbox] = useState<InboxOption | null>(null);
  const { request, loading } = useAssignEmailToInbox((r) => {
    addMessage({
      key: r?.email?.ref,
      message: `Assigned email to ${inbox?.inboxType} inbox ${inbox?.inboxEntityRef}`,
    });
  });

  const onAssign = () => {
    if (inbox) {
      request({
        emailRef: entry.email.ref,
        inboxType: inbox.inboxType,
        inboxEntityRef: inbox.inboxEntityRef,
      });
    }
  };
  return (
    <Box display="flex">
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="select-label">Inbox</InputLabel>
        <Select
          labelId="select-label"
          id="select-label"
          value={inbox ? `${inbox?.inboxType}|${inbox?.inboxEntityRef}` : ""}
          onChange={(event) => {
            const value = event.target.value as string;
            const [inboxType, inboxEntityRef] = value.split("|");
            setInbox({
              inboxType,
              inboxEntityRef,
            });
          }}
          input={<OutlinedInput label="Family" />}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <ListSubheader>Advisor Inboxes</ListSubheader>
          {advisors
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
            .map((advisor) => (
              <MenuItem
                key={`advisor-${advisor.ref}`}
                value={`advisor|${advisor.ref}`}
              >
                {advisor.displayName}
              </MenuItem>
            ))}
          <ListSubheader>Family Inboxes</ListSubheader>
          {families
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((family) => (
              <MenuItem
                key={`advisor-${family.ref}`}
                value={`family|${family.ref}`}
              >
                {family.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <IconButton
        color="primary"
        aria-label="assign"
        disabled={loading || !inbox}
        onClick={onAssign}
      >
        <ArrowForwardIcon />
      </IconButton>
    </Box>
  );
};
const EmailRow = ({
  entry,
  advisors,
  families,
  addMessage,
  initOpen = false,
}: {
  entry: UnassignedEmail;
  advisors: Advisor[];
  families: Family[];
  addMessage: (m: SnackbarMessage) => void;
  initOpen: boolean;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(initOpen);
  const time = DateDisplay({
    date: new Date(Number(entry.email.timestampSec) * 1000),
  });
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (!open) {
                const newQueryParams = new URLSearchParams();
                newQueryParams.set("ref", entry.email.ref);
                navigate({ search: newQueryParams.toString() });
              }
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{time}</TableCell>
        <TableCell>{entry.toAddress}</TableCell>
        <TableCell>{entry.fromAddress}</TableCell>
        <TableCell>
          <i>{entry.email.subject}</i>
        </TableCell>
        <TableCell>
          <InboxAssigner
            entry={entry}
            advisors={advisors}
            families={families}
            addMessage={addMessage}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: "2vw",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                padding: "12px 12px 12px calc(142px)",
                borderRadius: "10px",
              }}
            >
              <EmailLetter email={entry.email} />
            </Box>
            {entry.email.attachments.length > 0 && (
              <EmailAttachmentsTable attachments={entry.email.attachments} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default () => {
  const isMobile = useIsMobile();
  const {
    data: emailData,
    request: emailRequest,
    loading,
  } = useFetchUnassignedEmails();
  const { data: familiesData, request: familiesRequest } = useListAllFamilies();
  const { data: advisorsData, request: advisorsRequest } =
    useAdminListAllAdvisors();
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedRef = queryParams.get("ref") || "";

  useEffect(() => {
    emailRequest({
      cursor: "",
    });
    familiesRequest({});
    advisorsRequest({});
  }, []);
  if (loading || emailData === null) {
    return <Loading />;
  }

  const allFamilies = familiesData ? familiesData.families : [];
  const allAdvisors = advisorsData ? advisorsData.advisors : [];
  return (
    <GridPage
      sx={{
        margin: isMobile ? "" : "64px auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Typography variant="title" id="tableTitle">
        Lost Emails
      </Typography>
      <Alert severity="info">
        These are emails that were received by our system without being matched
        to a family. If an email was sent from an alternate address from one of
        your families, consider adding the alias on the family's profile page.
      </Alert>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SnackPack snackPack={snackPack} />
        {emailData.emails.length === 0 && (
          <Alert severity="info">No unassigned emails.</Alert>
        )}
        {emailData.emails.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Received</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Assign</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {emailData.emails.map((entry) => (
                  <EmailRow
                    key={entry.email.ref}
                    entry={entry}
                    families={allFamilies}
                    advisors={allAdvisors}
                    addMessage={(message: SnackbarMessage) => {
                      setSnackPack((prev) => [...prev, message]);
                    }}
                    initOpen={selectedRef === entry.email.ref}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </GridPage>
  );
};
