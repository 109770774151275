import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import DocumentEditor from "../editor/DocumentEditor";
import { useState, useEffect } from "react";
import {
  DEFAULT_EVENT_DURATION_MS,
  EphemeralEvent,
  FormErrors,
  reminderOptions,
} from "../../types/calendars";
import FamilySelect from "../creation/FamilySelect";
import { AutocompleteEntry } from "../email/AddressCompletion";
import { RecurringUnit, EventReminder } from "protogen/calendar_pb";
import AddressInputList from "../email/AddressInputList";
import useIsMobile from "../hooks/useIsMobile";
import TimezoneDropdown from "../common/TimezoneDropdown";
import { Event } from "@mui/icons-material";
import AddressAutocomplete from "components/common/AddressAutocomplete";

type RecurringOption = {
  unit: RecurringUnit | undefined;
  interval: number | undefined;
};

const recurringOptions: Record<string, RecurringOption> = {
  "Does not repeat": { unit: undefined, interval: undefined },
  Weekly: { unit: RecurringUnit.RecurringUnit_WEEK, interval: 1 },
  "Bi-weekly": { unit: RecurringUnit.RecurringUnit_WEEK, interval: 2 },
  Monthly: { unit: RecurringUnit.RecurringUnit_MONTH, interval: 1 },
  Yearly: { unit: RecurringUnit.RecurringUnit_YEAR, interval: 1 },
};

const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

const EventDate = ({
  label,
  disabled,
  value,
  update,
  error,
  format,
}: {
  label: string;
  disabled: boolean;
  value?: Date;
  update: (d: Date | null) => void;
  error?: string | null;
  format?: string | null;
}) => {
  const [dateOpen, setDateOpen] = useState(false);
  return (
    <FormControl sx={{ width: "100%" }}>
      <DatePicker
        disabled={disabled}
        label={label}
        value={value}
        sx={{ width: "100%", ".MuiButtonBase-root": { marginRight: "unset" } }}
        onChange={update}
        format={format ? format : "MM/dd/yyyy"}
        open={dateOpen}
        onClose={() => setDateOpen(false)}
        slotProps={{
          textField: {
            error: !!error,
            helperText: error,
            onClick: () => setDateOpen(true),
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setDateOpen(true)} edge="end">
                    <Event />
                  </IconButton>
                </InputAdornment>
              ),
            },
          },
        }}
      />
    </FormControl>
  );
};
interface SectionProps {
  loading: boolean;
  errors: FormErrors | null;
  formData: EphemeralEvent;
  updateFormData: (f: EphemeralEvent) => void;
  sectionDisabled?: boolean;
}
const EventRange = ({
  loading,
  errors,
  formData,
  updateFormData,
}: SectionProps) => {
  if (formData.allDay) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <EventDate
          disabled={loading}
          value={formData.startDate}
          label="Start date"
          error={errors?.startDate}
          update={(v: Date | null) => {
            let updates: Partial<EphemeralEvent> = {
              startDate: v ? new Date(v) : undefined,
            };
            // Auto update the enddate for the user if start date is past or equal to end date
            if (v && formData.endDate && v >= formData.endDate) {
              updates.endDate = v ? new Date(v) : undefined;
            }
            if (v && formData.endDate && !isSameDay(v, formData.endDate)) {
              updates.recurringUnit = undefined;
              updates.recurringInterval = undefined;
              updates.isRecurring = false;
            }
            updateFormData(updates);
          }}
        />
        <Typography variant="h3" sx={{ color: "text.tertiary" }}>
          -
        </Typography>
        <EventDate
          disabled={loading}
          label="End date"
          value={formData.endDate}
          error={errors?.endDate}
          update={(v: Date | null) => {
            let updates: Partial<EphemeralEvent> = {
              endDate: v ? new Date(v) : undefined,
            };
            // Auto update the start date for the user if end date is before start date
            if (v && formData.endDate && v < formData.endDate) {
              updates.startDate = v ? new Date(v) : undefined;
            }
            if (v && formData.startDate && !isSameDay(v, formData.startDate)) {
              updates.recurringUnit = undefined;
              updates.recurringInterval = undefined;
              updates.isRecurring = false;
            }
            updateFormData(updates);
          }}
        />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        alignItems: "center",
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        <EventDate
          value={formData.startDate}
          disabled={loading}
          label="Date"
          update={(v: Date | null) =>
            updateFormData({
              startDate: v || undefined,
              endDate: v || undefined,
            })
          }
          format="EEEE, MMM d"
          error={errors?.startDate}
        />
      </FormControl>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <DesktopTimePicker
          label="Start time"
          sx={{ ".MuiButtonBase-root": { marginRight: "unset" } }}
          value={formData.startDate}
          onChange={(v: Date | null) => {
            // Use the default if we don't have start/end, or their difference is 0.
            const interval =
              (formData.startDate &&
                formData.endDate &&
                formData.endDate.getTime() - formData.startDate.getTime()) ||
              DEFAULT_EVENT_DURATION_MS;
            if (v) {
              updateFormData({
                startDate: v,
                // Add 1 hour to the end date
                endDate: new Date(v.getTime() + interval),
              });
            } else {
              updateFormData({ startDate: v || undefined });
            }
          }}
          slotProps={{
            textField: {
              error: !!errors?.startDate,
              helperText: errors?.startDate,
            },
          }}
        />
        <Typography variant="h3" sx={{ color: "text.tertiary" }}>
          -
        </Typography>
        <DesktopTimePicker
          label="End time"
          sx={{ ".MuiButtonBase-root": { marginRight: "unset" } }}
          value={formData.endDate}
          onChange={(v: Date | null) =>
            updateFormData({ endDate: v || undefined })
          }
          slotProps={{
            textField: {
              error: !!errors?.endDate,
              helperText: errors?.endDate,
            },
          }}
        />
      </Box>
    </Box>
  );
};

const RecurringSection = ({
  loading,
  errors,
  formData,
  updateFormData,
  sectionDisabled = false,
}: SectionProps) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  let value = Object.keys(recurringOptions).find(
    (key) =>
      recurringOptions[key].unit === formData?.recurringUnit &&
      recurringOptions[key].interval === formData?.recurringInterval,
  );
  if (!value || sectionDisabled) {
    value = "Does not repeat";
  }

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        <FormControl sx={{ width: "100%", cursor: "pointer" }}>
          <InputLabel id="select-label">Repeats</InputLabel>
          <Select
            sx={{ cursor: "pointer" }}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            disabled={loading || sectionDisabled}
            labelId="select-label"
            id="select-label"
            error={!!errors?.recurringUnit}
            label={"Repeats"}
            defaultValue="Does not repeat"
            value={value}
            renderValue={(value) => {
              if (sectionDisabled) {
                return "Multi-day events cannot repeat";
              }
              return value;
            }}
            onChange={(e) => {
              const selectedOption = recurringOptions[e.target.value];
              updateFormData({
                recurringUnit: selectedOption.unit,
                recurringInterval: selectedOption.interval,
                isRecurring: e.target.value !== "Does not repeat",
              });
            }}
          >
            {Object.keys(recurringOptions).map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

const ReminderSection = ({
  errors,
  formData,
  updateFormData,
}: SectionProps) => {
  const [reminders, setReminders] = useState<EventReminder[]>([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const updateReminder = (value: string, index: number) => {
    setReminders((prevState) => {
      const newState = [...prevState].map((r, i) => {
        if (i === index) {
          return new EventReminder({
            title: value,
          });
        }
        return r;
      });

      updateFormData({
        reminders: newState,
      });
      return newState;
    });
  };

  useEffect(() => {
    if (formData.reminders && formData.reminders.length > 0) {
      setReminders(formData.reminders);
    } else {
      setReminders([new EventReminder({ title: "None" })]);
    }
  }, [formData]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        <FormControl
          onClick={() => setOpen(!open)}
          sx={{ width: "100%", cursor: "pointer" }}
        >
          <InputLabel id="select-label">Reminder</InputLabel>
          {reminders.map((r, i) => {
            return (
              <Select
                key={i}
                sx={{ cursor: "pointer" }}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                labelId="select-label"
                id="select-label"
                error={!!errors?.reminder}
                label={"Reminder"}
                defaultValue="None"
                value={r.title}
                renderValue={(value) => {
                  return value;
                }}
                onChange={(e) => {
                  updateReminder(e.target.value, i);
                }}
              >
                {reminderOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            );
          })}
        </FormControl>
      </Box>
    </Box>
  );
};

interface Props {
  formData: EphemeralEvent;
  updateFormData: (f: EphemeralEvent) => void;
  errors: FormErrors | null;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  selectFamily?: boolean;
  hideAction?: boolean;
  actionName?: string;
  attendeeAutocomplete?: AutocompleteEntry[];
  accountType?: "advisor" | "member";
}

export default ({
  loading,
  errors,
  formData,
  updateFormData,
  onSubmit,
  selectFamily = false,
  hideAction = false,
  actionName = "Save event",
  attendeeAutocomplete,
  accountType = "advisor",
}: Props) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  let initialDetailsText: string | undefined = undefined;
  if (formData?.description?.json) {
    initialDetailsText = JSON.parse(formData?.description?.json || "{}");
  } else if (formData?.initialDetailsText) {
    initialDetailsText = formData?.initialDetailsText;
  }

  const [hover, setHover] = useState(false);
  const getLocalTimezone = () => {
    return new Date()
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: isMobile ? "column" : undefined,
        gap: "48px",
        height: "100%",
        paddingTop: "22px",
        ...(hover ? { backgroundColor: "#e8f4f8" } : {}),
      }}
    >
      <Box>
        <Typography variant="h4">Details</Typography>
        <TextField
          disabled={loading}
          label="Event name"
          fullWidth
          error={!!errors?.title}
          helperText={errors?.title}
          value={formData.title || ""}
          onChange={(e) => updateFormData({ title: e.target.value })}
          autoComplete="off"
          sx={{ margin: "12px 0px" }}
        />
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.border}`,
            padding: "18px 20px",
            borderRadius: "8px",
          })}
        >
          <DocumentEditor
            initialContent={initialDetailsText}
            initialAttachments={formData?.initialAttachments || []}
            disabled={loading}
            placeholder="Description"
            setContent={(c) => {
              updateFormData({ description: c });
            }}
            attachmentsEnabled={true}
            setDragState={setHover}
          />
        </Box>
        <Box sx={{ margin: "14px 0px" }}>
          <ButtonGroup fullWidth>
            <Button
              sx={{
                marginRight: "10px",
              }}
              variant={formData.allDay ? "buttonGroupSelected" : "buttonGroup"}
              onClick={() =>
                updateFormData({ allDay: true, displayTimezone: "" })
              }
            >
              All day
            </Button>
            <Button
              variant={!formData.allDay ? "buttonGroupSelected" : "buttonGroup"}
              onClick={() => updateFormData({ allDay: false })}
            >
              Specific time
            </Button>
          </ButtonGroup>
        </Box>
        <Box sx={{ marginBottom: "14px" }}>
          <EventRange
            loading={loading}
            errors={errors}
            formData={formData}
            updateFormData={updateFormData}
          />
        </Box>
        {errors && errors["displayTimezone"] && (
          <Typography color="secondary.dark">
            {errors["displayTimezone"]}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          width: "329px",
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" flexDirection="column" gap="12px">
          {selectFamily && (
            <>
              <Typography variant="h4">Family</Typography>
              <FamilySelect
                selectedFamily={formData.familyRef || null}
                onChange={(v) => updateFormData({ familyRef: v || undefined })}
                error={errors?.familyRef}
                disabled={loading}
              />
            </>
          )}
          <Typography variant="h4">Attendees</Typography>
          {accountType === "advisor" && (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <AddressInputList
                recipients={formData.invitees || []}
                setRecipients={(r) => updateFormData({ invitees: r })}
                autocompleteEntries={attendeeAutocomplete}
                placeholder={"Attendee email"}
                fullWidthInput={true}
                sx={{
                  minHeight: "60px",
                  border: `1px solid ${theme.palette.border}`,
                  borderRadius: "8px",
                  padding: "9px 20px",
                  gap: "6px",
                }}
              />
            </FormControl>
          )}
          <Box>
            <RecurringSection
              loading={loading}
              errors={errors}
              formData={formData}
              updateFormData={updateFormData}
              sectionDisabled={
                formData.startDate &&
                formData.endDate &&
                !isSameDay(formData.startDate, formData.endDate)
              }
            />
          </Box>
          {accountType === "advisor" && (
            <Box>
              <ReminderSection
                loading={loading}
                errors={errors}
                formData={formData}
                updateFormData={updateFormData}
              />
            </Box>
          )}
          <AddressAutocomplete
            restrictToAddresses={false}
            initialValue={
              formData.location
                ? JSON.parse(formData?.location || "")?.formattedAddress
                : ""
            }
            setValue={(location) => {
              updateFormData({
                location: JSON.stringify(location),
              });
            }}
            label={"Location"}
            error={errors?.location}
            handleCopy={!!formData.location}
          />
          {!formData.allDay && accountType === "advisor" && (
            <Box sx={{ marginBottom: "22px" }}>
              <TimezoneDropdown
                timezone={formData.displayTimezone || ""}
                setTimezone={(timezone) => {
                  updateFormData({ displayTimezone: timezone });
                }}
                sx={{ width: "100%" }}
                placeholder={"Display timezone (optional)"}
                isSelectionClearable={true}
              />
              <FormHelperText
                sx={{
                  padding: "0px 5px",
                  fontSize: "12px",
                }}
              >
                Event will be created in "
                {formData.displayTimezone || getLocalTimezone()}" and displayed
                in the viewer's local time. This changes the timezone in which
                this event is created.
              </FormHelperText>
            </Box>
          )}
        </Box>
        {!hideAction && (
          <Button
            sx={{ alignSelf: "flex-end" }}
            onClick={() => {
              onSubmit();
            }}
          >
            {actionName}
          </Button>
        )}
      </Box>
    </Box>
  );
};
