import { Box, Typography } from "@mui/material";
import { Dot } from "lucide-react";
import { RetrievalResult } from "protogen/search_service_pb";
import DateDisplay from "../common/DateDisplay";
import { iconForResult, urlForResult } from "./utils";
import LinkRouter from "../navigation/LinkRouter";
import useIsMobile from "components/hooks/useIsMobile";

type Props = {
  result: RetrievalResult;
  onClose: () => void;
};

export default ({ result, onClose }: Props) => {
  const entryDt = new Date(Number(result.indexedSec) * 1000);
  const isMobile = useIsMobile();

  return (
    <LinkRouter to={urlForResult(result)} onClick={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          cursor: "pointer",
          borderRadius: "16px",
          border: "1px solid #D4D4D4",
          alignItems: "flex-start",
          padding: isMobile ? "16px 24px" : "20px 24px 22px",
          gap: "12px",
        }}
      >
        {!isMobile && (
          <Box sx={{ color: "#8E9598" }}>{iconForResult(result)}</Box>
        )}
        <Box
          sx={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography variant="bodyHeavy" sx={{ wordBreak: "break-word" }}>
            {result.text}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="bodySmall" color="#6B6E7B">
              {result.sourceName}
            </Typography>
            <Dot color="#6B6E7B" />
            {
              <DateDisplay
                date={entryDt}
                sx={{ fontSize: "14px", color: "#6B6E7B" }}
              />
            }
          </Box>
          <Typography variant="bodySmall" color="#6B6E7B">
            {result.subText}
          </Typography>
        </Box>
      </Box>
    </LinkRouter>
  );
};
