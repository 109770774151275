import { Box, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { ReactNode, useState } from "react";
import useIsMobile, { nonHookIsMobile } from "components/hooks/useIsMobile";

type InstructionStep = {
  number?: number;
  content: string | ReactNode;
};

const Steps = ({
  steps,
  width,
  justifyContent,
}: {
  steps: InstructionStep[];
  width?: string;
  justifyContent?: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: width,
      }}
    >
      {steps.map((step, i) => (
        <Box
          key={i}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            alignItems: "start",
            justifyContent: justifyContent || "center",
          }}
        >
          {step.number && (
            <Box
              sx={{
                display: "flex",
                width: "32px",
                height: "32px",
                padding: "8px 15px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                borderRadius: "100px",
                background: "#E3EFED",
                fontSize: "14px",
                fontWeight: "600",
                color: "#3D3D3D",
              }}
            >
              {step.number}
            </Box>
          )}
          <Typography variant="body" color="#3D3D3D" marginTop="6px">
            {step.content}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export const instructionPage = (
  imagePath: string,
  direction: string,
  steps?: InstructionStep[],
) => {
  const isMobile = nonHookIsMobile();

  return (
    <div
      style={{
        display: "flex",
        flexDirection:
          !isMobile && direction === "horizontal" ? "row" : "column-reverse",
        alignItems: isMobile ? "center" : "center",
        justifyContent: "center",
        gap: "24px",
        width: "100%",
        padding: isMobile ? "12px 0" : "0px 12px",
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            width: !isMobile && direction === "horizontal" ? "50%" : "auto",
            maxWidth: "100%",
            justifyContent: "center",
            display: "flex",
            marginBottom: "4px",
          }}
        >
          <img
            src={imagePath}
            style={{
              maxHeight: direction === "horizontal" ? "365px" : "255px",
              width: direction === "horizontal" ? "auto" : "80%",

              borderRadius: "8px",
              boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.07)",
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>
      )}

      {steps && (
        <Steps
          steps={steps}
          width={!isMobile && direction === "horizontal" ? "50%" : "80%"}
          justifyContent={direction === "horizontal" ? "start" : "center"}
        />
      )}
    </div>
  );
};

export const noImageInstructionPage = (steps: InstructionStep[]) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
      gap: "12px",
      width: "100%",
      padding: "0px 12px",
    }}
  >
    {steps && <Steps steps={steps} justifyContent="start" />}
  </div>
);

export default ({ steps }: { steps: ReactNode[] }) => {
  // Carousel library scrolls backwards with 2 steps and slide animation.
  // Add a fake third step as a workaround.
  const [hasFakeStep, setHasFakeStep] = useState(false);
  const [currentStep, setCurrentStep] = useState<Number | undefined>(0);
  const isMobile = useIsMobile();
  if (steps.length === 2) {
    setHasFakeStep(true);
    steps.push(" ");
  }
  return (
    <Box
      sx={{
        padding: "8px 20px",
        flexShrink: 0,
        borderRadius: "12px",
        border: "1px solid #D4D4D4",
        position: "relative",
        height: "400px", // Fixed height for consistency
      }}
    >
      <Carousel
        height={400}
        animation="slide"
        navButtonsAlwaysVisible
        cycleNavigation={false}
        autoPlay={false}
        onChange={(next) => {
          setCurrentStep(next);
        }}
        PrevIcon={<ChevronLeft color="#6B6E7B" size={"32px"} />}
        NextIcon={<ChevronRight color="#6B6E7B" size={"32px"} />}
        navButtonsProps={{
          style: {
            borderRadius: "100px",
            border: "1px solid #D4D4D4",
            backgroundColor: "#FFF",
            padding: "2px",
          },
        }}
        sx={{
          overflow: "visible",
          "div:first-of-type": { overflow: "hidden" },
          "& .MuiButtonBase-root:hover": {
            border: "1px solid #D4D4D4",
            backgroundColor: "#FFF",
            opacity: 1,
            filter: "none",
          },
          "& .MuiButtonBase-root[aria-label='Next']": {
            right: "-48px", // Move Next button
            display: hasFakeStep && currentStep === 1 ? "none" : "flex",
          },
          "& .MuiButtonBase-root[aria-label='Previous']": {
            left: "-48px", // Move Next button
          },
        }}
        indicators={false} // Disable dots
      >
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: isMobile ? "0" : "16px",
              textAlign: "start",
            }}
          >
            {typeof step === "string" ? (
              <Typography variant="body1">{step}</Typography>
            ) : (
              step
            )}
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};
