import useProtoMethod from "./useProtoMethod";
import {
  NotificationRegistrationRequest,
  NotificationRegistrationResponse,
} from "../protogen/notifications_service_pb";

const useNotificationRegistration = () => {
  return useProtoMethod<
    NotificationRegistrationRequest,
    NotificationRegistrationResponse
  >("NotificationRegistration", NotificationRegistrationResponse);
};

export { useNotificationRegistration };
