import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { FormErrors } from "./types";
import { useUpdateMembersFamily } from "services/member";
import AddressAutocomplete, {
  Location,
} from "components/common/AddressAutocomplete";

interface Props {
  familyRef: string;
  address: string;
  onSaveHandler: () => void;
  onCancelHandler: () => void;
}

const parseAddress = (address: string) => {
  return address === "" ? {} : JSON.parse(address);
};

export default ({
  familyRef,
  address,
  onSaveHandler,
  onCancelHandler,
}: Props) => {
  const [parsed, setParsed] = useState<Location>();
  const [errors] = useState<FormErrors>({});
  const [isLoaded, setIsLoaded] = useState(false);
  const { request } = useUpdateMembersFamily();

  const onSave = async () => {
    await request({
      familyRef: familyRef,
      updatedAddress: JSON.stringify(parsed),
      newMembers: [],
      updatedMembers: [],
    });
    onSaveHandler();
  };

  useEffect(() => {
    setParsed(parseAddress(address));
    setIsLoaded(true);
  }, [address]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: 4,
        padding: "24px",
        paddingTop: "8px",
      }}
    >
      <Typography sx={{ marginBottom: "14px" }} variant="title">
        Address
      </Typography>
      <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
        {isLoaded && (
          <AddressAutocomplete
            label={"Home address"}
            error={errors?.location}
            initialValue={parsed?.formattedAddress || ""}
            setValue={(location) => {
              setParsed(location);
            }}
          />
        )}
      </Box>

      <Box>
        <Button
          fullWidth
          sx={{ marginBottom: "12px", marginTop: "14px" }}
          onClick={onSave}
        >
          Save
        </Button>
        <Button fullWidth variant="outlinedSecondary" onClick={onCancelHandler}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
