import {
  useListActivitySubscriptions,
  useUpdateActivitySubscription,
} from "services/advisor";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Loading from "components/common/Loading";
import { ActivitySubscription } from "protogen/advisors_service_pb";
import { CurrentUserContext } from "components/context/RequireAuth";
import useIsMobile from "components/hooks/useIsMobile";
import Breadcrumbs from "components/common/Breadcrumbs";
import LinkRouter from "components/navigation/LinkRouter";

type SubscriptionItemProps = {
  pushRequired?: boolean;
  showAdvisor: boolean;
  subscription: ActivitySubscription;
};
const SubscriptionItem = ({
  pushRequired,
  showAdvisor,
  subscription,
}: SubscriptionItemProps) => {
  const isMobile = useIsMobile();
  const { request, loading } = useUpdateActivitySubscription((r) => {
    if (r.subscription) {
      setPushChecked(r.subscription.pushEnabled);
    }
  });
  const [pushChecked, setPushChecked] = useState(subscription.pushEnabled);
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    isPush: boolean,
  ) => {
    request({
      entityType: subscription.entityType,
      entityRef: subscription.entityRef,
      updatePush: isPush,
      pushEnabled: isPush ? event.target.checked : pushChecked,
    });
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      sx={{
        borderRadius: "8px",
        border: "1px solid #ECECEC",
        background: "#FFF",
        width: "100%",
        padding: "16px 24px",
        gap: "8px",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="12px"
      >
        <Box flexGrow={1} flex="4">
          <LinkRouter
            to={`/families/${encodeURIComponent(subscription.family?.ref)}`}
          >
            <Box display="flex" flexDirection={"column"} gap="4px">
              <Typography
                variant="bodyHeavy"
                color="#262626"
                sx={{
                  textWrap: "nowrap",
                }}
              >
                {subscription.family?.name}
              </Typography>
              {showAdvisor && (
                <Typography variant="bodySmall" color="#6B6E7B">
                  {subscription.family?.advisorName}
                </Typography>
              )}
            </Box>
          </LinkRouter>
        </Box>
        <Box
          display="flex"
          gap="32px"
          alignItems="center"
          justifyContent="space-between"
          flex="1"
        >
          <FormControlLabel
            control={
              <Switch
                checked={pushChecked}
                disabled={loading || pushRequired}
                onChange={(e) => onChange(e, true)}
                onClick={(e) => e.stopPropagation()}
                size="small"
              />
            }
            label={!isMobile ? "Enabled" : undefined}
            labelPlacement="bottom"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default () => {
  const isMobile = useIsMobile();
  const currentUser = useContext(CurrentUserContext);
  const { request, data, loading } = useListActivitySubscriptions();
  useEffect(() => {
    request();
  }, []);
  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <Loading />
      </Box>
    );
  }
  const myFamilies =
    data?.subscriptions?.filter(
      (s) =>
        s.entityType === "family" && s.family.advisorRef === currentUser.ref,
    ) || [];
  const otherFamilies =
    data?.subscriptions?.filter(
      (s) =>
        s.entityType === "family" && s.family.advisorRef !== currentUser.ref,
    ) || [];
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "64px",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Notification Settings</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="end"
        >
          <Box
            display="flex"
            gap="32px"
            alignItems="center"
            justifyContent="space-between"
            flex="4"
          >
            <Typography variant="bodySmallHeavy" color="#262626">
              Family
            </Typography>
          </Box>
          <Box
            display="flex"
            gap="32px"
            alignItems="center"
            justifyContent="space-between"
            mr={"32px"}
            flex="1"
          >
            <Typography
              variant="bodySmallHeavy"
              color="#262626"
              alignSelf={"end"}
              mr={"54px"}
            >
              Push{!isMobile && <> notifications</>}
            </Typography>
          </Box>
        </Box>
        {loading && <Loading />}
        {myFamilies.map((subscription) => (
          <SubscriptionItem
            pushRequired={true}
            key={subscription.family.ref}
            subscription={subscription}
            showAdvisor={false}
          />
        ))}
        {otherFamilies.length > 0 && (
          <Typography
            variant="bodyHeavy"
            color="#262626"
            sx={{ marginTop: "12px" }}
          >
            Other Families
          </Typography>
        )}
        {otherFamilies.map((subscription) => (
          <SubscriptionItem
            key={subscription.family.ref}
            subscription={subscription}
            showAdvisor={true}
          />
        ))}
      </Box>
    </Box>
  );
};
