import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "components/common/Loading";
import FlexPage from "components/layout/FlexPage";
import { useGetMemberDetails } from "services/member";
import { useListAllMemberTaskInspirations } from "services/tasks";

export default () => {
  const navigate = useNavigate();
  const { taskRef } = useParams();
  const { request: listAllTaskInspirationRequest } =
    useListAllMemberTaskInspirations(() => {});
  const { request: memberDetailsRequest } = useGetMemberDetails(() => {});

  const onLoad = async () => {
    const taskInspiration = await listAllTaskInspirationRequest();
    const memberDetails = await memberDetailsRequest();
    // In the case where we can't find the task inspiration or there is an error, just navidae to the add task page anyways.
    const task = (taskInspiration?.taskInspirations || []).find(
      (t) => t.ref === taskRef,
    );
    const family = memberDetails?.family;
    navigate("/tasks/add", { state: { inspiredTask: task, family } });
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <FlexPage leftAligned sx={{ width: "100%" }}>
      <Loading />
    </FlexPage>
  );
};
