import { Box, Typography } from "@mui/material";
import ReactiveDialog from "../../common/ReactiveDialog";
import { ArrowRight } from "lucide-react";
import { useEffect } from "react";
import { useGenerateICSUrl } from "services/calendar";
import CopyTextField from "../../common/CopyTextField";
import Button from "@mui/material/Button";
import LinkRouter from "components/navigation/LinkRouter";
import Loading from "components/common/Loading";
import { ExportedCalendar } from "./exports";
import InstructionStepper from "./InstructionStepper";

export default ({
  calendarType,
  open,
  onClose,
  existingUrl,
}: {
  calendarType: ExportedCalendar;
  open: boolean;
  onClose: (refresh: boolean) => void;
  existingUrl?: string;
}) => {
  const { request, data } = useGenerateICSUrl();

  const createLink = async (linkType: string) => {
    const resp = await request({ name: linkType });
    if (resp && resp.export) {
      return resp.export.icsUrlPath;
    }
    return null;
  };
  useEffect(() => {
    if (existingUrl) {
      return;
    }
    createLink(calendarType.title);
  }, [existingUrl]);
  const exportLink = data?.export?.icsUrlPath || existingUrl || null;
  let [oneClickUrl, oneClickButton]: [string | null, string | null] = [
    null,
    null,
  ];
  if (exportLink) {
    const result = calendarType.getAddCalendarUrlAndButton(exportLink);
    if (result) {
      [oneClickUrl, oneClickButton] = result;
    }
  }
  return (
    <ReactiveDialog
      open={open}
      onClose={() => onClose(true)}
      title="Export your Faye calendar"
      fullWidth
      maxWidth="md"
    >
      {!exportLink && <Loading />}
      {exportLink && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "space-between",
            gap: "64px",
          }}
        >
          {oneClickUrl && (
            <Box
              display="flex"
              flexDirection="column"
              gap={"8px"}
              width="330px"
            >
              <Typography variant="body">
                {/* TODO: Make this dynamic. */}
                Instantly add your Faye calendar to your {calendarType.title}.
              </Typography>
              <LinkRouter to={oneClickUrl} targetNew={true}>
                <Button endIcon={<ArrowRight />}>{oneClickButton}</Button>
              </LinkRouter>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "95%",
            }}
          >
            {calendarType.steps && (
              <Box display="flex" flexDirection="column" gap="9px">
                {oneClickUrl && (
                  <Typography variant="bodyHeavy">
                    Or follow these steps to add it manually:
                  </Typography>
                )}
                <InstructionStepper
                  steps={calendarType.steps}
                  key={calendarType.name}
                />
              </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography variant="bodyHeavy">Faye calendar URL</Typography>
              <CopyTextField sx={{ flex: 10 }} value={exportLink} />
            </Box>
          </Box>
        </Box>
      )}
    </ReactiveDialog>
  );
};
