import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { Box, Typography, TextField, IconButton, Button } from "@mui/material";
import { BlockHandle, BlockProps, toMaybeNumber } from "./utils";
import BlockContainer from "./BlockContainer";
import BlockGutterMap from "./BlockGutterMap";
import DocumentViewer from "../../editor/DocumentViewer";
import DateDisplay from "../../common/DateDisplay";
import { TaskBlock, TaskBlockStatus } from "protogen/tasks_pb";
import { InsertableSuggestion } from "../../editor/extensions/utils";
import DocumentEditor, {
  Handle as DocumentHandle,
} from "../../editor/DocumentEditor";
import useAutoSave from "../../editor/useAutoSave";
import {
  useUpdateBlockContent,
  useUpdateMemberBlockContent,
  useCreateEmailDraftFromDocumentBlock,
  useUpdateBlockMetadata,
  useUpdateTaskBlockStatusRequest,
  useUpdateMemberTaskBlockStatusRequest,
} from "services/tasks";
import EmailCompose from "../../email/EmailComposeDialog";
import { EmailMessage } from "protogen/conversation_pb";
import { EllipsisVertical } from "lucide-react";
import SimpleDocument from "../SimpleDocument";
import { RichContent } from "components/editor/utils";
import { CurrentUserContext } from "components/context/RequireAuth";
import PopperMenu, { createMenuOption } from "../../common/PopperMenu";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../../common/ConfirmationDialog";
import { stripImgSrc } from "../../../common/utils";

const Header = ({
  block,
  toggleEdit,
  isEditMode,
  isShared,
  onEmail,
  title,
  onTitleChange,
  accountType = "advisor",
  isRichDocument = false,
  onDelete,
}: {
  block: TaskBlock;
  toggleEdit: () => void;
  isEditMode: boolean;
  isShared: boolean;
  onEmail: () => void;
  title?: string;
  onTitleChange?: (title: string) => void;
  accountType?: "advisor" | "member";
  isRichDocument?: boolean;
  onDelete?: () => void;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const currentUserRef = currentUser.ref;
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const anchorRef = useRef<HTMLButtonElement>(null);
  const actionMenu = ({
    edit = false,
    email = false,
    remove = false,
  }: {
    edit?: boolean;
    email?: boolean;
    remove?: boolean;
  }) => {
    const options = [];
    if (edit) {
      options.push(
        createMenuOption(
          "Edit",
          () => {
            toggleEdit();
            handleClose();
          },
          "pencil",
        ),
      );
    }
    if (email) {
      options.push(
        createMenuOption(
          "Send as email",
          () => {
            onEmail();
            handleClose();
          },
          "mail",
        ),
      );
    }
    if (remove) {
      options.push(
        createMenuOption(
          "Delete",
          () => {
            onDelete && onDelete();
            handleClose();
          },
          "trash",
        ),
      );
    }
    return (
      <Box>
        <IconButton ref={anchorRef} onClick={handleToggle}>
          <EllipsisVertical size={20} color="#8E9598" />
        </IconButton>
        <PopperMenu
          open={open}
          anchorRef={anchorRef}
          handleClose={() => {
            setOpen(false);
          }}
        >
          {options}
        </PopperMenu>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      {isRichDocument ? (
        <Box sx={{ width: "100%", paddingBottom: "2px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <Typography variant="bodyHeavy" sx={{ color: "text.secondary" }}>
              Document
            </Typography>
            {accountType === "advisor" &&
              actionMenu({ email: true, remove: true })}
          </Box>
          <Box>
            {accountType === "advisor" ? (
              <TextField
                variant="standard"
                fullWidth
                multiline
                placeholder="Untitled document"
                value={title}
                onChange={(e) => {
                  onTitleChange && onTitleChange(e.target.value);
                }}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  sx: (theme) => ({
                    ...theme.typography.h3,
                  }),
                }}
              />
            ) : (
              <Typography variant="h3" color="#3D3D3D">
                {title}
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography variant="bodyHeavy" color="#3D3D3D">
            {isShared ? `${block?.createdBy?.displayName}` : "Private note"}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {!isRichDocument && !isEditMode && (
          <DateDisplay date={new Date(Number(block?.createdSec) * 1000)} />
        )}
        {!isRichDocument &&
          !isEditMode &&
          currentUserRef === block?.createdBy?.ref &&
          actionMenu({
            edit: true,
            remove: true,
          })}
      </Box>
    </Box>
  );
};

export interface Handle extends BlockHandle {
  insertSuggestion: (s: InsertableSuggestion) => void;
}

export default forwardRef<Handle, BlockProps>(
  ({ block, onUpdate, accountType, onDeleteTaskBlock }: BlockProps, ref) => {
    const isFirstRender = useRef(true);
    const metadata = block.metadata ? JSON.parse(block.metadata) : {};
    const isRichDocument = metadata.isRichDocument;
    const [title, setTitle] = useState(metadata.title || "");
    const [updatedBlock, setUpdatedBlock] = useState<TaskBlock | null>(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isShared, setIsShared] = useState(metadata.isShared);
    const [emailComposeOpen, setEmailComposeOpen] = useState(false);
    const [emailDraft, setEmailDraft] = useState<EmailMessage | undefined>(
      undefined,
    );
    const updateBlockContentRequest =
      accountType === "advisor"
        ? useUpdateBlockContent
        : useUpdateMemberBlockContent;
    const [updating, setUpdating] = useState(false);
    const { request, error } = updateBlockContentRequest((r) => {
      setUpdatedBlock(r.block);
    });
    const confirmState = useConfirmationDialog();
    const { request: updateBlockMetadata } = useUpdateBlockMetadata();
    const updateBlockStatusRequest =
      accountType === "advisor"
        ? useUpdateTaskBlockStatusRequest
        : useUpdateMemberTaskBlockStatusRequest;
    const { request: updateBlockStatus } = updateBlockStatusRequest();
    const [hovering, setHovering] = useState(false);

    const {
      request: createEmailDraftFromDocumentBlock,
      error: createEmailDraftError,
    } = useCreateEmailDraftFromDocumentBlock(async (r) => {
      if (accountType === "member") return;
      const draft = new EmailMessage({
        ...r.email,
        subject: title,
      });
      setEmailDraft(draft);
      setEmailComposeOpen(true);
    });
    const initialContent = updatedBlock?.content || block.content;
    const contentType = block.contentType;
    const content =
      initialContent && contentType === "json"
        ? JSON.parse(initialContent)
        : initialContent;
    const documentEditorRef = useRef<DocumentHandle | null>(null);
    useImperativeHandle(ref, () => ({
      insertSuggestion: (s: InsertableSuggestion) =>
        documentEditorRef.current?.insertSuggestion(s),
    }));

    const toggleEdit = () => {
      setIsEditMode(!isEditMode);
    };

    const deleteBlock = ({ block }: { block: TaskBlock }) => {
      confirmState.openDialog(() => {
        updateBlockStatus({
          taskBlockRef: block.ref,
          status: TaskBlockStatus.DELETED,
        });
        onDeleteTaskBlock && onDeleteTaskBlock(block.ref);
      });
    };

    const onSimpleUpdate = async (
      content: RichContent,
      isShareable: boolean,
    ) => {
      await request({
        taskBlockRef: block.ref,
        content: stripImgSrc(content.html),
        contentType: "html",
        textContent: content.text,
        attachments: content.attachments,
      });

      updateBlockMetadata({
        taskBlockRef: block.ref,
        key: "isShared",
        value: JSON.stringify(isShareable),
      });
      setIsEditMode(false);
      setIsShared(isShareable);
    };

    useEffect(() => {
      if (isFirstRender.current || !title) {
        isFirstRender.current = false;
        return;
      }
      updateBlockMetadata({
        taskBlockRef: block.ref,
        key: "title",
        value: JSON.stringify(title),
      });
    }, [title]);

    const { setEditorContent, editorContent } = useAutoSave(
      block.content || null,
      toMaybeNumber(block?.lastUpdatedSec),
      async (c) => {
        const resp = await request({
          taskBlockRef: block.ref,
          content: stripImgSrc(c.html),
          contentType: "html",
          textContent: c.text,
          attachments: c.attachments,
        });
        if (resp) {
          onUpdate && onUpdate(resp?.block?.lastUpdatedSec || BigInt(0));
          setUpdatedBlock(resp.block);
        }
      },
      !!error,
      1500,
      "html",
    );

    return (
      <BlockContainer
        gutterIcon={
          isRichDocument
            ? BlockGutterMap.document.icon
            : BlockGutterMap.message.icon({ user: block.createdBy })
        }
        advisorViewOnly={!isShared}
        visibilityCTA={
          !isShared &&
          isRichDocument && (
            <Button
              variant="text"
              disabled={updating}
              sx={{
                fontSize: "16px",
                height: "19px",
                lineHeight: "19px",
              }}
              onClick={async () => {
                setUpdating(true);
                if (editorContent) {
                  await request({
                    taskBlockRef: block.ref,
                    content: stripImgSrc(editorContent.html),
                    contentType: "html",
                    textContent: editorContent.text,
                    attachments: editorContent.attachments,
                  });
                }
                const resp = await updateBlockMetadata({
                  taskBlockRef: block.ref,
                  key: "isShared",
                  value: JSON.stringify(!isShared),
                });
                onUpdate &&
                  onUpdate(
                    resp?.block?.lastUpdatedSec || BigInt(0),
                    "Published! Document has been sent to the client.",
                  );
                setIsShared(!isShared);
                setUpdating(false);
              }}
            >
              Publish
            </Button>
          )
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "16px 24px",
            gap: "4px",
            ...(hovering ? { backgroundColor: "#e8f4f8" } : {}),
          }}
        >
          <Header
            block={block}
            toggleEdit={toggleEdit}
            isEditMode={isEditMode}
            isShared={isShared}
            onEmail={() => {
              createEmailDraftFromDocumentBlock({
                taskBlockRef: block.ref,
              });
            }}
            title={title}
            onTitleChange={setTitle}
            accountType={accountType}
            isRichDocument={isRichDocument}
            onDelete={() => {
              deleteBlock({ block });
            }}
          />
          {isRichDocument && accountType === "advisor" ? (
            <DocumentEditor
              ref={documentEditorRef}
              setContent={setEditorContent}
              passiveEditor={false}
              placeholder={"Write something..."}
              initialContent={content}
              attachmentsEnabled={true}
              initialAttachments={block.attachments}
              editorDefaultFontSize={"15px"}
              placement={"top"}
              setDragState={setHovering}
              sx={{ marginTop: "0px", paddingBottom: "20px" }}
              editorMaxHeight={"calc(100vh - 210px)"}
              scrollable={true}
            />
          ) : isEditMode ? (
            <SimpleDocument
              isCreate={false}
              onSaveDocument={async (content, isShareable) => {
                onSimpleUpdate(content, isShareable);
              }}
              initialContent={block.content}
              initialAttachments={block.attachments}
              isShared={isShared}
              isEditMode={isEditMode}
              onCancel={() => {
                setIsEditMode(false);
              }}
            />
          ) : (
            <DocumentViewer
              content={content}
              attachments={updatedBlock?.attachments || block.attachments}
              minHeight="29px"
              sx={{ padding: "0" }}
            />
          )}
        </Box>
        <EmailCompose
          closed={!emailComposeOpen}
          onClose={() => {
            setEmailComposeOpen(false);
          }}
          onSent={() => {}}
          onRemove={() => {}}
          initialDraft={emailDraft}
        />
        {createEmailDraftError && (
          <Typography color="secondary.block">
            {createEmailDraftError}
          </Typography>
        )}

        <ConfirmationDialog
          // Make dynamic for docs
          content={
            isRichDocument
              ? "Are you sure you want to remove this document?"
              : "Are you sure you want to remove this comment?"
          }
          {...confirmState.dialogProps}
        />
      </BlockContainer>
    );
  },
);
