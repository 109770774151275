import { Box, Typography } from "@mui/material";
import { format } from "date-fns";

export default ({ date }: { date: Date }) => {
  const day = format(date, "EEE");

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="bodySmall">{day}</Typography>
    </Box>
  );
};
