import {
  FamilyBilling,
  FamilyStatus,
  PlatformFeeSchedule,
} from "protogen/advisors_service_pb";
import { AccountStub, EntityMetadata } from "protogen/common_pb";
import { BillingCoupon, BillingProduct } from "protogen/billing_pb";

export type UserPermission = {
  ref?: string;
  advisorRef: string;
  active: boolean;
  expiration: bigint | null;
  actions: { [key: string]: boolean };
  reactivate?: boolean;
  deactivate?: boolean;
  // Used for the UI
  account?: AccountStub;
};

export type Family = {
  ref?: string;
  name: string;
  advisorRef: string;
  status: FamilyStatus;
  startDate: string | null;
  clearStartDate?: boolean;
  endDate: string | null;
  clearEndDate?: boolean;
  permissions: UserPermission[];
  shouldUpdateBilling?: boolean;
  product?: BillingProduct;
  introCoupon?: BillingCoupon;
  platformFeeSchedule?: PlatformFeeSchedule;
  address?: string;
  billingInfo?: FamilyBilling;
  stripeCustomerId?: string;
  metadata?: EntityMetadata;
};

export type Member = {
  ref?: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  altEmails: string[];
  timezone: string;
  isEphemeral?: boolean;
};

export type FormErrors = {
  [key: string]: string;
};

export const prettyFamilyStatus = (status: FamilyStatus): string => {
  switch (status) {
    case FamilyStatus.ACTIVE:
      return "Active";
    case FamilyStatus.PROSPECT:
      return "Prospect";
    case FamilyStatus.DEMO:
      return "Demo";
    case FamilyStatus.TEST:
      return "Test";
    case FamilyStatus.DEACTIVATED:
      return "Deactivated";
    case FamilyStatus.PREACTIVATION:
      return "Pre-Activation";
    case FamilyStatus.DEACTIVATED_PROSPECT:
      return "Deactivated Prospect";
    default:
      return "";
  }
};
