import { PlainMessage } from "@bufbuild/protobuf";
import { Member } from "protogen/common_pb";
import { UpdateMembersFamilyRequest_UpdatedMember } from "protogen/member_service_pb";

export type EphemeralMember = {
  ref?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  avatarUrl?: string;
  primaryPhone?: string;
  primaryEmail?: string;
  alternateEmails?: string[];
  timezone?: string;
  address?: string;
  password?: string;
};

export type FormErrors = {
  [key: string]: string;
};

export const updates = (
  member: Member,
  formData: EphemeralMember,
): PlainMessage<UpdateMembersFamilyRequest_UpdatedMember> => {
  return {
    ref: member.ref,
    updatedPhone:
      member.primaryPhone === formData.primaryPhone
        ? ""
        : formData.primaryPhone!,
  };
};
