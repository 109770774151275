import { Box, Button, FormHelperText, Link, Typography } from "@mui/material";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CAL_CLIENT_ID!;
export const REDIRECT_URI = `https://${window.location.host}/settings/calendars/google`;

export default ({}: {}) => {
  const path = "https://www.googleapis.com/auth/calendar.readonly";
  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=email%20${path}&access_type=offline&include_granted_scopes=true&response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&prompt=consent`;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{ height: "100%" }}
    >
      <Typography variant="h4" sx={{ marginBottom: "8px" }}>
        Allow Faye to have read-only access to your calendar events
      </Typography>
      <Typography variant="body">
        We use this to schedule appointments, see availability, and to spot
        opportunities to help. You can select the accounts and calendars you
        want to share with your advisor.
      </Typography>
      <Box display="flex">
        <Button
          fullWidth
          color="primary"
          style={{ marginTop: "25px" }}
          href={authUrl}
        >
          Connect Google Calendar
        </Button>
      </Box>
      <FormHelperText
        sx={{ marginTop: "10px", fontSize: "14px", fontWeight: "400" }}
      >
        Faye's use and transfer to any other app of information received from
        Google APIs will adhere to{" "}
        <Link
          target="_new"
          href={
            "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
          }
        >
          Google API Services User Data Policy
        </Link>
        , including the Limited Use requirements.
      </FormHelperText>
    </Box>
  );
};
