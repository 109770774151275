import { IconButton, Box } from "@mui/material";
import { Filter } from "lucide-react";

export interface CalendarFiltersDelegateProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}
export const CalendarFiltersDelegate = ({
  onClick,
}: CalendarFiltersDelegateProps) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick(event);
  };
  return (
    <Box className="fitlers-button" onClick={handleClick}>
      <IconButton>
        <Filter width="20" color="#6B6E7B" strokeWidth="2" />
      </IconButton>
    </Box>
  );
};
