import React from "react";
import { Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  fullHeight?: boolean;
}

export default (props: TabPanelProps) => {
  const { children, value, index, fullHeight = false, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ height: fullHeight ? "100%" : "auto" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "24px 0", height: fullHeight ? "100%" : "auto" }}>
          {children}
        </Box>
      )}
    </Box>
  );
};
