import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { Box, Button } from "@mui/material";
import {
  SendTextMessageRequest,
  SendTextMessageResponse,
} from "protogen/text_message_service_pb";
import { Family, FeedEntry, Medium } from "protogen/advisors_service_pb";
import { Member } from "protogen/common_pb";
import { useSendTextMessage } from "services/textMessage";

type Props = {
  onShared: (e: FeedEntry) => void;
  family: Family;
  recipient: Member;
};

export default ({ onShared, recipient, family }: Props) => {
  const callback = (resp: SendTextMessageResponse) => {
    const message = resp.textMessage;
    onShared(
      new FeedEntry({
        ref: message.ref,
        senderRef: message.senderRef,
        timestampSec: message.timestampSec,
        medium: Medium.SMS,
        textMessage: message,
        recipientRefs: [recipient.ref],
      }),
    );
  };
  const { loading, data, error, request } = useSendTextMessage(callback);
  const onClick = () => {
    request(
      new SendTextMessageRequest({
        familyRef: family.ref,
        memberRefs: [recipient.ref],
        content: "",
        includeContactCard: true,
        attachments: [],
        scheduledSendSecUTC: BigInt(0),
      }),
    );
  };

  if (data) return null;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
    >
      <Button
        variant="outlined"
        startIcon={<ContactPhoneIcon />}
        disabled={!!(loading || error) || !recipient.primaryPhone}
        sx={{
          maxWidth: "200px",
          textTransform: "none",
        }}
        onClick={onClick}
      >
        Share contact
      </Button>
    </Box>
  );
};
