import React, { useContext, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import useIsMobile from "components/hooks/useIsMobile";
import FlexPage from "components/layout/FlexPage";
import Breadcrumbs from "components/common/Breadcrumbs";
import { Copy } from "lucide-react";
import { CurrentUserContext } from "components/context/RequireAuth";
import { useSubmitReferral } from "services/member";
import { celebrate, getElementCenter } from "../common/celebrations";
import { userReferralLink } from "components/referrals/utils";

// Types
interface ReferralFormData {
  name: string;
  email: string;
}

const ReferralModule = ({}: {}) => {
  const currentUser = useContext(CurrentUserContext);
  const buttonRef = useRef<HTMLButtonElement>(null);
  // Form state
  const [formData, setFormData] = useState<ReferralFormData>({
    name: "",
    email: "",
  });

  const { request, loading } = useSubmitReferral();

  // Feedback states
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);

  // Handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const referralLink = userReferralLink(currentUser.ref);
  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(referralLink);
    setShowCopySuccess(true);
    setTimeout(() => setShowCopySuccess(false), 2000);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const resp = await request(formData);
    if (resp) {
      setShowSubmitSuccess(true);
      setFormData({ name: "", email: "" });
      celebrate(
        buttonRef.current ? getElementCenter(buttonRef.current) : undefined,
      );
    }
  };

  const handleCloseAlert = () => {
    setShowSubmitSuccess(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Typography variant="bodyHeavy">Copy your referral link</Typography>

        <TextField
          fullWidth
          variant="outlined"
          value={referralLink}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ padding: "0 6px 0 0 !important" }}
              >
                <Copy width="20" />
              </InputAdornment>
            ),
          }}
          onClick={handleCopyLink}
          sx={{
            bgcolor: "grey.50",
            ".MuiInputBase-root": {
              cursor: "pointer",
            },
            input: {
              cursor: "pointer",
              marginTop: 0,
            },
          }}
        />
      </Box>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
      >
        <Typography variant="bodyHeavy">
          Or share an invitation directly
        </Typography>
        <TextField
          fullWidth
          name="name"
          label="Friend's name"
          value={formData.name}
          onChange={handleInputChange}
          disabled={loading}
        />
        <TextField
          fullWidth
          name="email"
          label="Friend's email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          disabled={loading}
          required
        />
        <Button
          ref={buttonRef}
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{
            width: "max-content",
            bgcolor: "primary.main",
            "&:hover": {
              bgcolor: "primary.dark",
            },
          }}
        >
          {loading ? "Sending..." : "Send invitation"}
        </Button>
      </Box>

      {/* Feedback Snackbars */}
      <Snackbar
        open={showCopySuccess}
        autoHideDuration={2000}
        onClose={() => setShowCopySuccess(false)}
      >
        <Alert severity="success" onClose={() => setShowCopySuccess(false)}>
          Link copied to clipboard!
        </Alert>
      </Snackbar>

      <Snackbar
        open={showSubmitSuccess}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert severity="success" onClose={handleCloseAlert}>
          Invitation sent successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default () => {
  const isMobile = useIsMobile();

  return (
    <FlexPage leftAligned>
      <Box sx={{ maxWidth: "600px" }}>
        <Breadcrumbs
          breadcrumbs={[{ name: "Home", link: "/" }, { name: "Invite" }]}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            marginBottom: isMobile ? "24px" : "28px",
          }}
        >
          <Typography variant="display">Give $50, Get $50</Typography>
          <Typography variant="body">
            Help your friends find more time by inviting them to Faye. When they
            join, you'll both get $50 off. Invite as many friends as you'd like!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row",
            gap: isMobile ? "32px" : "64px",
            width: "100%",
          }}
        >
          <ReferralModule />
        </Box>
      </Box>
    </FlexPage>
  );
};
