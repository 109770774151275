import { Box } from "@mui/material";
import { EventNotice, EventNotice_NoticeType } from "protogen/calendar_pb";
import { ReactComponent as ExclamationIcon } from "../../icons/Exclamation.svg";
import LinkRouter from "../navigation/LinkRouter";
import { subtitle } from "./utils";
import { Family } from "protogen/advisors_service_pb";
import useIsMobile from "../hooks/useIsMobile";
import { ReactNode } from "react";
import { nowSec } from "../../types/calendars";
import { Theme } from "@mui/material/styles";
import { ReactComponent as EllipseIcon } from "../../icons/Menu/Ellipse.svg";

const eventStyles = (event: EventNotice, theme: Theme) => {
  const now = nowSec();
  const isPast =
    (!event.endSec && now > event.startSec) ||
    (event.endSec && now > event.endSec);
  if (event.noticeType === EventNotice_NoticeType.NoticeType_OVERDUE_TASK) {
    return {
      background: "#FFF5F5",
    };
  } else if (!isPast && event.taskRef) {
    return {
      background: "#FFF",
      border: `1px solid ${theme.palette.border}`,
    };
  } else if (!isPast && event.eventRef) {
    return {
      background: "#F3F7F6",
    };
  } else {
    return {
      border: "1px solid #EAEBEC",
      background: "#FAF9FA",
    };
  }
};

type Props = {
  event: EventNotice;
  onClick?: (eventRef: string) => void;
  family?: Family | null;
  familyPageView?: boolean;
  rangeIndex?: [number, number] | undefined;
  rangeIndexSize?: number | undefined;
  backgroundColor?: string;
};

const ListItemBody = (
  event: EventNotice,
  family?: Family | null,
  familyPageView = false,
  rangeIndex?: [number, number] | undefined,
  rangeIndexSize?: number | undefined,
  backgroundColor?: string,
) => {
  const isExternalEvent =
    event.noticeType === EventNotice_NoticeType.NoticeType_EXTERNAL_EVENT;
  const isMobile = useIsMobile();
  const secondaryWrap = (n: ReactNode) => (
    <Box
      sx={{
        color: "text.secondary",
        fontSize: "14px",
        fontWeight: 500,
      }}
    >
      {n}
    </Box>
  );
  let rightSection: ReactNode = null;
  let underSection = null;
  if (familyPageView) {
    if (isMobile) {
      underSection = secondaryWrap(subtitle(event, false));
    } else {
      rightSection = secondaryWrap(subtitle(event, false));
    }
  } else {
    if (isMobile) {
      underSection = secondaryWrap(
        <>
          {subtitle(event, false)}
          {family ? ` • ${family.name}` : null}
        </>,
      );
    } else {
      underSection = secondaryWrap(subtitle(event, false));
      rightSection = family ? secondaryWrap(family.name) : null;
    }
  }
  const overdueTask =
    event.noticeType === EventNotice_NoticeType.NoticeType_OVERDUE_TASK;
  return (
    <Box
      key={event.eventRef || event.taskRef}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={"8px"}
      sx={(theme) => ({
        flexGrow: "1",
        borderRadius: "12px",
        padding: `${familyPageView && !isMobile ? "20px" : "12px"} ${
          isMobile ? "16px" : "24px"
        }`,
        ...eventStyles(event, theme),
        // Note this will override the background if present.
        ...(backgroundColor && { background: backgroundColor }),
      })}
    >
      <Box display="flex" flexDirection="column" gap="4px" flex={1}>
        <Box
          sx={{
            color: "text.primary",
            fontSize: "16px",
            fontWeight: 700,
            display: "flex",
            gap: "6px",
            alignItems: "center",
          }}
        >
          {event.unreadStatusCount > 0 && (
            <EllipseIcon
              height={8}
              width={8}
              style={{ marginLeft: "6px", marginRight: "6px" }}
            />
          )}
          {rangeIndex &&
            !rangeIndexSize &&
            `${event.title} (Day ${rangeIndex[0]})`}
          {rangeIndex &&
            rangeIndexSize &&
            `${event.title} (Days ${rangeIndex[0]} - ${
              rangeIndex[0] + rangeIndexSize - 1
            })`}
          {!rangeIndex && event.title}
          {!isExternalEvent && (
            <img
              width="16px"
              height="16px"
              src="../../../../assets/images/faye-mini-icon.png"
              background-size="contain"
              background-repeat="no-repeat"
            />
          )}
          {overdueTask && <ExclamationIcon width="16px" height="16px" />}
        </Box>
        {underSection}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{
          marginRight: "3px",
          gap: "6px",
        }}
      >
        {rightSection}
      </Box>
    </Box>
  );
};

export default ({
  event,
  onClick,
  family,
  familyPageView,
  rangeIndex,
  rangeIndexSize,
  backgroundColor,
}: Props) => {
  return (
    <Box>
      {event.taskRef && (
        <LinkRouter to={`/tasks/${encodeURIComponent(event.taskRef || "")}`}>
          {ListItemBody(
            event,
            family,
            familyPageView,
            rangeIndex,
            rangeIndexSize,
            backgroundColor,
          )}
        </LinkRouter>
      )}

      {event.eventRef && (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (onClick) {
              onClick(event.eventRef);
            }
          }}
        >
          {ListItemBody(
            event,
            family,
            familyPageView,
            rangeIndex,
            rangeIndexSize,
            backgroundColor,
          )}
        </Box>
      )}
    </Box>
  );
};
