import { Box, Skeleton } from "@mui/material";

export default ({ minHeight = "30vh" }: { minHeight?: string | null }) => {
  return (
    <Box display="flex" flexDirection="column" width={"100%"}>
      <Box flexGrow={1} padding="1rem" overflow="auto" sx={{ minHeight }}>
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </Box>
    </Box>
  );
};
