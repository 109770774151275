import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { isStandaloneWebapp, urlBase64ToUint8Array } from "../../common/utils";
import { VAPID_PUBLIC_KEY } from "../../constants";
import { useNotificationRegistration } from "services/notifications";
import { SWRegistrationContext } from "../context/AddServiceWorker";
import { CurrentUserContext } from "../context/RequireAuth";
import useIsMobile from "../hooks/useIsMobile";
import { AccountType } from "../../protogen/common_pb";
import { useGlobalModalContext } from "components/context/GlobalModalContext";

export default ({ accountType }: { accountType: AccountType }) => {
  const { request } = useNotificationRegistration();
  const [closed, setClosed] = useState(false);
  const currentUser = useContext(CurrentUserContext);
  const isMobile = useIsMobile();
  const { setModalOpen } = useGlobalModalContext();
  const [themeBase] = useState(
    document.querySelector("meta[name=theme-color]")?.getAttribute("content") ||
      undefined,
  );

  const {
    loaded: regContextLoaded,
    serviceWorker,
    subscription,
  } = useContext(SWRegistrationContext);
  const notificationsEnabled = "Notification" in window;

  useEffect(() => {
    if (
      regContextLoaded &&
      notificationsEnabled &&
      !closed &&
      serviceWorker &&
      !currentUser.isTemporarySession &&
      Notification.permission === "granted"
    ) {
      if (!closed) {
        setModalOpen(false);
        setClosed(true);
      }
      (async () => {
        await getOrCreateAndSaveSubscription();
      })();
    }
  }, [
    regContextLoaded,
    notificationsEnabled,
    closed,
    serviceWorker,
    currentUser.isTemporarySession,
    subscription,
  ]);

  // On desktop, let's just rely on the default permission prompt.
  useEffect(() => {
    const requestPermission = async () => {
      await window.Notification.requestPermission();
    };
    if (
      !isMobile &&
      regContextLoaded &&
      !subscription &&
      notificationsEnabled &&
      !closed &&
      serviceWorker &&
      !currentUser.isTemporarySession
    ) {
      requestPermission();
    }
  });

  const getOrCreateAndSaveSubscription = async () => {
    if (!serviceWorker) return;
    // We likely need to wrap this in a confirmation modal.
    // Ensure pushManager is available
    const registration = await navigator.serviceWorker.ready;
    if (!registration.pushManager) {
      return;
    }

    const _subscription =
      subscription === null
        ? await serviceWorker.pushManager.subscribe({
            applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
            userVisibleOnly: true,
          })
        : subscription;
    if (!_subscription || !_subscription?.endpoint) return;
    await request({
      platform: isStandaloneWebapp() ? "ios-webapp" : "desktop-webapp", // Not strictly true!
      subscription: JSON.stringify(_subscription.toJSON()),
    });
  };

  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setClosed(true);
    setModalOpen(false);
    // Triggers popup to request access to send notifications
    const result = await window.Notification.requestPermission();
    // If the user rejects the permission result will be "denied"
    if (result === "granted") {
      await getOrCreateAndSaveSubscription();
      // You must use the service worker notification to show the notification
      // Using new Notification("Hello World", { body: "My first notification on iOS"}) does not work on iOS
      // despite working on other platforms
      // await swRegistration.showNotification("Granted!", {
      //   body: "We did it boys.",
      // });
    }
    // console.log(result);
  };

  const notReadyForPrompt =
    !regContextLoaded ||
    subscription ||
    !notificationsEnabled ||
    closed ||
    !serviceWorker ||
    currentUser.isTemporarySession ||
    // Don't prompt on desktop.
    !isMobile;

  const revertThemeColor = () => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", themeBase || "");
    }
  };

  useEffect(() => {
    setModalOpen(!closed);
    return () => setModalOpen(false);
  }, [closed, setModalOpen]);

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (!notReadyForPrompt && !closed) {
      if (metaThemeColor) {
        if (accountType == AccountType.ADVISOR) {
          // Note(Kip) Not finalized.
          metaThemeColor.setAttribute("content", "#575F70");
        } else {
          // Note(Kip) Not finalized.
          metaThemeColor.setAttribute("content", "#707c74");
        }
      }
    } else {
      revertThemeColor();
    }
    return () => {
      revertThemeColor();
    };
  }, [open]);

  if (notReadyForPrompt) {
    return null;
  }

  return (
    <Dialog
      open={!closed}
      PaperComponent={Paper}
      PaperProps={{ style: { borderRadius: "12px", padding: "15px 15px" } }}
      disableEscapeKeyDown={true}
      onClose={(_, reason) => {
        if (reason && reason === "backdropClick") return;
        setClosed(true);
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          padding: "8px 12px",
        }}
      >
        <span>Enable notifications</span>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0 12px 20px 12px",
        }}
      >
        <DialogContentText>
          {accountType === AccountType.MEMBER
            ? "Stay updated on task progress by enabling notifications from your Advisor"
            : "Get notified of messages and updates from your clients"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {accountType === AccountType.MEMBER && (
          <Button
            variant="text"
            onClick={(e) => {
              e.preventDefault();
              setClosed(true);
            }}
          >
            Skip
          </Button>
        )}
        {/* We don't give advisors the option to explictily skip */}
        <Button onClick={onClick}>Next</Button>
      </DialogActions>
    </Dialog>
  );
};
