import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Chip, Typography, Button, Alert } from "@mui/material";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useActivateFamily, useGetFamilyDetails } from "services/advisor";

import ActivityFeed from "components/activity/ActivityFeed";
import Loading from "components/common/Loading";
import { Family, FamilyStatus } from "protogen/advisors_service_pb";
import TwoPanelGrid, { Panel } from "components/details-page/TwoPanelGrid";
import TabbedDetailsPanel from "components/details-page/TabbedDetailsPanel";
import { Note } from "protogen/notes_pb";
import useIsMobile from "components/hooks/useIsMobile";
import { Task } from "protogen/tasks_pb";
import { SearchContext } from "components/context/SearchContextProvider";
import FamilyTaskSection from "components/family/FamilyTaskSection";
import FamilySecretsSection from "components/family/FamilySecretsSection";
import { SecretStub } from "protogen/secrets_pb";
import { FactSectionStub, FactStub } from "protogen/facts_pb";
import FamilyNotesSection from "components/family/FamilyNotesSection";
import Breadcrumbs from "components/common/Breadcrumbs";
import SuggestionActions, {
  SuggestionActionsHandle,
} from "components/activity/SuggestionActions";
import { ContactRound, MessageSquareText } from "lucide-react";
import { useNavigate } from "react-router";
import { Advisor, UserPermission } from "protogen/common_pb";
import { PlanUtilization } from "protogen/common_pb";
import TimeUtilization from "components/time-utilization/TimeUtilization";
import NotFound from "components/NotFound";
import useTitle from "components/hooks/useTitle";
import FamilyCalendarSection from "components/family/FamilyCalendarSection";
import FamilySettingsSection from "../components/family/FamilySettingsSection";
import { DatePicker } from "@mui/x-date-pickers";
import ReactiveDialog from "../components/common/ReactiveDialog";

interface LeftFamilyPanelProps {
  family: Family;
  familyNotes: Note;
  tasks: Task[];
  secrets: SecretStub[];
  facts: FactStub[];
  factSections: FactSectionStub[];
  setShowMessages: (s: boolean) => void;
  refresh: () => void;
  primaryAdvisor: Advisor;
  advisorPermissions: UserPermission[];
  planUtilization: PlanUtilization;
}
const LeftFamilyPanel = ({
  family,
  familyNotes,
  tasks,
  refresh,
  secrets,
  facts,
  factSections,
  primaryAdvisor,
  advisorPermissions,
  planUtilization,
}: LeftFamilyPanelProps) => {
  const [searchParams] = useSearchParams();
  let panels = [
    // <Section key={"Suggestions"} title={"Suggestions"}>
    //   <CardsContainer />
    // </Section>,
    <FamilyNotesSection
      key={"Notes"}
      title={"Family notes"}
      note={familyNotes}
      facts={facts}
      sections={factSections}
      familyRef={family.ref}
      family={family}
      refresh={refresh}
      advisorPermissions={advisorPermissions}
      primaryAdvisor={primaryAdvisor}
    />,
    <FamilyCalendarSection
      title={"Calendar"}
      key={"Calendar"}
      family={family}
      fullHeight
    />,
    <FamilyTaskSection
      title={"Tasks"}
      key={"Open Tasks"}
      family={family}
      initialTasks={tasks}
      planUtilization={planUtilization}
    />,
    <FamilySecretsSection
      title={"Secrets"}
      secrets={secrets}
      family={family}
      key="secrets"
    />,
    <FamilySettingsSection
      title={"Settings"}
      key={"settings"}
      family={family}
      primaryAdvisor={primaryAdvisor}
      advisorPermissions={advisorPermissions}
    />,
  ];
  let defaultTab = undefined;
  if (searchParams.has("factRef")) {
    defaultTab = panels.findIndex((p) => p.type === FamilyNotesSection);
  } else if (searchParams.has("secretRef")) {
    defaultTab = panels.findIndex((p) => p.type === FamilySecretsSection);
  }
  return (
    <TabbedDetailsPanel
      panels={panels}
      defaultTab={defaultTab}
      sx={{ overflowY: "hidden", paddingRight: "20px", gap: "0px" }}
    />
  );
};

const getFamilyBadge = (family: Family) => {
  const values = new Map<FamilyStatus, [string, string, string]>([
    [FamilyStatus.ACTIVE, ["Active", "#ECF4EC", "#198282"]],
    [
      FamilyStatus.PROSPECT,
      ["Prospect", "rgba(251, 242, 229, 0.95)", "#9A6518"],
    ],
    [FamilyStatus.DEACTIVATED, ["Inactive", "#F5F5F5", "#6B6E7B"]],
    [FamilyStatus.TEST, ["Demo", "#FFF", "#6B6E7B"]],
    [FamilyStatus.DEMO, ["Demo", "#FFF", "#6B6E7B"]],
  ]);
  if (!values.has(family.status)) {
    // Includes NOT_SET and ACTIVE
    return null;
  }
  const [copy, bgColor, textColor] = values.get(family.status)!;
  return (
    <Chip
      label={copy}
      sx={{
        height: "35px",
        fontSize: "16px",
        fontWeight: "600",
        borderRadius: "100px",
        padding: "8px 12px",
        background: bgColor,
        span: { color: textColor },
        ...(bgColor === "#FFF" && { border: "1px solid #D4D4D4" }),
      }}
    />
  );
};

const getInvitationButton = (family: Family, onClick: () => void) => {
  return (
    <Button onClick={onClick} variant="outlined" size="small">
      Invite to join
    </Button>
  );
};

const _TaskTimeUtilization = ({
  planUtilization,
}: {
  planUtilization?: PlanUtilization;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = () => {
    const pathname = location.pathname;
    // Parse the current search params
    const searchParams = new URLSearchParams(location.search);
    // Update the parameter value
    searchParams.set("currentMonth", "1");

    // Construct the new URL
    const newUrl = `${pathname}?${searchParams.toString()}#tab-4`;

    // Navigate to the new URL
    navigate(newUrl);
  };
  return (
    <TimeUtilization planUtilization={planUtilization} onClick={onClick} />
  );
};

const ActivateAction = ({
  family,
  mobile,
}: {
  family: Family;
  mobile?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [date, setDate] = useState<Date | null>(
    family.startDate ? new Date(family.startDate) : null,
  );
  const { request, loading } = useActivateFamily();
  const today = new Date();

  const onActivation = async () => {
    if (!date) return;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const startDate = `${year}-${month}-${day}`;
    const resp = await request({ familyRef: family.ref, startDate });
    if (resp) {
      setOpen(false);
      // refresh the page. Hamfisted, but this is an infrequent operation.
      window.location.reload();
    }
    // Handle activation logic here
    console.log("Activated with date:", date);
  };
  const billingFields =
    family.billingInfo?.product &&
    family.platformFeeSchedule &&
    family.billingInfo?.stripeCustomerId;
  const enabled = !!date && !loading && billingFields;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "end",
        gap: "16px",
        ...(mobile ? { justifyContent: "center", padding: "12px" } : {}),
      }}
    >
      <Button onClick={() => setOpen(true)} size="small">
        Start service
      </Button>
      <ReactiveDialog
        open={open}
        onClose={() => setOpen(false)}
        title={"Start service"}
        primaryActionName={"Activate"}
        primaryActionEnabled={enabled}
        primaryAction={onActivation}
        fullWidthSize={"sm"}
      >
        <Box display="flex" flexDirection="column" gap="12px">
          {!billingFields && (
            <Alert severity="warning">
              To activate a client, a product, platform fee schedule, and Stripe
              Customer ID must all be present.
            </Alert>
          )}
          <Typography variant="bodyHeavy">
            This will be your client's billing start date. If needed, please
            adjust to the day in which you actually started work (solving tasks,
            holding the onboarding call, etc).
          </Typography>
          <DatePicker
            sx={{
              width: "100%",
              ".MuiButtonBase-root": { marginRight: "unset" },
            }}
            label="Start date"
            defaultValue={date}
            disabled={loading}
            onChange={(d) => setDate(d)}
            format="MM/dd/yyyy"
            open={dateOpen}
            maxDate={today}
            // today - three weeks
            minDate={new Date(today.getTime() - 1000 * 60 * 60 * 24 * 21)}
            onClose={() => setDateOpen(false)}
            slotProps={{
              textField: {
                onClick: () => setDateOpen(true),
              },
              inputAdornment: {
                onClick: () => setDateOpen(true),
              },
            }}
          />
        </Box>
      </ReactiveDialog>
    </Box>
  );
};
const getPanelHeaderActions = (
  family: Family,
  onClick: () => void,
  planUtilization?: PlanUtilization,
  mobile?: boolean,
) => {
  const includeInviteButton =
    family.status === FamilyStatus.PROSPECT ||
    family.status === FamilyStatus.PREACTIVATION;
  let module;
  if (family.status === FamilyStatus.ACTIVE) {
    module = (
      <Box>
        <_TaskTimeUtilization planUtilization={planUtilization} />
      </Box>
    );
  } else if (family.status === FamilyStatus.PREACTIVATION) {
    module = <ActivateAction family={family} mobile={mobile} />;
  } else {
    module = (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "end",
          gap: "16px",
          ...(mobile ? { justifyContent: "center", padding: "12px" } : {}),
        }}
      >
        {includeInviteButton && getInvitationButton(family, onClick)}
        {!mobile ? getFamilyBadge(family) : null}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "end",
        justifyContent: "end",
        gap: "16px",
      }}
    >
      {module}
    </Box>
  );
};

export default () => {
  const { setFamily } = useContext(SearchContext);
  const navigate = useNavigate();
  const { hash } = useLocation();
  const isMobile = useIsMobile();
  const [showMessages, setShowMessages] = useState(true);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  let params = useParams();
  const actionsRef = useRef<SuggestionActionsHandle | null>(null);
  const { data, request, errorCode } = useGetFamilyDetails((r) => {
    if (r.family) {
      setFamily(r.family);
      setHasLoaded(true);
    }
  });
  useTitle(
    data?.family?.name ? `Families | ${data?.family?.name}` : "Families",
  );

  useEffect(() => {
    // Clear it so we don't show the wrong family while loading.
    setHasLoaded(false);
    request({ familyRef: params.familyRef! });
  }, [params.familyRef]);
  if (errorCode === 404) return <NotFound title={"Family not found"} />;
  if (!hasLoaded || !data) {
    return <Loading />;
  }
  const { family, familyNotes, activeTasks, secrets, facts, planUtilization } =
    data;
  // Not sure why these are nullable. TS+protobuf problem for another day.
  if (!family) {
    throw Error("Family type expected.");
  }
  const refresh = () => {
    request({ familyRef: params.familyRef! });
  };

  const onInviteClick = () => {
    navigate(`/families-invitation/${encodeURIComponent(family.ref)}`);
  };
  return (
    <>
      <TwoPanelGrid
        key={family.ref}
        defaultPanel={hash.match(/#tab-\d+/) ? Panel.Left : Panel.Right}
        headerAction={getPanelHeaderActions(
          family,
          onInviteClick,
          planUtilization,
        )}
        onChangePanel={(panel) => {
          panel === Panel.Right
            ? navigate("#", { replace: true })
            : navigate("#tab-0", { replace: true });
        }}
        leftPanel={{
          title: <Breadcrumbs breadcrumbs={[{ name: "Family details" }]} />,
          subtitle: (
            <Box sx={{}}>
              <Typography variant="display">{family.name}</Typography>
            </Box>
          ),
          switchContent: (
            <ContactRound size={20} strokeWidth="2px" stroke="#6B6E7B" />
          ),
          content: (
            <>
              {isMobile
                ? getPanelHeaderActions(family, onInviteClick, undefined, true)
                : null}
              <LeftFamilyPanel
                family={family}
                tasks={activeTasks}
                familyNotes={familyNotes}
                secrets={secrets}
                setShowMessages={setShowMessages}
                refresh={refresh}
                facts={facts}
                factSections={data.factSections}
                primaryAdvisor={data.primaryAdvisor}
                advisorPermissions={data.advisorPermissions}
                planUtilization={planUtilization}
              />
            </>
          ),
        }}
        rightPanel={{
          sx: { backgroundColor: "#FDFAF7" },
          title: null,
          subtitle: isMobile ? (
            <Box sx={{}}>
              <Typography variant="h2Serif" sx={{ fontWeight: 700 }}>
                {family.name}
              </Typography>
            </Box>
          ) : null,
          switchContent: (
            <MessageSquareText size={20} strokeWidth="2px" stroke="#6B6E7B" />
          ),
          content: family.familyMembers.length ? (
            <ActivityFeed
              family={data.family!}
              showMessages={showMessages}
              suggestionActions={{
                suggestTask: (t) => {
                  actionsRef.current?.suggestTask(t);
                },
                suggestFact: (f) => {
                  actionsRef.current?.suggestFact(f);
                },
                suggestEvent: (e) => {
                  actionsRef.current?.suggestEvent(e);
                },
              }}
              primaryAdvisor={data.primaryAdvisor}
              advisorPermissions={data.advisorPermissions}
            />
          ) : null,
        }}
      />
      <SuggestionActions
        ref={actionsRef}
        familyRef={family.ref}
        onFactSuggested={refresh}
        onEventSuggested={refresh}
        // These get redirected.
        // onTaskSuggested={refresh}
      />
    </>
  );
};
