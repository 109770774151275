import * as React from "react";
import AuthService from "services/auth";
import { Navigate, useLocation } from "react-router-dom";

import { createContext } from "react";
import { CurrentUser } from "protogen/auth_pb";
export const CurrentUserContext = createContext<CurrentUser>(new CurrentUser());
const PROD_ADVISOR_DOMAIN = "https://advisor.findfaye.com";
const PROD_MEMBER_DOMAIN = "https://app.findfaye.com";

const RequireAuth = ({
  children,
  loginRedirectPath,
}: {
  children: React.ReactNode;
  loginRedirectPath: string;
}) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("user_type"))
    ?.split("=")[1];
  const location = useLocation();
  const currentUser = AuthService.getCurrentUser();
  if (!currentUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // Note that we will also add a redirect path to the url. This way if the user changes browsers
    // (common on mobile) they will still be redirected to the correct page.
    const hasDestination =
      !(
        location.pathname === "" ||
        location.pathname === "/" ||
        location.pathname === "/home"
      ) || location.search !== "";

    // We want to share calendar urls between advisor and member applications and subdomains.
    // If we can detect a user type based on cookie and they are using the wrong domain,
    // we re-direct them to the correct domain.
    const isLocalHost = window.location.hostname === "localhost";

    const getRedirectDomain = (cookieValue: string, destination: string) => {
      if (
        cookieValue === "advisor" &&
        window.location.hostname !== PROD_ADVISOR_DOMAIN &&
        destination.includes("calendar")
      ) {
        return PROD_ADVISOR_DOMAIN;
      } else if (
        cookieValue === "member" &&
        window.location.hostname !== PROD_MEMBER_DOMAIN &&
        destination.includes("calendar")
      ) {
        return PROD_MEMBER_DOMAIN;
      }
      return "";
    };

    if (hasDestination && cookieValue && !isLocalHost) {
      const destination = location.pathname + location.search;
      const redirectDomain = getRedirectDomain(cookieValue, destination);

      if (redirectDomain) {
        window.location.href = `${redirectDomain}${destination}`;
        return null;
      }
    }
    const redirectTo = hasDestination
      ? `${loginRedirectPath}?destination=${encodeURIComponent(
          location.pathname + location.search,
        )}`
      : loginRedirectPath;
    return (
      <Navigate
        to={redirectTo}
        state={{ from: location, ...location.state }}
        replace
      />
    );
  }
  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default RequireAuth;
