// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/member_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Family } from "./advisors_service_pb.ts";
import { Task } from "./tasks_pb.ts";
import { SecretStub } from "./secrets_pb.ts";
import { Member, PlanUtilization, PublicAdvisor } from "./common_pb.ts";

/**
 * @generated from enum protogen.NotificationSettingsValue
 */
export enum NotificationSettingsValue {
  /**
   * @generated from enum value: NOTIFICATION_SETTINGS_VALUE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: NOTIFICATION_SETTINGS_VALUE_ENABLED = 1;
   */
  ENABLED = 1,

  /**
   * @generated from enum value: NOTIFICATION_SETTINGS_VALUE_DISABLED = 2;
   */
  DISABLED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(NotificationSettingsValue)
proto3.util.setEnumType(NotificationSettingsValue, "protogen.NotificationSettingsValue", [
  { no: 0, name: "NOTIFICATION_SETTINGS_VALUE_UNSPECIFIED" },
  { no: 1, name: "NOTIFICATION_SETTINGS_VALUE_ENABLED" },
  { no: 2, name: "NOTIFICATION_SETTINGS_VALUE_DISABLED" },
]);

/**
 * @generated from message protogen.GetMemberDetailsRequest
 */
export class GetMemberDetailsRequest extends Message<GetMemberDetailsRequest> {
  constructor(data?: PartialMessage<GetMemberDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetMemberDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMemberDetailsRequest {
    return new GetMemberDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMemberDetailsRequest {
    return new GetMemberDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMemberDetailsRequest {
    return new GetMemberDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMemberDetailsRequest | PlainMessage<GetMemberDetailsRequest> | undefined, b: GetMemberDetailsRequest | PlainMessage<GetMemberDetailsRequest> | undefined): boolean {
    return proto3.util.equals(GetMemberDetailsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetMemberDetailsResponse
 */
export class GetMemberDetailsResponse extends Message<GetMemberDetailsResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  /**
   * @generated from field: repeated protogen.Task activeTasks = 4;
   */
  activeTasks: Task[] = [];

  /**
   * @generated from field: repeated protogen.SecretStub secrets = 5;
   */
  secrets: SecretStub[] = [];

  /**
   * @generated from field: protogen.PublicAdvisor primaryAdvisor = 6;
   */
  primaryAdvisor?: PublicAdvisor;

  /**
   * @generated from field: protogen.Member member = 7;
   */
  member?: Member;

  /**
   * @generated from field: protogen.PlanUtilization planUtilization = 8;
   */
  planUtilization?: PlanUtilization;

  /**
   * @generated from field: protogen.NotificationSettings notificationSettings = 9;
   */
  notificationSettings?: NotificationSettings;

  constructor(data?: PartialMessage<GetMemberDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetMemberDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
    { no: 4, name: "activeTasks", kind: "message", T: Task, repeated: true },
    { no: 5, name: "secrets", kind: "message", T: SecretStub, repeated: true },
    { no: 6, name: "primaryAdvisor", kind: "message", T: PublicAdvisor },
    { no: 7, name: "member", kind: "message", T: Member },
    { no: 8, name: "planUtilization", kind: "message", T: PlanUtilization },
    { no: 9, name: "notificationSettings", kind: "message", T: NotificationSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMemberDetailsResponse {
    return new GetMemberDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMemberDetailsResponse {
    return new GetMemberDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMemberDetailsResponse {
    return new GetMemberDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMemberDetailsResponse | PlainMessage<GetMemberDetailsResponse> | undefined, b: GetMemberDetailsResponse | PlainMessage<GetMemberDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetMemberDetailsResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateMembersFamilyRequest
 */
export class UpdateMembersFamilyRequest extends Message<UpdateMembersFamilyRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string updatedAddress = 2;
   */
  updatedAddress = "";

  /**
   * @generated from field: repeated protogen.Member newMembers = 3;
   */
  newMembers: Member[] = [];

  /**
   * @generated from field: repeated protogen.UpdateMembersFamilyRequest.UpdatedMember updatedMembers = 4;
   */
  updatedMembers: UpdateMembersFamilyRequest_UpdatedMember[] = [];

  constructor(data?: PartialMessage<UpdateMembersFamilyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateMembersFamilyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "newMembers", kind: "message", T: Member, repeated: true },
    { no: 4, name: "updatedMembers", kind: "message", T: UpdateMembersFamilyRequest_UpdatedMember, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMembersFamilyRequest {
    return new UpdateMembersFamilyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMembersFamilyRequest {
    return new UpdateMembersFamilyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMembersFamilyRequest {
    return new UpdateMembersFamilyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMembersFamilyRequest | PlainMessage<UpdateMembersFamilyRequest> | undefined, b: UpdateMembersFamilyRequest | PlainMessage<UpdateMembersFamilyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateMembersFamilyRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateMembersFamilyRequest.UpdatedMember
 */
export class UpdateMembersFamilyRequest_UpdatedMember extends Message<UpdateMembersFamilyRequest_UpdatedMember> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string updatedPhone = 2;
   */
  updatedPhone = "";

  constructor(data?: PartialMessage<UpdateMembersFamilyRequest_UpdatedMember>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateMembersFamilyRequest.UpdatedMember";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMembersFamilyRequest_UpdatedMember {
    return new UpdateMembersFamilyRequest_UpdatedMember().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMembersFamilyRequest_UpdatedMember {
    return new UpdateMembersFamilyRequest_UpdatedMember().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMembersFamilyRequest_UpdatedMember {
    return new UpdateMembersFamilyRequest_UpdatedMember().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMembersFamilyRequest_UpdatedMember | PlainMessage<UpdateMembersFamilyRequest_UpdatedMember> | undefined, b: UpdateMembersFamilyRequest_UpdatedMember | PlainMessage<UpdateMembersFamilyRequest_UpdatedMember> | undefined): boolean {
    return proto3.util.equals(UpdateMembersFamilyRequest_UpdatedMember, a, b);
  }
}

/**
 * @generated from message protogen.UpdateMembersFamilyResponse
 */
export class UpdateMembersFamilyResponse extends Message<UpdateMembersFamilyResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  constructor(data?: PartialMessage<UpdateMembersFamilyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateMembersFamilyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMembersFamilyResponse {
    return new UpdateMembersFamilyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMembersFamilyResponse {
    return new UpdateMembersFamilyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMembersFamilyResponse {
    return new UpdateMembersFamilyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMembersFamilyResponse | PlainMessage<UpdateMembersFamilyResponse> | undefined, b: UpdateMembersFamilyResponse | PlainMessage<UpdateMembersFamilyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateMembersFamilyResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetBillingPortalUrlRequest
 */
export class GetBillingPortalUrlRequest extends Message<GetBillingPortalUrlRequest> {
  constructor(data?: PartialMessage<GetBillingPortalUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetBillingPortalUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBillingPortalUrlRequest {
    return new GetBillingPortalUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBillingPortalUrlRequest {
    return new GetBillingPortalUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBillingPortalUrlRequest {
    return new GetBillingPortalUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetBillingPortalUrlRequest | PlainMessage<GetBillingPortalUrlRequest> | undefined, b: GetBillingPortalUrlRequest | PlainMessage<GetBillingPortalUrlRequest> | undefined): boolean {
    return proto3.util.equals(GetBillingPortalUrlRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetBillingPortalUrlResponse
 */
export class GetBillingPortalUrlResponse extends Message<GetBillingPortalUrlResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<GetBillingPortalUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetBillingPortalUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBillingPortalUrlResponse {
    return new GetBillingPortalUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBillingPortalUrlResponse {
    return new GetBillingPortalUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBillingPortalUrlResponse {
    return new GetBillingPortalUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetBillingPortalUrlResponse | PlainMessage<GetBillingPortalUrlResponse> | undefined, b: GetBillingPortalUrlResponse | PlainMessage<GetBillingPortalUrlResponse> | undefined): boolean {
    return proto3.util.equals(GetBillingPortalUrlResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetAdvisorProfileRequest
 */
export class GetAdvisorProfileRequest extends Message<GetAdvisorProfileRequest> {
  /**
   * @generated from field: string advisorRef = 1;
   */
  advisorRef = "";

  constructor(data?: PartialMessage<GetAdvisorProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetAdvisorProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAdvisorProfileRequest {
    return new GetAdvisorProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAdvisorProfileRequest {
    return new GetAdvisorProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAdvisorProfileRequest {
    return new GetAdvisorProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAdvisorProfileRequest | PlainMessage<GetAdvisorProfileRequest> | undefined, b: GetAdvisorProfileRequest | PlainMessage<GetAdvisorProfileRequest> | undefined): boolean {
    return proto3.util.equals(GetAdvisorProfileRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetAdvisorProfileResponse
 */
export class GetAdvisorProfileResponse extends Message<GetAdvisorProfileResponse> {
  /**
   * @generated from field: protogen.PublicAdvisor profile = 1;
   */
  profile?: PublicAdvisor;

  constructor(data?: PartialMessage<GetAdvisorProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetAdvisorProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile", kind: "message", T: PublicAdvisor },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAdvisorProfileResponse {
    return new GetAdvisorProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAdvisorProfileResponse {
    return new GetAdvisorProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAdvisorProfileResponse {
    return new GetAdvisorProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAdvisorProfileResponse | PlainMessage<GetAdvisorProfileResponse> | undefined, b: GetAdvisorProfileResponse | PlainMessage<GetAdvisorProfileResponse> | undefined): boolean {
    return proto3.util.equals(GetAdvisorProfileResponse, a, b);
  }
}

/**
 * @generated from message protogen.SubmitReferralRequest
 */
export class SubmitReferralRequest extends Message<SubmitReferralRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<SubmitReferralRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SubmitReferralRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitReferralRequest {
    return new SubmitReferralRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitReferralRequest {
    return new SubmitReferralRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitReferralRequest {
    return new SubmitReferralRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitReferralRequest | PlainMessage<SubmitReferralRequest> | undefined, b: SubmitReferralRequest | PlainMessage<SubmitReferralRequest> | undefined): boolean {
    return proto3.util.equals(SubmitReferralRequest, a, b);
  }
}

/**
 * @generated from message protogen.SubmitReferralResponse
 */
export class SubmitReferralResponse extends Message<SubmitReferralResponse> {
  constructor(data?: PartialMessage<SubmitReferralResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SubmitReferralResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitReferralResponse {
    return new SubmitReferralResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitReferralResponse {
    return new SubmitReferralResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitReferralResponse {
    return new SubmitReferralResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitReferralResponse | PlainMessage<SubmitReferralResponse> | undefined, b: SubmitReferralResponse | PlainMessage<SubmitReferralResponse> | undefined): boolean {
    return proto3.util.equals(SubmitReferralResponse, a, b);
  }
}

/**
 * @generated from message protogen.NotificationSettings
 */
export class NotificationSettings extends Message<NotificationSettings> {
  /**
   * @generated from field: protogen.NotificationSettingsValue email = 2;
   */
  email = NotificationSettingsValue.UNSPECIFIED;

  /**
   * @generated from field: protogen.NotificationSettingsValue push = 3;
   */
  push = NotificationSettingsValue.UNSPECIFIED;

  constructor(data?: PartialMessage<NotificationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.NotificationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "email", kind: "enum", T: proto3.getEnumType(NotificationSettingsValue) },
    { no: 3, name: "push", kind: "enum", T: proto3.getEnumType(NotificationSettingsValue) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationSettings {
    return new NotificationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationSettings {
    return new NotificationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationSettings {
    return new NotificationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationSettings | PlainMessage<NotificationSettings> | undefined, b: NotificationSettings | PlainMessage<NotificationSettings> | undefined): boolean {
    return proto3.util.equals(NotificationSettings, a, b);
  }
}

/**
 * @generated from message protogen.UpdateMemberNotificationsRequest
 */
export class UpdateMemberNotificationsRequest extends Message<UpdateMemberNotificationsRequest> {
  /**
   * @generated from field: protogen.NotificationSettings settings = 1;
   */
  settings?: NotificationSettings;

  constructor(data?: PartialMessage<UpdateMemberNotificationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateMemberNotificationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settings", kind: "message", T: NotificationSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMemberNotificationsRequest {
    return new UpdateMemberNotificationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMemberNotificationsRequest {
    return new UpdateMemberNotificationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMemberNotificationsRequest {
    return new UpdateMemberNotificationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMemberNotificationsRequest | PlainMessage<UpdateMemberNotificationsRequest> | undefined, b: UpdateMemberNotificationsRequest | PlainMessage<UpdateMemberNotificationsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateMemberNotificationsRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateMemberNotificationsResponse
 */
export class UpdateMemberNotificationsResponse extends Message<UpdateMemberNotificationsResponse> {
  /**
   * @generated from field: protogen.NotificationSettings settings = 1;
   */
  settings?: NotificationSettings;

  constructor(data?: PartialMessage<UpdateMemberNotificationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateMemberNotificationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settings", kind: "message", T: NotificationSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMemberNotificationsResponse {
    return new UpdateMemberNotificationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMemberNotificationsResponse {
    return new UpdateMemberNotificationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMemberNotificationsResponse {
    return new UpdateMemberNotificationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMemberNotificationsResponse | PlainMessage<UpdateMemberNotificationsResponse> | undefined, b: UpdateMemberNotificationsResponse | PlainMessage<UpdateMemberNotificationsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateMemberNotificationsResponse, a, b);
  }
}

