import { useState, forwardRef, ForwardedRef, useImperativeHandle } from "react";
import { Box } from "@mui/material";
import {
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, PaymentMethod } from "@stripe/stripe-js";
import { Family } from "protogen/advisors_service_pb";
import { Member } from "protogen/common_pb";
import CreditCardForm from "./CreditCardForm";
import { Location } from "../common/AddressAutocomplete";

interface StripeFormProps {
  metadata: Record<string, string>;
  onSuccess: (paymentMethod: PaymentMethod) => Promise<void>;
  family: Family;
  member: Member;
  ref: ForwardedRef<{ submitForm: () => void }>;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const StripeForm = forwardRef((props: StripeFormProps, ref) => {
  const { metadata, onSuccess, family, member } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    let address: Location;
    try {
      address = JSON.parse(family.address);
    } catch (e) {
      setError("Error getting family address");
      return;
    }
    const billingDetails = {
      name: `${member.firstName} ${member.lastName}`,
      email: member.primaryEmail || "",
      address: {
        line1: address.address,
        city: address.city,
        state: address.state,
        postal_code: address.zip,
      },
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)!,
      billing_details: billingDetails,
      metadata: metadata,
    });

    if (error) {
      setError(error.message || "An unknown error occurred");
      throw error;
    } else {
      await onSuccess(paymentMethod!);
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit,
  }));

  return (
    <div onSubmit={handleSubmit}>
      <CreditCardForm></CreditCardForm>
      {error && <div>{error}</div>}
    </div>
  );
});

const MemberPayment = forwardRef(
  (
    props: {
      family: Family;
      member: Member;
      onStripeSuccess: (pm: PaymentMethod) => void;
    },
    ref: ForwardedRef<{ submitForm: () => void }>,
  ) => {
    const { family, member, onStripeSuccess } = props;

    const handleStripeSuccess = async (pm: PaymentMethod) => {
      await onStripeSuccess(pm);
    };

    return (
      <Box>
        <Elements stripe={stripePromise}>
          <StripeForm
            ref={ref}
            metadata={{ key: "value" }}
            onSuccess={handleStripeSuccess}
            family={family}
            member={member}
          />
        </Elements>
      </Box>
    );
  },
);

export default MemberPayment;
