import { Box, styled } from "@mui/material";
import { Loader2 } from "lucide-react";

const Spinner = styled(Box)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 1s linear infinite;
  display: flex;
`;

export default () => {
  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 10,
      }}
    >
      <Box className="spinner">
        <Spinner>
          <Loader2
            style={{
              animation: "spin 1s linear infinite",
            }}
            size={48}
            color="#8E9598"
          />
        </Spinner>
      </Box>
    </Box>
  );
};
