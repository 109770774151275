import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Typography,
  MenuItem,
  PopperPlacementType,
  IconButton,
  Menu,
} from "@mui/material";
import React, { ReactElement, RefObject, useState } from "react";
import {
  ChevronLeft,
  FilterX,
  Mail,
  Pencil,
  Pin,
  PinOff,
  Send,
  Trash2,
} from "lucide-react";

export default ({
  open,
  anchorRef,
  handleClose,
  children,
  placement,
}: {
  open: boolean;
  anchorRef: RefObject<HTMLButtonElement>;
  handleClose: (event: Event) => void;
  children: ReactElement | ReactElement[];
  placement?: PopperPlacementType;
}) => {
  return (
    <Popper
      sx={{ zIndex: 1 }}
      open={open}
      anchorEl={anchorRef.current}
      placement={placement ? placement : "bottom-start"}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ]}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper
            sx={{
              display: "flex",
              padding: "8px",
              alignItems: "center",
              gap: "8px",
              borderRadius: "12px",
              border: "2px solid #EAEBEC",
              background: "#FFF",
              boxShadow: "0px 2px 8px 0px rgba(151, 93, 51, 0.07)",
              minWidth: "160px",
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>{children}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const getOptionIcon = (iconName: string) => {
  switch (iconName) {
    case "pencil":
      return <Pencil size={20} color="#8E9598" />;
    case "trash":
      return <Trash2 size={20} color="#8E9598" />;
    case "pin":
      return <Pin size={20} color="#8E9598" />;
    case "pinOff":
      return <PinOff size={20} color="#8E9598" />;
    case "mail":
      return <Mail size={20} color="#8E9598" />;
    case "send":
      return <Send size={20} color="#8E9598" />;
    case "filterX":
      return <FilterX size={20} color="#8E9598" />;
    default:
      return null;
  }
};

export const createMenuOption = (
  title: string,
  onClick: () => void,
  iconName: string,
) => {
  return (
    <MenuItem
      sx={{ display: "flex", gap: "12px" }}
      onClick={onClick}
      key={title}
    >
      {getOptionIcon(iconName)}
      <Typography color="#3D3D3D" variant="body">
        {title}
      </Typography>
    </MenuItem>
  );
};

export const SubmenuMenuOption = ({
  title,
  onClick,
  submenuOptions,
  currentValue,
}: {
  title: string;
  onClick: (v: string) => void;
  submenuOptions: { label: string; value: string }[];
  currentValue?: string;
}) => {
  const [anchorRect, setAnchorRect] = useState<DOMRect | null>(null);
  const handleSubmenuClose = () => {
    setAnchorRect(null);
  };
  return (
    <>
      <MenuItem
        sx={{ display: "flex", gap: "12px", justifyContent: "space-between" }}
        onClick={(event) =>
          setAnchorRect(event.currentTarget.getBoundingClientRect())
        }
        key={title}
      >
        <IconButton size="small">
          <ChevronLeft size={20} />
        </IconButton>
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          {/*{getOptionIcon(iconName)}*/}
          <Typography color="#3D3D3D" variant="body">
            {(currentValue &&
              submenuOptions.find((o) => o.value === currentValue)?.label) ||
              title}
          </Typography>
        </div>
      </MenuItem>
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={
          anchorRect
            ? {
                top: anchorRect.top,
                left: anchorRect.right - anchorRect.width,
              }
            : undefined
        }
        open={Boolean(anchorRect)}
        onClose={handleSubmenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        style={{ marginLeft: "-15px" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        slotProps={{
          paper: {
            sx: {
              "& .MuiPopover-paper": {
                marginLeft: "-15px", // Alternative way to add spacing
              },
              maxHeight: 48 * 5.5,
              width: "20ch",
            },
          },
        }}
      >
        {submenuOptions.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              onClick(option.value);
              handleSubmenuClose();
            }}
          >
            <Typography color="#3D3D3D" variant="body">
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
