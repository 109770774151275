import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  TextField,
  Divider,
  IconButton,
} from "@mui/material";
import {
  Hammer,
  TreePalm,
  Baby,
  PartyPopper,
  Sparkles,
  Utensils,
  X,
} from "lucide-react";
import auth from "components/nux/auth";
import { useAddMemberTasks } from "../../..//services/nux";
import { getStepPath } from "components/nux/utils";
import NuxContentLayout from "components/nux/NuxContentLayout";
import useIsMobile from "../../..//components/hooks/useIsMobile";
import { USER_NUX_TYPE } from "components/nux/constants";
import { ChevronUp, ChevronDown } from "lucide-react";

type ExampleTask = {
  title: string;
  icon: React.ReactElement;
  examples: string[];
};

const EXAMPLE_TASKS: ExampleTask[] = [
  {
    title: "Home services",
    icon: <Hammer size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: ["Find a new handyman", "Book a pest control specialist"],
  },
  {
    title: "Travel and vacation",
    icon: <TreePalm size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: ["Book a beach house for 7 people", "Research car rentals"],
  },
  {
    title: "Children's activities",
    icon: <Baby size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: ["Find beginner ballet lessons", "Register for summer camps"],
  },
  {
    title: "Parties and events",
    icon: <PartyPopper size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: ["Book a bounce house", "Find and wrap a present"],
  },
  {
    title: "Calendar management",
    icon: <Sparkles size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: ["Reschedule PT appointment", "Book recurring date night"],
  },

  {
    title: "Meal planning",
    icon: <Utensils size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: [
      "Find new kid-friendly recipes",
      "Order grocery delivery each week",
    ],
  },
];

const Task = ({
  title,
  icon,
  examples,
}: {
  title: string;
  icon: React.ReactElement;
  examples: string[];
}) => {
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState<boolean>(!isMobile);
  return (
    <Box
      sx={{
        width: isMobile ? "100%" : "225px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: isMobile ? "12px" : "8px",
          }}
        >
          {icon}
          <Typography variant="bodyHeavy">{title}</Typography>
        </Box>
        {isMobile && (
          <Box
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? (
              <ChevronUp color="#8E9598" size={24}></ChevronUp>
            ) : (
              <ChevronDown color="#8E9598" size={24}></ChevronDown>
            )}
          </Box>
        )}
      </Box>
      {isOpen && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          {examples.map((example) => (
            <Box key={example}>
              <Box sx={{ paddingLeft: isMobile ? "44px" : "0px" }}>
                <Typography variant="bodySmall">{example}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const MemberTask = ({
  taskInquiry,
  onRemove,
}: {
  taskInquiry: string;
  onRemove: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "18px 16px 17px 24px",
        alignItems: "center",
        gap: "20px",
        borderRadius: "12px",
        border: "1px solid #D4D4D4",
        background: "#FFF",
        width: "fit-content",
        minWidth: "100%",
        justifyContent: "space-between",
        animation: "growVertical 0.3s ease-out forwards",
        "@keyframes growVertical": {
          from: { maxHeight: "0px", opacity: 0 },
          to: { maxHeight: "200px", opacity: 1 }, // Adjust maxHeight to suit your content
        },
      }}
    >
      {taskInquiry}
      <IconButton onClick={() => onRemove()}>
        <X />
      </IconButton>
    </Box>
  );
};

export default () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [taskInquiry, setTaskInquiry] = useState<string>("");
  const [taskInquiries, setTaskInquiries] = useState<string[]>([]);
  const { request, loading } = useAddMemberTasks((r) => {
    if (r?.nuxDetails?.stepName) {
      const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.MEMBER);
      navigate(path);
    }
  });

  const onContinue = async (tasks: string[]) => {
    await request({
      token: auth.getAuthData(),
      tasks,
    });
  };

  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            marginBottom: "8px",
          }}
        >
          <Typography variant="title">Now’s the fun part!</Typography>
          <Typography variant="body" color="#3D3D3D">
            Let's start unloading your to-do list. Here are some example tasks
            to get the ideas flowing.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "24px",
            padding: "12px 0px",
          }}
        >
          {EXAMPLE_TASKS.map((task, i) => {
            return (
              <Box key={i} sx={{ width: isMobile ? "100%" : "auto" }}>
                <Task key={task.title} {...task} />
                {isMobile ? (
                  <Divider sx={{ marginTop: "16px" }}></Divider>
                ) : (
                  <></>
                )}
              </Box>
            );
          })}
        </Box>
        <Box sx={{ margin: "40px 0px" }}>
          <Box sx={{ marginBottom: "16px" }}>
            <Typography variant="h4" sx={{ marginBottom: "4px" }}>
              What can we help with?
            </Typography>
            <Typography variant="body" color="#3D3D3D">
              We'll add these to your Advisor's list. You can always edit tasks
              or add more details in your Family Hub.
            </Typography>
          </Box>
          <Box
            display="flex"
            gap="16px"
            {...(isMobile
              ? {
                  flexDirection: "column",
                  alignItems: "end",
                }
              : {
                  flexDirection: "row",
                  alignItems: "center",
                })}
          >
            <TextField
              multiline
              fullWidth
              maxRows={4}
              placeholder="Write a short task title..."
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  if (!taskInquiry.trim()) {
                    return;
                  }
                  setTaskInquiries((iq) => [taskInquiry, ...iq]);
                  setTaskInquiry("");
                }
              }}
              onChange={(e) => {
                setTaskInquiry(e.target.value);
              }}
              value={taskInquiry}
              sx={{
                borderRadius: "16px",
                border: "1px solid #D4D4D4",
                background: "#FFF",
                textarea: {
                  padding: "18px 20px",
                  marginTop: "0px !important",
                },
              }}
            />
            <Button
              sx={{ width: "140px" }}
              variant="outlined"
              onClick={() => {
                if (!taskInquiry.trim()) {
                  return;
                }
                setTaskInquiries((iq) => [taskInquiry, ...iq]);
                setTaskInquiry("");
              }}
            >
              Add task
            </Button>
          </Box>
        </Box>
        {taskInquiries.length > 0 && (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "16px",
                gap: "16px",
              }}
            >
              <Typography variant="h4">My tasks</Typography>
              {taskInquiries.map((taskInquiry, i) => (
                <MemberTask
                  key={i}
                  taskInquiry={taskInquiry}
                  onRemove={() => {
                    setTaskInquiries((iq) => iq.filter((_, idx) => idx !== i));
                  }}
                />
              ))}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            alignSelf: "flex-end",
            width: isMobile ? "100%" : "auto",
            gap: "16px",
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row",
            marginTop: isMobile ? "48px" : "16px",
          }}
        >
          <Button
            sx={{ width: "100%" }}
            variant="outlined"
            onClick={() => onContinue([])}
            disabled={loading}
          >
            Skip
          </Button>
          <Button
            sx={{ width: "100%" }}
            onClick={() => onContinue(taskInquiries)}
            disabled={loading}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
