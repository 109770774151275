import useProtoMethod from "./useProtoMethod";
import {
  GetMemberDetailsRequest,
  GetMemberDetailsResponse,
  UpdateMembersFamilyRequest,
  UpdateMembersFamilyResponse,
  GetBillingPortalUrlRequest,
  GetBillingPortalUrlResponse,
  GetAdvisorProfileResponse,
  GetAdvisorProfileRequest,
  SubmitReferralResponse,
  SubmitReferralRequest,
  UpdateMemberNotificationsResponse,
  UpdateMemberNotificationsRequest,
} from "protogen/member_service_pb";

const useGetMemberDetails = (
  callback?: (r: GetMemberDetailsResponse) => void,
) => {
  return useProtoMethod<GetMemberDetailsRequest, GetMemberDetailsResponse>(
    "GetMemberDetails",
    GetMemberDetailsResponse,
    { callback },
  );
};

const useUpdateMembersFamily = (
  callback?: (r: UpdateMembersFamilyResponse) => void,
) => {
  return useProtoMethod<
    UpdateMembersFamilyRequest,
    UpdateMembersFamilyResponse
  >("UpdateMembersFamily", UpdateMembersFamilyResponse, { callback });
};

const useGetBillingPortalUrl = (
  callback?: (r: GetBillingPortalUrlResponse) => void,
) => {
  return useProtoMethod<
    GetBillingPortalUrlRequest,
    GetBillingPortalUrlResponse
  >("GetBillingPortalUrl", GetBillingPortalUrlResponse, { callback });
};

const useGetAdvisorProfile = (
  callback?: (r: GetAdvisorProfileResponse) => void,
) => {
  return useProtoMethod<GetAdvisorProfileRequest, GetAdvisorProfileResponse>(
    "GetAdvisorProfile",
    GetAdvisorProfileResponse,
    { callback },
  );
};

const useSubmitReferral = (callback?: (r: SubmitReferralResponse) => void) => {
  return useProtoMethod<SubmitReferralRequest, SubmitReferralResponse>(
    "SubmitReferral",
    SubmitReferralResponse,
    { callback },
  );
};

const useUpdateMemberNotifications = (
  callback?: (r: UpdateMemberNotificationsResponse) => void,
) => {
  return useProtoMethod<
    UpdateMemberNotificationsRequest,
    UpdateMemberNotificationsResponse
  >("UpdateMemberNotifications", UpdateMemberNotificationsResponse, {
    callback,
  });
};

export {
  useGetMemberDetails,
  useUpdateMembersFamily,
  useGetBillingPortalUrl,
  useGetAdvisorProfile,
  useSubmitReferral,
  useUpdateMemberNotifications,
};
