import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as FayeIcon } from "../icons/FayeBrand.svg";
import AuthService from "services/auth";
import { FayeAppBar } from "components/layout/NavigationLayout";
import { useLocation } from "react-router-dom";

const cleanPhone = (phone: string): string => {
  const clean = phone.replace(/[^\d+]/g, "");
  if (clean.length === 11 && clean[0] === "1") {
    return `+${clean}`;
  } else if (clean.length === 10) {
    return `+1${clean}`;
  } else {
    return clean;
  }
};

const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const isValidPhone = (phone: string) => {
  return /^\+1\d{10}$/.test(cleanPhone(phone));
};

export default () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const usernameFromParams = queryParams.get("username") || "";
  const [username, setUsername] = useState(usernameFromParams);

  const [error, setError] = useState<string | null>(null);
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (sent) return;
    if (!isValidEmail(username) && !isValidPhone(username)) {
      setError("");
      setLoading(false);
      return;
    }
    setLoading(true);
    setError(null);
    AuthService.initiatePasswordReset(username).then(
      () => {
        setSent(true);
        setLoading(false);
        setError(null);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setError(resMessage);
      },
    );
  };

  return (
    <>
      <FayeAppBar />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "500px",
            padding: "0px 28px",
            alignSelf: "stretch",
          }}
        >
          <div>
            <FayeIcon
              style={{
                width: "112.762px",
                height: "64px",
                flexShrink: "0",
                marginBottom: "29px",
              }}
            />
          </div>
          <Typography
            variant="h2Serif"
            sx={{
              marginBottom: "8px",
            }}
          >
            Reset Password
          </Typography>
          <Typography
            variant="body"
            sx={{
              marginBottom: "32px",
            }}
          >
            Your password awaits.
          </Typography>
          {sent && (
            <Alert
              severity="success"
              sx={{
                marginBottom: "12px",
                borderRadius: "12px",
                border: "2px solid #BEDDBB",
                background: "#FBFDFB",
                fontWeight: "500",
                color: "#3D7C37",
                alignSelf: "stretch",
                "& .MuiSvgIcon-root": {
                  color: "#468E3E",
                },
              }}
            >
              A link to reset your password has been sent if there is a matching
              account. See you soon!
            </Alert>
          )}
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            {error !== null && (
              <Alert
                icon={false}
                severity="error"
                sx={{
                  alignSelf: "stretch",
                  marginBottom: "12px",
                  borderRadius: "12px",
                  border: "2px solid #FAD3D0",
                  background: "#FFFBFA",
                }}
              >
                {error && error}
                {!error && "Please enter a valid email or username"}
              </Alert>
            )}
            <FormControl
              fullWidth
              style={{ marginTop: 16 }}
              disabled={loading || sent}
            >
              <TextField
                sx={{ flexGrow: 1 }}
                disabled={loading || sent}
                autoFocus
                label="Username or account email"
                type="text"
                fullWidth
                autoComplete="off"
                variant="outlined"
                error={error !== null}
                value={username}
                onChange={(e) => setUsername(e.target.value?.trim() || "")}
              />
            </FormControl>
            <Button
              disabled={loading || sent}
              type="submit"
              fullWidth
              style={{ marginTop: "12px" }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
};
