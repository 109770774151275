import { useEffect, useState } from "react";
import GridPage from "components/layout/GridPage";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { Box, Chip, Divider, ListItemText, Typography } from "@mui/material";
import Loading from "components/common/Loading";
import { Advisor } from "protogen/common_pb";
import EditRolesDialog from "components/admin/EditRolesDialog";
import { useAdminListAllAdvisors } from "services/admin";
import useIsMobile from "components/hooks/useIsMobile";

export default () => {
  const isMobile = useIsMobile();
  const [editAdvisor, setEditAdvisor] = useState<Advisor | null>(null);
  const { data, loading, request } = useAdminListAllAdvisors();
  useEffect(() => {
    request();
  }, []);
  return (
    <GridPage
      sx={{
        margin: isMobile ? "" : "64px auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Typography variant="title" id="tableTitle">
        Manage Roles
      </Typography>
      {loading && <Loading />}
      <List
        sx={{
          maxWidth: "600px",
        }}
      >
        {(data?.advisors || []).map((advisor, i) => (
          <div key={advisor.ref}>
            <ListItemButton
              sx={{ padding: "20px" }}
              onClick={() => setEditAdvisor(advisor)}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Box display="flex" flexDirection="column" gap="20px">
                  <ListItemText
                    primary={`${advisor.firstName} ${advisor.lastName}`}
                    secondary={`${advisor.username}`}
                  />
                </Box>
                <div>
                  {advisor.roles.map((r: string) => (
                    <Chip
                      key={r}
                      sx={{ marginLeft: "4px" }}
                      label={r}
                      variant="outlined"
                    />
                  ))}
                </div>
              </Box>
            </ListItemButton>
            {i < (data?.advisors || []).length - 1 ? (
              <Divider variant="inset" component="li" />
            ) : null}
          </div>
        ))}
      </List>
      {editAdvisor && (
        <EditRolesDialog
          advisor={editAdvisor}
          open={!!editAdvisor}
          onClose={(updated) => {
            setEditAdvisor(null);
            if (updated) {
              request();
            }
          }}
        />
      )}
    </GridPage>
  );
};
