import ReactiveDialog from "components/common/ReactiveDialog";
import { ImportCalendar } from "./imports";

export default ({
  open,
  importType,
  onClose,
  onSuccess,
}: {
  open: boolean;
  importType: ImportCalendar;
  onClose: () => void;
  onSuccess: (s: string) => void;
  // onFailure: (s: string) => void;
}) => {
  return (
    <ReactiveDialog
      open={open}
      onClose={onClose}
      title={importType.title}
      fullWidthSize={importType.getModalWidth() || "md"}
    >
      {importType.getContent(onSuccess, () => {
        // onFailure("Failed to import calendar");
        onClose();
      })}
    </ReactiveDialog>
  );
};
