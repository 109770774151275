import { useEffect } from "react";
import { FormControl, Autocomplete, TextField } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { useListAllFamilies } from "services/admin";
import { Family } from "../../protogen/advisors_service_pb";

interface Props {
  selectedFamily: string | null;
  onChange: (familyRef: string | null, family?: Family | null) => void;
  error?: string | null;
  disabled?: boolean;
  title?: string;
  isAdmin?: boolean;
}

export default ({
  selectedFamily,
  onChange,
  error,
  disabled,
  title = "Assignee",
}: Props) => {
  const { data: familiesData, request } = useListAllFamilies();

  useEffect(() => {
    request();
  }, []);

  const options = familiesData?.families || [];

  const selectedFamilyObj =
    options.find((family: Family) => family.ref === selectedFamily) || null;

  return (
    <FormControl sx={{ width: "100%" }} fullWidth>
      <Autocomplete
        disabled={disabled}
        value={selectedFamilyObj}
        onChange={(event, value) => {
          onChange(value ? value.ref : null, value || null);
        }}
        options={options}
        getOptionLabel={(option) => option.name || ""}
        renderOption={(props, option) => (
          <li {...props} key={option.ref}>
            <ListItem
              component="div"
              disablePadding
              sx={{ overflow: "hidden" }}
            >
              {option.name}
            </ListItem>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              ".MuiInputBase-root": {
                paddingTop: "3px",
                paddingBottom: "3px",
              },
            }}
            label={title}
            error={!!error}
          />
        )}
      />
    </FormControl>
  );
};
