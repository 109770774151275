import { ReactNode, useEffect } from "react";
import {
  FormControl,
  SelectChangeEvent,
  MenuItem,
  ListItem,
} from "@mui/material";
import Select from "../common/Select";

interface Priority {
  value: string;
  label: string;
  sortOrder: number;
  labelColor: string;
  labelBackgroundColor: string;
  icon: ReactNode;
  border?: string;
}

export const PRIORITY_STATES: Priority[] = [
  {
    value: "low",
    label: "Low",
    labelColor: "#6B6E7B",
    labelBackgroundColor: "#00000014",
    icon: <span>🗓️</span>,
    sortOrder: 3,
  },
  {
    value: "medium",
    label: "Medium",
    labelColor: "#9A6518",
    labelBackgroundColor: "rgba(251, 242, 229, 0.95)",
    icon: <span>🕓</span>,
    sortOrder: 2,
  },
  {
    value: "high",
    label: "High",
    labelColor: "#198282",
    labelBackgroundColor: "#ECF4EC",
    icon: <span>🔥</span>,
    sortOrder: 1,
  },
];
export const getPriorityIcon = (priority: string) => {
  const priorityObj = PRIORITY_STATES.find((p) => p.value === priority);
  return priorityObj ? priorityObj.icon : null;
};
export const getPriorityLabel = (value: string) => {
  const priorityObj = PRIORITY_STATES.find((p) => p.value === value);
  return priorityObj ? priorityObj.label : null;
};

export const getSortOrder = (value: string) => {
  const priorityObj = PRIORITY_STATES.find((p) => p.value === value);
  return priorityObj ? priorityObj.sortOrder : null;
};

export const getLabelColor = (value: string) => {
  const priorityObj = PRIORITY_STATES.find((p) => p.value === value);
  return priorityObj ? priorityObj.labelColor : null;
};

export const getLabelBackgroundColor = (value: string) => {
  const priorityObj = PRIORITY_STATES.find((p) => p.value === value);
  return priorityObj ? priorityObj.labelBackgroundColor : null;
};
export const getLabelBorder = (value: string) => {
  const priorityObj = PRIORITY_STATES.find((p) => p.value === value);
  return priorityObj ? priorityObj.border : null;
};
interface Props {
  selectedPriority: string | null;
  onChange: (priority: string | null) => void;
  error?: string | null;
  disabled?: boolean;
  defaultValue?: string | null;
}

export default ({
  selectedPriority,
  onChange,
  error,
  disabled,
  defaultValue = PRIORITY_STATES[1].value,
}: Props) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedValue = event.target.value as string;
    onChange(selectedValue);
  };

  useEffect(() => {
    if (!selectedPriority && defaultValue) {
      onChange(defaultValue);
    }
  }, []);

  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        options={PRIORITY_STATES}
        disabled={disabled}
        error={!!error}
        value={selectedPriority || defaultValue || ""}
        onChange={handleChange}
      >
        {PRIORITY_STATES.map((priority) => (
          <MenuItem key={priority.value} value={priority.value}>
            <ListItem
              component="div"
              disablePadding
              sx={(theme) => ({ color: theme.palette.text.primary })}
            >
              {priority.label}
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
