import useIsMobile from "../hooks/useIsMobile";
import LinkRouter from "../navigation/LinkRouter";
import TileSection from "./TileSection";
import {
  BookOpenCheck,
  HeartHandshake,
  MessageCircleQuestion,
  MessageSquare,
} from "lucide-react";
import React, { useContext } from "react";
import Panel, { CommonTileProps } from "./Panel";
import { Alert, AlertTitle } from "@mui/material";
import { StatusContext } from "../context/StatusContextProvider";

export default () => {
  const isMobile = useIsMobile();
  const { familyHealthAlert } = useContext(StatusContext);
  return (
    <>
      {familyHealthAlert && (
        <Alert
          severity="error"
          sx={{ margin: "16px 0", backgroundColor: "#FDEDED" }}
        >
          <AlertTitle>One or more families need attention</AlertTitle>
          Please check your{" "}
          <LinkRouter inline={true} to="/families">
            dashboard
          </LinkRouter>{" "}
          for more information.
        </Alert>
      )}
      {!isMobile && (
        <Panel
          title="We want your feedback!"
          content={
            <>
              If you have any thoughts, suggestions or wild ideas about Faye
              please share! Start a discussion with the community here or send
              the team a{" "}
              <LinkRouter
                to="/inbox/messages?recipientRefs=AAAAAAAAAAE="
                inline
              >
                <span
                  style={{
                    color: "#198282",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  direct message
                </span>
              </LinkRouter>
              .
            </>
          }
        />
      )}
      <TileSection
        tiles={[
          {
            title: "Ask FAs",
            icon: <MessageCircleQuestion {...CommonTileProps} />,
            path: "/community/ask-fas",
          },
          {
            title: "Recommendations",
            icon: <HeartHandshake {...CommonTileProps} />,
            path: "/community/recommendations",
          },
          {
            title: "Resources",
            icon: <BookOpenCheck {...CommonTileProps} />,
            path: "/community/resources",
          },
          {
            title: "Chatter",
            icon: <MessageSquare {...CommonTileProps} />,
            path: "/community/chatter",
          },
        ]}
      />
    </>
  );
};
