import { useEffect, useState } from "react";
import { Box, Typography, Button, FormControlLabel } from "@mui/material";
import GridPage from "components/layout/GridPage";
import TaskInspirationDialog from "components/tasks/TaskInspirationDialog";
import TaskInspirationCategoryDialog from "components/tasks/TaskInspirationCategoryDialog";
import {
  useListAllTaskInspirations,
  useListAllTaskInspirationCategories,
} from "services/tasks";
import { TaskInspiration, TaskInspirationCategory } from "protogen/tasks_pb";
import useIsMobile from "components/hooks/useIsMobile";
import { InspiredTaskList } from "components/tasks/TaskInspiration";
import Checkbox from "components/common/Checkbox";
import SnackPack, { SnackbarMessage } from "components/common/SnackPack";

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const groupByCategory = (
  inspirations: TaskInspiration[],
  inspirationCategories: TaskInspirationCategory[],
) => {
  const inspirationsByCategory = inspirations.reduce(
    (acc: { [key: string]: TaskInspiration[] }, inspiration) => {
      const category =
        inspirationCategories.find((c) => {
          return c.ref === inspiration.categoryRef;
        })?.title || "";

      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(inspiration);
      return acc;
    },
    {},
  );

  return inspirationsByCategory;
};

export default () => {
  const isMobile = useIsMobile();
  const [shouldFilterInactiveTasks, setShouldFilterInactiveTasks] =
    useState<boolean>(true);
  const [inspirationsByCategory, setInspirationsByCategory] = useState<
    Record<string, TaskInspiration[]>
  >({});
  const [selectedTaskInspiration, setSelectedTaskInspiration] =
    useState<TaskInspiration | null>(null);
  const [selectedTaskInspirationCategory, setSelectedTaskInspirationCategory] =
    useState<TaskInspirationCategory | null>(null);
  const [open, setOpen] = useState(false);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const { data, request: listAllTaskInspirationRequest } =
    useListAllTaskInspirations(() => {});
  const { data: inspirationCategories, request: inspirationCategoriesRequest } =
    useListAllTaskInspirationCategories(() => {});
  useEffect(() => {
    inspirationCategoriesRequest();
    listAllTaskInspirationRequest();
  }, [open, categoryDialogOpen]);

  const onCopySuccess = (task: TaskInspiration) => {
    setSnackPack((p) => [
      ...p,
      {
        message: `Task ${task.title} copied!`,
        saveNotification: true,
        key: `copy-task-${task.ref}`,
      },
    ]);
  };

  useEffect(() => {
    const filteredInspirations = data?.taskInspirations.filter(
      (inspiration) => {
        return shouldFilterInactiveTasks
          ? inspiration.status === "active"
          : true;
      },
    );
    setInspirationsByCategory(
      groupByCategory(
        filteredInspirations || [],
        inspirationCategories?.taskInspirationCategories || [],
      ),
    );
  }, [shouldFilterInactiveTasks, data, inspirationCategories]);

  if (!data || !inspirationCategories) return <></>;
  return (
    <GridPage
      sx={{
        padding: isMobile ? "32px 24px" : "64px 48px",
        maxWidth: "1000px",
        gap: "12px",
        margin: isMobile ? "" : "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? "12px" : "",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="title">Task inspiration</Typography>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <FormControlLabel
            label={
              <Typography variant="body" sx={{ WebkitUserSelect: "none" }}>
                Filter inactive tasks
              </Typography>
            }
            control={
              <Checkbox
                checked={shouldFilterInactiveTasks}
                onChange={() => setShouldFilterInactiveTasks((u) => !u)}
              />
            }
          />
          <Button
            variant="outlined"
            onClick={() => {
              setCategoryDialogOpen(true);
            }}
          >
            Add category
          </Button>
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            Add new
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        {Object.keys(inspirationsByCategory).map((category, i) => {
          const inspirations = inspirationsByCategory[category];
          return (
            <InspiredTaskList
              numVisibileTasks={100}
              showStatus={true}
              iconType="More"
              key={i}
              title={capitalizeFirstLetter(category)}
              titleSx={{
                fontFamily: "AlbertSans",
                fontSize: "18px",
                fontWeight: "700",
              }}
              tasks={inspirations}
              onTaskClick={(task) => {
                setSelectedTaskInspiration(task as TaskInspiration);
                setOpen(true);
              }}
              onCopySuccess={onCopySuccess}
              onCategoryClick={(categoryRef) => {
                const category =
                  inspirationCategories.taskInspirationCategories.find((c) => {
                    return c.ref === categoryRef;
                  });
                setSelectedTaskInspirationCategory(category);
                setCategoryDialogOpen(true);
              }}
            />
          );
        })}
      </Box>
      <TaskInspirationDialog
        open={open}
        taskInspiration={selectedTaskInspiration}
        taskInspirationCategories={
          inspirationCategories.taskInspirationCategories
        }
        onClose={() => {
          setOpen(false);
          setSelectedTaskInspiration(null);
        }}
      />
      <TaskInspirationCategoryDialog
        open={categoryDialogOpen}
        taskInspirationCategory={selectedTaskInspirationCategory}
        taskInspirationCategories={
          inspirationCategories.taskInspirationCategories
        }
        onClose={() => {
          setCategoryDialogOpen(false);
          setSelectedTaskInspirationCategory(null);
        }}
      />
      <SnackPack
        autoHideDuration={2000}
        snackPack={snackPack}
        alertSeverity="success"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </GridPage>
  );
};
