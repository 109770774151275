import { useContext, useEffect } from "react";
import { FormControl, Autocomplete, TextField } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { CurrentUserContext } from "../context/RequireAuth";
import { AccountStub } from "protogen/common_pb";
import { isUserAdmin } from "../../common/userUtils";
import { useAdminListAllAdvisors } from "services/admin";
import { useListAllAdvisors } from "services/advisor";
import { ChevronDown } from "lucide-react";

interface Props {
  selectedAdvisor: string | null;
  onChange: (advisorRef: string | null, advisor?: AccountStub | null) => void;
  error?: string | null;
  disabled?: boolean;
  title?: string;
  defaultSelf?: boolean;
  isAdmin?: boolean;
}

export default ({
  selectedAdvisor,
  onChange,
  error,
  disabled,
  title = "Assignee",
  defaultSelf = false,
  isAdmin = false,
}: Props) => {
  const currentUser = useContext(CurrentUserContext);

  const callback = () => {
    if (defaultSelf && !selectedAdvisor) {
      onChange(currentUser.ref);
    }
  };

  const { data: advisorsData, request } = isAdmin
    ? useAdminListAllAdvisors(callback)
    : useListAllAdvisors(callback);

  useEffect(() => {
    if (isAdmin) {
      request();
    } else {
      request({ includeStaff: true });
    }
  }, []);

  const options = advisorsData?.advisors || [];

  const selectedAdvisorObj =
    options.find((advisor: AccountStub) => advisor.ref === selectedAdvisor) ||
    null;

  return (
    <FormControl sx={{ width: "100%" }} fullWidth>
      <Autocomplete
        disableClearable
        popupIcon={<ChevronDown />}
        disabled={disabled || (isAdmin && !isUserAdmin(currentUser))}
        value={selectedAdvisorObj}
        onChange={(event, value) => {
          onChange(value ? value.ref : null, value || null);
        }}
        options={options}
        getOptionLabel={(option) => option.displayName || ""}
        renderOption={(props, option) => (
          <li {...props} key={option.ref}>
            <ListItem
              component="div"
              disablePadding
              sx={{ overflow: "hidden" }}
            >
              {option.displayName}
            </ListItem>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              ".MuiInputBase-root": {
                paddingTop: "3px",
                paddingBottom: "3px",
              },
            }}
            label={title}
            error={!!error}
          />
        )}
      />
    </FormControl>
  );
};
