import { useState } from "react";
import { Box, Button } from "@mui/material";
import { CalendarClock } from "lucide-react";
import ScheduledMessageDialog from "./ScheduledMessageDialog";
import { formatDate } from "./TextMessageCompose";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { StyledMenu } from "../common/DropdownMenu";
import { ScheduleMessageReturnType } from "./useScheduleMessage";

export default ({
  scheduledTime,
  scheduledManager,
}: {
  scheduledTime: bigint;
  scheduledManager: ScheduleMessageReturnType;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [schedulerOpen, setSchedulerOpen] = useState(false);
  const [scheduled, setScheduled] = useState<Date>(
    new Date(Number(scheduledTime) * 1000),
  );
  return (
    <Box
      sx={{
        padding: "4px 8px",
      }}
    >
      <Button
        variant={"text"}
        sx={{
          height: "unset",
          fontSize: "14px",
        }}
        onClick={handleClick}
        startIcon={<CalendarClock size={20} />}
      >
        Scheduled for {formatDate(scheduled)}
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          disabled={scheduledManager.loading}
          onClick={async () => {
            await scheduledManager.handleSendNow();
            setAnchorEl(null);
          }}
          disableRipple
          sx={{
            gap: "12px",
            height: "40px",
          }}
        >
          Send now
        </MenuItem>
        <MenuItem
          disabled={scheduledManager.loading}
          onClick={() => {
            setAnchorEl(null);
            setSchedulerOpen(true);
          }}
          disableRipple
          sx={{
            gap: "12px",
            height: "40px",
          }}
        >
          Change time
        </MenuItem>
        <MenuItem
          disabled={scheduledManager.loading}
          onClick={async () => {
            await scheduledManager.handleDelete();
            setAnchorEl(null);
          }}
          disableRipple
          sx={{
            gap: "12px",
            height: "40px",
          }}
        >
          Delete
        </MenuItem>
      </StyledMenu>
      {/* Drop down */}
      <ScheduledMessageDialog
        open={schedulerOpen}
        value={scheduled}
        onUpdate={async (d) => {
          await scheduledManager.handleUpdateSchedule(d);
          setScheduled(d);
        }}
        onClose={() => {
          setSchedulerOpen(false);
        }}
      />
    </Box>
  );
};
