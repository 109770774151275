import { Box, Button, IconButton, Typography } from "@mui/material";
import { Trash2 } from "lucide-react";
import { useRevealICSUrl, useUpdateICSUrl } from "services/calendar";
import { ICSExportIntegration } from "protogen/calendar_service_pb";
import CopyTextField from "components/common/CopyTextField";
import { calendarSyncStatus } from "./CalendarSyncStatus";
import useIsMobile from "components/hooks/useIsMobile";

export default ({
  link,
  refresh,
}: {
  link: ICSExportIntegration;
  refresh: () => void;
}) => {
  const { request: updateLink, loading: updateLoading } = useUpdateICSUrl();

  const onDisableLink = async () => {
    await updateLink({
      ref: link.ref,
      updatedName: "",
      updatedStatus: "deactivated",
    });
    refresh();
  };

  const exportLink = link.icsUrlPath || null;
  const { request: revealRequest } = useRevealICSUrl();
  const [icon, byline] = calendarSyncStatus(Number(link.lastAccessedSec));
  const isMobile = useIsMobile();

  return (
    <Box
      key={link.ref}
      sx={{
        display: "flex",
        alignItems: isMobile ? "start" : "center",
        justifyContent: "space-between",
        gap: "16px",
        padding: "12px 24px",
        border: "1px solid #E2E3E4",
        borderRadius: "12px",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Box display="flex" alignItems="center" gap={2} flex={10}>
        {icon}
        <Box>
          <Typography variant="body1">
            {link.name || "Exported Calendar"}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {byline}
          </Typography>
        </Box>
      </Box>
      <CopyTextField
        sx={{ width: isMobile ? "100%" : "35%" }}
        value={exportLink}
        onReveal={async () => {
          const resp = await revealRequest({ ref: link.ref });
          return resp?.export?.icsUrlPath || "";
        }}
      />
      {isMobile ? (
        <Button
          variant="text"
          size="small"
          color="error"
          onClick={() => onDisableLink()}
          disabled={updateLoading}
          sx={{ flex: 1 }}
        >
          Delete
        </Button>
      ) : (
        <IconButton
          onClick={() => onDisableLink()}
          disabled={updateLoading}
          edge="end"
          sx={{ flex: 1 }}
        >
          <Trash2 width="20" />
        </IconButton>
      )}
    </Box>
  );
};
