import { mergeAttributes, Node, nodePasteRule } from "@tiptap/core";

// Regex to match Canva design URLs and extract the design ID
const CANVA_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?canva\.com\/design\/(([a-zA-Z0-9_-]+)(\/([a-zA-Z0-9_-]+))*)\/(edit|view)(?:\?.*)?/;
const CANVA_REGEX_GLOBAL =
  /^(?:https?:\/\/)?(?:www\.)?canva\.com\/design\/(([a-zA-Z0-9_-]+)(\/([a-zA-Z0-9_-]+))*)\/(edit|view)(?:\?.*)?$/g;

const isValidCanvaUrl = (url: string) => {
  return url.match(CANVA_REGEX);
};

const styleObjectToCssString = (styles: Record<string, any>): string => {
  return Object.entries(styles)
    .map(([key, value]) => {
      const kebabKey: string = key
        .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
        .toLowerCase();
      return `${kebabKey}: ${value};`;
    })
    .join(" ");
};

export interface CanvaOptions {
  addPasteHandler: boolean;
  inline: boolean;
  width: number;
  height: number;
  HTMLAttributes: Record<string, any>;
  allowFullscreen: boolean;
  lazy: boolean;
  noBorder: boolean;
}

type SetCanvaEmbedOptions = {
  sourceLink: string;
  width?: number;
  height?: number;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    canva: {
      /**
       * Insert a Canva design
       */
      setCanvaEmbed: (options: SetCanvaEmbedOptions) => ReturnType;
    };
  }
}

export default Node.create<CanvaOptions>({
  name: "canva",

  addOptions() {
    return {
      addPasteHandler: true,
      inline: false,
      HTMLAttributes: {},
      height: 480,
      width: 480,
      allowFullscreen: true,
      lazy: true,
      noBorder: true,
    };
  },

  group() {
    return this.options.inline ? "inline" : "block";
  },

  inline: false,
  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      sourceLink: {
        default: null,
      },
      width: {
        default: this.options.width,
      },
      height: {
        default: this.options.height,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div[data-canva-embed]",
        getAttrs: (element) => {
          const iframe = element.querySelector("iframe");
          if (!iframe) return false;

          const sourceLink = iframe.getAttribute("src");
          const width = iframe.style.width || this.options.width;
          const height = iframe.style.height || this.options.height;

          return {
            sourceLink,
            width,
            height,
          };
        },
      },
    ];
  },

  addCommands() {
    return {
      setCanvaEmbed:
        (options: SetCanvaEmbedOptions) =>
        ({ commands }) => {
          if (!isValidCanvaUrl(options.sourceLink)) {
            return false;
          }

          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addPasteRules() {
    if (!this.options.addPasteHandler) {
      return [];
    }

    return [
      nodePasteRule({
        find: CANVA_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          return { sourceLink: match[0] };
        },
      }),
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = HTMLAttributes as SetCanvaEmbedOptions;
    // Reset the lastIndex to ensure the regex works correctly when called multiple times
    CANVA_REGEX.lastIndex = 0;
    // Use the exec method to search for a match
    const match = CANVA_REGEX.exec(attrs.sourceLink);
    // match[1] will be the captured group if a match is found
    const designId = match ? match[1] : "";
    // Build the embed URL
    const embedUrl = `https://www.canva.com/design/${designId}/view?embed`;
    return [
      "div",
      {
        "data-canva-embed": "",
        style: styleObjectToCssString({
          position: "relative",
          width: `${this.options.width}px`,
          height: `${this.options.height}px`,
          maxWidth: "90%", // Max out at 90% of the screen width
          paddingBottom: "56.25%", // Maintains a 16:9 aspect ratio
        }),
      },
      [
        "iframe",
        mergeAttributes(this.options.HTMLAttributes, {
          src: embedUrl,
          loading: this.options.lazy ? "lazy" : undefined,
          style: styleObjectToCssString({
            position: "absolute",
            width: `${this.options.width}px`,
            height: `${this.options.height}px`,
            maxHeight: "100%",
            maxWidth: "100%",
            top: 0,
            left: 0,
            padding: 0,
            margin: 0,
            ...(this.options.noBorder ? { border: "none" } : {}),
          }),
          ...(this.options.allowFullscreen
            ? {
                allowfullscreen: "allowfullscreen",
                allow: "fullscreen",
              }
            : {}),
        }),
      ],
    ];
  },
});
