export default () => {
  const queryParams = new URLSearchParams(location.search);
  const year = queryParams.get("year");
  const month = queryParams.get("month");
  const day = queryParams.get("day");
  let defaultDate = new Date();
  if (year && month && day) {
    defaultDate = new Date(
      parseInt(year, 10),
      parseInt(month, 10),
      parseInt(day, 10),
    );
  }
  return defaultDate;
};
