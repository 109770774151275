import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

export const nonHookIsMobile = () => {
  // Define the media query for small screens (similar to `theme.breakpoints.down("sm")` in MUI)
  const mediaQuery = window.matchMedia("(max-width: 600px)"); // Adjust the width as per your theme

  // Return whether the query matches
  return mediaQuery.matches;
};

export default () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isMobile;
};
