import _ from "lodash";
import { ReactElement, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { ReactComponent as CheckmarkIcon } from "../icons/Member/Checkmark.svg";
import { ReactComponent as LoadingIcon } from "../icons/Member/Loading.svg";
import Loading from "components/common/Loading";
import FlexPage from "components/layout/FlexPage";
import useIsMobile from "components/hooks/useIsMobile";
import Breadcrumbs from "components/common/Breadcrumbs";
import { Task, TaskState } from "protogen/tasks_pb";
import { useListTasksForMember } from "services/tasks";
import { useGetMemberDetails } from "services/member";
import TaskInspiration from "components/tasks/TaskInspiration";
import MemberTaskSection from "components/tasks/MemberTaskSection";
import SearchBar from "components/forum/SearchBar";

const TaskSectionHeader = ({
  title,
  icon,
  count,
}: {
  title: string;
  icon: ReactElement;
  count: number;
}) => {
  return (
    <Box sx={{ display: "flex", gap: "8px", marginBottom: "24px" }}>
      {icon}
      <Typography variant="h2">{title}</Typography>
      <Typography
        variant="bodyHeavy"
        sx={{ color: "#6B6E7B", marginTop: "2px" }}
      >
        {count}
      </Typography>
    </Box>
  );
};

export default () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [showDoneTasks, setShowDoneTasks] = useState(false);
  const [query, setQuery] = useState("");
  const { request: memberDetailsRequest, data: memberDetails } =
    useGetMemberDetails(() => {});
  const onAddTaskClick = () => {
    navigate("/tasks/add", { state: { family: memberDetails?.family } });
  };

  const { request, data } = useListTasksForMember((r) => {
    setTasks(r.tasks);
  });

  useEffect(() => {
    request();
    memberDetailsRequest();
  }, []);

  let resultingTasks = tasks || [];
  if (query) {
    const queryPaths = ["title", "priority", "state", "family.name"];
    const regexPattern = new RegExp(
      query
        .split(" ")
        .map((sub) => `(${sub})`)
        .join("|"),
      "i",
    );

    resultingTasks = resultingTasks.filter((t) => {
      const searchableValues = queryPaths
        .map((path) => _.get(t, path))
        .join(" ");
      return regexPattern.test(searchableValues);
    });
  }

  const openTasks = useMemo(
    () =>
      resultingTasks.filter(
        (task) =>
          task.state === TaskState.INPROGRESS || task.state === TaskState.OPEN,
      ),
    [resultingTasks],
  );
  const doneTasks = useMemo(
    () =>
      resultingTasks
        .filter((task) => task.state === TaskState.COMPLETED)
        .sort((a, b) => Number(b.lastUpdatedSec) - Number(a.lastUpdatedSec)),

    [resultingTasks],
  );

  return (
    <FlexPage leftAligned sx={{ width: "100%" }}>
      <Breadcrumbs
        breadcrumbs={[{ name: "Home", link: "/" }, { name: "Tasks" }]}
      />
      <Typography
        variant="display"
        sx={{ marginBottom: isMobile ? "24px" : "32px" }}
      >
        Family tasks
      </Typography>
      {!data ? (
        <Loading />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "48px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              justifyContent: "space-between",
            }}
          >
            <SearchBar
              fullWidth={isMobile}
              onQuery={(query) => {
                setQuery(query);
                setShowDoneTasks(true);
              }}
              onKeyPress={true}
              sx={{ marginLeft: "0px" }}
            />
            <Button sx={{ minWidth: "120px" }} onClick={onAddTaskClick}>
              Add task
            </Button>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TaskSectionHeader
              title="Open tasks"
              icon={<LoadingIcon height="24px" width="24px" />}
              count={openTasks.length}
            />
            <MemberTaskSection tasks={openTasks} />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TaskSectionHeader
              title="Done"
              icon={<CheckmarkIcon />}
              count={doneTasks.length}
            />
            {showDoneTasks ? (
              <MemberTaskSection tasks={doneTasks} />
            ) : (
              <Button
                variant="outlinedSecondary"
                onClick={() => {
                  setShowDoneTasks(!showDoneTasks);
                }}
              >
                Show {doneTasks.length} finished tasks
              </Button>
            )}
          </Box>
          <TaskInspiration
            family={memberDetails?.family!}
            activeTasks={memberDetails?.activeTasks || []}
          />
        </Box>
      )}
    </FlexPage>
  );
};
