import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Button, TextField, Alert } from "@mui/material";
import { useGetFamilyDetails } from "services/advisor";
import { useCreateMemberNux } from "services/nux";
import GridPage from "components/layout/GridPage";
import Loading from "components/common/Loading";
import useIsMobile from "components/hooks/useIsMobile";
import { SearchContext } from "components/context/SearchContextProvider";
import AdvisorNote from "components/nux/member/AdvisorNote";
import { Advisor } from "protogen/common_pb";
import { Family } from "protogen/advisors_service_pb";
import {
  PlanDetailsForm,
  Errors,
  defaultPlanDetails,
  validateFamilyInvitation,
} from "components/nux/member/types";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useNavigate } from "react-router";
import FamilyPlanDetails from "components/family/FamilyPlanDetails";

const DEFAULT_WELCOME_MESSAGE =
  "Thank you for taking the time to fill out your information. As soon as this is complete, we'll schedule our onboarding call and start tackling your tasks. Look forward to meeting you!";

const ClientDetails = ({ family }: { family: Family }) => {
  const primaryContact = family.familyMembers[0];
  const isMobile = useIsMobile();
  return (
    <Box>
      <Typography variant="h3Serif">Client information</Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "40px",
          marginTop: "10px",
        }}
      >
        <TextField
          sx={{ width: isMobile ? "100%" : "calc(50% - 20px)" }}
          disabled
          margin="none"
          label="First name"
          type="text"
          variant="outlined"
          value={primaryContact.firstName || ""}
        />
        <TextField
          sx={{ width: isMobile ? "100%" : "calc(50% - 20px)" }}
          disabled
          margin="none"
          label="Last name"
          type="text"
          variant="outlined"
          value={primaryContact.lastName || ""}
        />
        <TextField
          sx={{ width: isMobile ? "100%" : "calc(50% - 20px)" }}
          disabled={!!primaryContact.primaryEmail}
          margin="none"
          label="Email"
          name="username"
          type="email"
          variant="outlined"
          value={primaryContact.primaryEmail || ""}
        />
      </Box>
      <Typography>
        The invitation to sign up will be sent to this email.
      </Typography>
    </Box>
  );
};

const ServiceDetails = ({
  serviceDetails,
  errors,
  onServiceDetailsChange,
}: {
  serviceDetails: string;
  errors: Errors;
  onServiceDetailsChange: (serviceDetails: string) => void;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ marginBottom: "4px" }}>
        <Typography variant="h3Serif">Service details</Typography>
      </Box>
      <Typography variant="body">
        Briefly summarize your service. Include details of what you do and don’t
        do and how many hours a month are included with their plan.
      </Typography>
      <TextField
        sx={{ marginTop: "12px" }}
        multiline
        rows={4}
        placeholder="Ex. Our plan includes 8 hours of task assistance each month, including the occasional errand."
        value={serviceDetails}
        error={!!errors?.serviceDetails}
        helperText={errors?.serviceDetails}
        onChange={(e) => {
          onServiceDetailsChange(e.target.value);
        }}
      />
    </Box>
  );
};

const WelcomeMessage = ({
  advisor,
  welcomeMessage,
  onWelcomeMessageChange,
}: {
  advisor?: Advisor;
  welcomeMessage: string;
  onWelcomeMessageChange: (message: string) => void;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ marginBottom: "4px" }}>
        <Typography variant="h3Serif">Welcome message</Typography>
      </Box>
      <Typography variant="body">
        When your clients finish the signup form, we’ll include a message from
        you.
      </Typography>
      <TextField
        sx={{ margin: "20px 0px" }}
        multiline
        rows={4}
        value={welcomeMessage}
        onChange={(e) => {
          onWelcomeMessageChange(e.target.value);
        }}
      />
      <Box sx={{ marginBottom: "12px" }}>
        <Typography variant="bodyHeavy">Message preview</Typography>
      </Box>

      <AdvisorNote
        advisor={advisor}
        message={welcomeMessage}
        backgroundColor="#FAF9FA"
      />
    </Box>
  );
};

export default () => {
  const navigate = useNavigate();
  const { setFamily } = useContext(SearchContext);
  const isMobile = useIsMobile();
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [planDetails, setPlanDetails] =
    useState<PlanDetailsForm>(defaultPlanDetails());
  const [serviceDetails, setServiceDetails] = useState<string>("");
  const [welcomeMessage, setWelcomeMessage] = useState<string>(
    DEFAULT_WELCOME_MESSAGE,
  );
  const params = useParams();
  const { request: createMemberNuxRequest } = useCreateMemberNux();
  const { data, request } = useGetFamilyDetails((r) => {
    if (r.family) {
      setFamily(r.family);
      setPlanDetails({
        ...planDetails,
        startDate: r.family.startDate
          ? new Date(r.family.startDate)
          : planDetails.startDate,
        product: r.family.billingInfo?.product?.id
          ? r.family.billingInfo.product
          : undefined,
        coupon: r.family.billingInfo?.introCoupon?.id
          ? r.family.billingInfo?.introCoupon
          : undefined,
      });
      setHasLoaded(true);
    }
  });

  useEffect(() => {
    setHasLoaded(false);
    request({ familyRef: params.familyRef! });
  }, [params.familyRef]);

  if (!hasLoaded || !data) {
    return <Loading />;
  }

  const { family, primaryAdvisor } = data;

  const handleSendInvitation = async () => {
    const _errors = validateFamilyInvitation(planDetails, serviceDetails);
    if (Object.keys(_errors).length > 0) {
      setErrors(_errors);
      return;
    }
    setDisabled(true);
    const resp = await createMemberNuxRequest({
      familyRef: params.familyRef!,
      startDate: planDetails.startDate!.toLocaleDateString("en-US"),
      product: planDetails.product,
      coupon: planDetails.coupon,
      serviceDetails: serviceDetails,
      welcomeMessage: welcomeMessage,
    });
    if (resp) {
      navigate(`/families/${encodeURIComponent(params.familyRef!)}`);
    }
    setDisabled(false);
  };

  if (!family) {
    throw Error("Family type expected.");
  }
  return (
    <GridPage
      sx={{
        maxWidth: "1000px",
        margin: isMobile ? "" : "64px auto",
        padding: isMobile ? "32px 24px" : "0",
        gap: "39px",
      }}
    >
      <Box>
        <Breadcrumbs
          breadcrumbs={[
            { name: "Families", link: "/families" },
            { name: family.name, link: `/families/${family.ref}` },
            { name: "Invite" },
          ]}
        />
        <Typography variant="title">New client invitation</Typography>
        {!primaryAdvisor?.blurbContent && (
          <Alert
            severity="error"
            action={
              <Button
                sx={{ height: "26px" }}
                variant="text"
                size="small"
                onClick={() => {
                  navigate("/settings");
                }}
              >
                Go to settings
              </Button>
            }
          >
            Please update your profile "About me" section before sending an
            invitation.
          </Alert>
        )}
      </Box>
      <ClientDetails family={family} />
      <FamilyPlanDetails
        planDetails={planDetails}
        errors={errors}
        onPlanDetailsChange={(details) => {
          setPlanDetails(details);
        }}
        stacked={isMobile}
      />
      <ServiceDetails
        serviceDetails={serviceDetails}
        errors={errors}
        onServiceDetailsChange={(details) => {
          setServiceDetails(details);
        }}
      />
      <WelcomeMessage
        advisor={primaryAdvisor}
        welcomeMessage={welcomeMessage}
        onWelcomeMessageChange={(m) => {
          setWelcomeMessage(m);
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          sx={{ alignSelf: "flex-end" }}
          onClick={handleSendInvitation}
          disabled={disabled || !primaryAdvisor?.blurbContent}
        >
          Send invitation
        </Button>
      </Box>
    </GridPage>
  );
};
