import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { useGetTask } from "services/tasks";
import Loading from "components/common/Loading";
import useIsMobile from "components/hooks/useIsMobile";
import TaskDetailsPanel from "components/tasks/TaskDetailsPanel";
import Breadcrumbs from "components/common/Breadcrumbs";
import { Task } from "protogen/tasks_pb";
import NotFound from "components/NotFound";
import TaskDropDown from "components/tasks/TaskDropDown";
import useTitle from "components/hooks/useTitle";

export default ({ addTask = false }: { addTask?: boolean }) => {
  const location = useLocation();
  const family = location.state?.family;
  const inspiredTask = location.state?.inspiredTask;
  let params = useParams();
  const [task, setTask] = useState<Task | null>(null);
  const [isUnsavedTask, setIsUnsavedTask] = useState(false);
  const { data, loading, request, error } = useGetTask((r) => {
    setIsUnsavedTask(false);
    setTask(r.task);
  });
  useTitle(data?.task?.title || "Tasks");
  const isMobile = useIsMobile();
  useEffect(() => {
    if (addTask) {
      const newTask = new Task({ state: 1 });
      if (inspiredTask) {
        newTask.title = inspiredTask.title;
        newTask.sourceEntityType = "task_inspiration";
        newTask.sourceEntityRef = inspiredTask.ref;
      }
      setTask(newTask);
      setIsUnsavedTask(true);
      return;
    }
    if (isUnsavedTask) return;
    request({ taskRef: params.taskRef!, markTaskNotificationsRead: true });
  }, [params.taskRef, inspiredTask]);

  if (error) return <NotFound title={"Task not found"} />;
  if (!task || loading) return <Loading />;

  const breadcrumbs =
    isUnsavedTask && !family
      ? [{ name: "Tasks", back: true }]
      : [
          {
            name: isUnsavedTask && family ? family.name : task?.family?.name,
            link: `/families/${encodeURIComponent(
              isUnsavedTask && family ? family.ref : task?.family?.ref || "",
            )}`,
          },
          { name: "Tasks", back: true },
        ];

  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        maxWidth="850px"
        sx={{ marginBottom: "8px" }}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            {(task?.ref || isUnsavedTask) && (
              <TaskDropDown
                task={task}
                refresh={() => {
                  request({
                    taskRef: task?.ref!,
                    markTaskNotificationsRead: false,
                  });
                }}
                isUnsavedTask={isUnsavedTask}
                toggleHiddenUnsavedTask={(hidden) => {
                  setTask((t) => {
                    if (t) {
                      t.hidden = hidden;
                    }
                    return t;
                  });
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TaskDetailsPanel
          task={task}
          family={isUnsavedTask ? family : data?.family}
          updateTask={(t) => {
            setTask(t);
            setIsUnsavedTask(false);
          }}
          isUnsavedTask={isUnsavedTask}
        />
      </Box>
    </Box>
  );
};
