import { useEffect, useState } from "react";
import { useUpdateCalendarIntegration } from "services/calendar";
import { Box, IconButton, Typography, Collapse } from "@mui/material";
import { CalendarIntegration } from "protogen/calendar_service_pb";
import Checkbox from "components/common/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Trash2 } from "lucide-react";
import { calendarSyncStatus } from "./CalendarSyncStatus";

export default ({
  integration,
  onUpdate,
  defaultOpen = false,
}: {
  integration: CalendarIntegration;
  onUpdate: () => void;
  defaultOpen: boolean;
}) => {
  const { request } = useUpdateCalendarIntegration();
  const [isExpanded, setIsExpanded] = useState(defaultOpen);

  useEffect(() => {
    if (defaultOpen) {
      setIsExpanded(true);
    }
  }, [defaultOpen]);
  const onDeleteIntegration = async () => {
    const response = await request({
      integrationRef: integration.ref,
      deactivate: true,
      activate: false,
      enableCalendarId: "",
      disableCalendarId: "",
    });
    if (response) {
      onUpdate();
    }
  };

  const onCalendarChange = async (calendarId: string, enabled: boolean) => {
    const response = await request({
      integrationRef: integration.ref,
      deactivate: false,
      activate: false,
      enableCalendarId: enabled ? calendarId : "",
      disableCalendarId: !enabled ? calendarId : "",
    });
    if (response) {
      onUpdate();
    }
  };

  const enabled = integration.status === "active";
  const [icon, byline] = calendarSyncStatus(
    Number(integration.lastAccessedSec),
  );
  if (integration.status === "deactivated") return null;
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        border: "1px solid #E2E3E4",
        borderRadius: "12px",
        background: "#FFF",
        width: "100%",
        padding: "16px 24px",
      }}
    >
      <Box
        display="flex"
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={2} flexGrow={1}>
          {icon}
          <Box
            display="flex"
            flexDirection={"column"}
            gap="4px"
            sx={{ cursor: !isExpanded ? "pointer" : undefined }}
            onClick={() => !isExpanded && setIsExpanded(true)}
          >
            <Typography
              variant="bodyHeavy"
              color="#262626"
            >{`${integration.name}`}</Typography>
            <Typography
              variant="bodySmall"
              color="text.secondary"
              sx={{
                textOverflow: "ellipses",
                overflow: "hidden",
              }}
            >
              {byline}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" gap="16px" alignItems="center">
          <IconButton aria-label="delete" onClick={onDeleteIntegration}>
            <Trash2 width="20" />
          </IconButton>
          <IconButton
            sx={{
              visibility: integration.calendars?.length ? "visible" : "hidden",
            }}
            aria-label="expand"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isExpanded}>
        {integration.calendars?.length > 0 && (
          <Box
            display="flex"
            flexDirection="column"
            gap="4px"
            alignItems={"start"}
            marginTop="12px"
          >
            <Box display="flex" flexDirection="column" gap="16px">
              {integration.calendars.map((cal) => {
                return (
                  <Box
                    key={cal.id}
                    display="flex"
                    alignItems="center"
                    gap="8px"
                  >
                    <Checkbox
                      disabled={!enabled}
                      checked={cal.enabled}
                      onChange={(e) =>
                        onCalendarChange(cal.id, e.target.checked)
                      }
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography
                        variant="bodyHeavy"
                        color="text.primary"
                        sx={{
                          textWrap: "none",
                          textOverflow: "ellipses",
                          overflow: "hidden",
                        }}
                      >
                        {cal.summary}
                      </Typography>
                      <Typography
                        variant="body"
                        color="text.secondary"
                        sx={{
                          textWrap: "none",
                          textOverflow: "ellipses",
                          overflow: "hidden",
                        }}
                      >
                        {cal.id}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Collapse>
    </Box>
  );
};
