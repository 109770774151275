import React, { useContext, useEffect, useState } from "react";
import { FormControlLabel, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/material";
import Loading from "components/common/Loading";
import { Advisor } from "protogen/common_pb";
import useIsMobile from "components/hooks/useIsMobile";
import LinkRouter from "components/navigation/LinkRouter";
import { useNavigate } from "react-router";
import { CurrentUserContext } from "components/context/RequireAuth";
import { useListAllAdvisors } from "services/advisor";
import Breadcrumbs from "components/common/Breadcrumbs";
import Checkbox from "components/common/Checkbox";
import { MessageSquare } from "lucide-react";
import { UserAvatar } from "components/common/CurrentUserAvatar";
import { commaSeparatedEnglishList } from "../../common/utils";

const AdvisorCard = ({ advisor }: { advisor: Advisor }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const onMessage = () => {
    navigate(`/inbox/messages?recipientRefs=${advisor.ref}`);
  };
  const byline =
    commaSeparatedEnglishList(advisor.specialties) || advisor.blurbText;
  const location = `${advisor.city}, ${advisor.state}`;
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      sx={{
        borderRadius: "8px",
        border: "1px solid #ECECEC",
        background: "#FFF",
        width: "100%",
        padding: "16px 24px",
        gap: "8px",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="12px"
      >
        <Box>
          <UserAvatar user={advisor} size={48} />
        </Box>
        <Box flexGrow={1}>
          <LinkRouter to={`/advisor/${encodeURIComponent(advisor.ref)}`}>
            <Box display="flex" flexDirection={"column"} gap="4px">
              <Typography
                variant="bodyHeavy"
                color="#262626"
              >{`${advisor.firstName} ${advisor.lastName}`}</Typography>
              <Typography
                variant="body"
                color="text.secondary"
                sx={{
                  textWrap: "none",
                  textOverflow: "ellipses",
                  overflow: "hidden",
                }}
              >
                {isMobile ? location : byline}
              </Typography>
            </Box>
          </LinkRouter>
        </Box>
        <Box display="flex" gap="32px" alignItems="center" justifyContent="end">
          {!isMobile && (
            <Typography
              variant="body"
              color="#262626"
              sx={{
                textAlign: "right",
              }}
            >
              {location}
            </Typography>
          )}
          <IconButton onClick={onMessage}>
            <MessageSquare size={24} color="#198282" />
          </IconButton>
        </Box>
      </Box>
      {isMobile && byline && (
        <Typography variant="bodySmall" color="#6B6E7B">
          {byline}
        </Typography>
      )}
    </Box>
  );
};

export default () => {
  const loggedInUser = useContext(CurrentUserContext);
  const isMobile = useIsMobile();

  const [localFilter, setLocalFilter] = useState<boolean>(false);
  const { data, loading, request } = useListAllAdvisors();
  useEffect(() => {
    request({
      includeStaff: true,
    });
  }, []);

  const currentUser = data?.advisors.find((c) => c.ref === loggedInUser.ref);
  const city = currentUser && currentUser?.city;
  const state = currentUser && currentUser?.state;
  let advisors = data?.advisors || [];
  if (localFilter) {
    advisors = advisors.filter((a) => a.city === city && a.state === state);
  }
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "64px",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Advisor Directory</Typography>
            {city && (
              <FormControlLabel
                sx={{
                  // Label normally has a more negative margin to offset icon padding.
                  marginLeft: "-9px",
                }}
                label={
                  <Typography variant="body" sx={{ WebkitUserSelect: "none" }}>
                    {`${city} only`}
                  </Typography>
                }
                control={
                  <Checkbox
                    checked={localFilter}
                    onChange={() => setLocalFilter((u) => !u)}
                  />
                }
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        {!isMobile && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="end"
          >
            <Typography variant="bodySmallHeavy" color="#262626">
              Name
            </Typography>
            <Box
              display="flex"
              gap="32px"
              alignItems="center"
              justifyContent="space-between"
              mr={"32px"}
            >
              <Typography
                variant="bodySmallHeavy"
                color="#262626"
                alignSelf={"end"}
              >
                Location
              </Typography>
              <Typography variant="bodySmallHeavy" color="#262626">
                DM
              </Typography>
            </Box>
          </Box>
        )}
        {loading && <Loading />}
        {advisors.map((advisor) => (
          <AdvisorCard key={advisor.ref} advisor={advisor} />
        ))}
      </Box>
    </Box>
  );
};
