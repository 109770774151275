import { MenuItem } from "@mui/material";
import { StyledMenu } from "components/common/DropdownMenu";
import { useState, useEffect } from "react";
import Checkbox from "components/common/Checkbox";

export interface CalendarFilter {
  key: string;
  name: string;
  color?: string;
}

export interface SettingsProps {
  disabled?: boolean;
  calendarFilters?: CalendarFilter[];
  setEnabledFilters?: (filters: CalendarFilter[]) => void;
  menuOpen: boolean;
  handleClose: () => void;
  anchorPos: { top: number; left: number } | null;
}

export const CalendarSettings = ({
  disabled,
  menuOpen,
  handleClose,
  anchorPos,
  calendarFilters,
  setEnabledFilters,
}: SettingsProps) => {
  const [knownFilters, setKnownFilters] = useState<Set<string>>(
    new Set((calendarFilters || []).map((filter) => filter.key || filter.name)),
  );
  const [checkedFilters, setCheckedFilters] = useState<Set<string>>(
    new Set((calendarFilters || []).map((filter) => filter.key || filter.name)),
  );
  const hasFilters = calendarFilters?.length && setEnabledFilters;
  useEffect(() => {
    // Update known filters when calendarFilters change and add it as checked.
    (calendarFilters || []).map((filter) => {
      if (!knownFilters.has(filter.key || filter.name)) {
        setKnownFilters((v) => {
          v.add(filter.key || filter.name);
          return v;
        });
        checkedFilters.add(filter.key || filter.name);
      }
    });
  }, [calendarFilters]);

  return (
    <>
      <StyledMenu
        anchorReference="anchorPosition"
        anchorPosition={
          anchorPos ? { top: anchorPos.top, left: anchorPos.left } : undefined
        }
        open={menuOpen}
        onClose={() => {
          handleClose();
        }}
      >
        {hasFilters &&
          calendarFilters.map((filter) => (
            <MenuItem
              key={filter.key || filter.name}
              disabled={disabled}
              onClick={() => {
                const newFilters = new Set(checkedFilters);
                if (newFilters.has(filter.key)) {
                  newFilters.delete(filter.key);
                } else {
                  newFilters.add(filter.key);
                }
                setCheckedFilters(newFilters);
                setEnabledFilters(
                  calendarFilters.filter((filter) =>
                    newFilters.has(filter.key),
                  ),
                );
              }}
              disableRipple
              sx={{
                gap: "2px",
                padding: "3px 21px 3px 12px",
              }}
            >
              <Checkbox
                bgColor={filter.color}
                disableRipple
                checked={checkedFilters.has(filter.key)}
              />
              {filter.name}
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  );
};
