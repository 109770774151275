import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CAL_CLIENT_ID!;
export const REDIRECT_URI = `https://${window.location.host}/settings/calendars/google`;

type GoogleScope = {
  name: string;
  path: string;
  helperText: string;
};

const SCOPES: GoogleScope[] = [
  {
    name: "Read only",
    path: "https://www.googleapis.com/auth/calendar.readonly",
    helperText: "Allows read-only access to your calendars and events.",
  },
  {
    name: "Limited read",
    path: "https://www.googleapis.com/auth/calendar.freebusy",
    helperText: "Allows access to your calendar's free/busy information only.",
  },
  {
    name: "Full access",
    path: "https://www.googleapis.com/auth/calendar",
    helperText:
      "Allows full access to manage your calendars and events, including creating, editing, and deleting events.",
  },
];

export default ({}: {}) => {
  const [scope, setScope] = useState<GoogleScope>(SCOPES[0]);
  const handleAuthorization = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=email%20${scope.path}&access_type=offline&include_granted_scopes=true&response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&prompt=consent`;
    window.location.href = authUrl;
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{ padding: "20px", height: "100%" }}
    >
      <Box display="flex">
        <FormControl sx={{ m: 1, width: "100%" }}>
          <InputLabel>Access Level</InputLabel>
          <Select
            defaultValue={0}
            onChange={(e) => setScope(SCOPES[e.target.value as number])}
          >
            {SCOPES.map((scope, ind) => (
              <MenuItem key={scope.path} value={ind}>
                {scope.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography sx={{ marginLeft: "8px" }} variant="body">
        {scope.helperText}
      </Typography>
      <Box display="flex">
        <Button
          fullWidth
          color="success"
          onClick={handleAuthorization}
          style={{ marginTop: "25px" }}
        >
          Connect
        </Button>
      </Box>
      <FormHelperText sx={{ marginTop: "10px" }}>
        Faye's use and transfer to any other app of information received from
        Google APIs will adhere to{" "}
        <Link
          target="_new"
          href={
            "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
          }
        >
          Google API Services User Data Policy
        </Link>
        , including the Limited Use requirements.
      </FormHelperText>
    </Box>
  );
};
