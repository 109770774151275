import { useUpdateScheduledTextMessage } from "../../services/textMessage";
import { UpdateScheduledTextMessageRequest } from "../../protogen/text_message_service_pb";

export type ScheduleMessageReturnType = {
  handleSendNow: () => Promise<void>;
  handleDelete: () => Promise<void>;
  handleUpdateSchedule: (d: Date) => Promise<void>;
  loading: boolean;
};
export default ({
  messageRef,
  onSent,
  onDeleted,
}: {
  messageRef: string;
  onSent: () => void;
  // onUpdate?: () => void;
  onDeleted: () => void;
}): ScheduleMessageReturnType => {
  const { request, loading } = useUpdateScheduledTextMessage();
  const defaultArgs = () => ({
    messageRef: messageRef,
    updatedEndSecUTC: BigInt(0),
    sendMessageNow: false,
    deleteMessage: false,
  });
  const handleSendNow = async () => {
    await request(
      new UpdateScheduledTextMessageRequest({
        ...defaultArgs(),
        sendMessageNow: true,
      }),
    );
    onSent();
  };
  const handleDelete = async () => {
    await request(
      new UpdateScheduledTextMessageRequest({
        ...defaultArgs(),
        deleteMessage: true,
      }),
    );
    onDeleted();
  };
  const handleUpdateSchedule = async (d: Date) => {
    await request(
      new UpdateScheduledTextMessageRequest({
        ...defaultArgs(),
        updatedEndSecUTC: BigInt(d.getTime() / 1000),
      }),
    );
  };
  return {
    handleSendNow,
    handleDelete,
    handleUpdateSchedule,
    loading,
  };
};
