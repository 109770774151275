import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import Loading from "components/common/Loading";
import { Family, FamilyStatus } from "protogen/advisors_service_pb";
import { Member } from "protogen/common_pb";
import AddFamilyDialog from "components/family/AddFamilyDialog";
import { useAdminListAllAdvisors, useListAllFamilies } from "services/admin";
import { useUpdateActivitySubscription } from "services/advisor";
import LinkRouter from "components/navigation/LinkRouter";
import useIsMobile from "components/hooks/useIsMobile";
import Breadcrumbs from "components/common/Breadcrumbs";
import { Map as MapIcon, Plus, UserRoundCog } from "lucide-react";

import { commaSeparatedEnglishList } from "../../common/utils";
import { CurrentUserContext } from "components/context/RequireAuth";
import CheckedDropDown from "components/common/CheckedDropDown";
import { AccountStub } from "protogen/common_pb";
import { useNavigate } from "react-router";
import { getFormattedLocation, getLatLng } from "components/family/utils";
import MapList from "components/common/maps/MapList";
import { Treatment } from "components/common/maps/FMarker";
import SearchBar from "components/forum/SearchBar";
import { findNearestDMA } from "components/common/maps/dmas";
import { useSearchParams } from "react-router-dom";

type FamilyRowProps = {
  family: Family;
  subscribed: boolean;
  advisor: AccountStub | null;
};

const FamilyCard = ({ family, subscribed, advisor }: FamilyRowProps) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext);
  const [isSubscribed, setIsSubscribed] = useState(subscribed);
  const { request, loading } = useUpdateActivitySubscription((r) => {
    if (r.subscription) {
      setIsSubscribed(r.subscription.pushEnabled);
    }
  });
  const onSubChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    request({
      entityType: "family",
      entityRef: family.ref,
      updatePush: true,
      pushEnabled: event.target.checked,
    });
  };

  const onEdit = () => {
    navigate(`/families/${encodeURIComponent(family.ref)}#tab-4`);
  };
  const byline = (
    <Box>
      {advisor && (
        <Typography
          variant="body"
          onClick={() =>
            navigate(`/advisor/${encodeURIComponent(advisor.ref)}`)
          }
        >
          {advisor.displayName}
        </Typography>
      )}
    </Box>
  );
  const location = `${getFormattedLocation(family.address)}`;
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      sx={{
        borderRadius: "8px",
        border: "1px solid #ECECEC",
        background: "#FFF",
        width: "100%",
        padding: "16px 24px",
        gap: "8px",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="12px"
      >
        <Box flexGrow={1}>
          <LinkRouter to={`/families/${encodeURIComponent(family.ref)}`}>
            <Box display="flex" flexDirection={"column"} gap="4px">
              <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                gap="4px"
              >
                <Typography
                  variant="bodyHeavy"
                  color="#262626"
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  {family.name}
                </Typography>
                {!isMobile && <> &middot; </>}
                <Typography
                  sx={{
                    ...(!isMobile && {
                      textWrap: "nowrap",
                      textOverflow: "ellipsis",
                      overflowX: "hidden",
                    }),
                  }}
                  color="text.secondary"
                >
                  {commaSeparatedEnglishList(
                    family.familyMembers.map((m: Member) => m.displayName),
                  )}
                </Typography>
              </Box>
              <Typography
                variant="body"
                color="text.secondary"
                sx={{
                  textWrap: "none",
                  textOverflow: "ellipses",
                  overflow: "hidden",
                }}
              >
                {isMobile ? location : byline}
              </Typography>
            </Box>
          </LinkRouter>
        </Box>
        <Box display="flex" gap="32px" alignItems="center" justifyContent="end">
          {!isMobile && (
            <Typography
              variant="body"
              color="#262626"
              sx={{
                textAlign: "right",
              }}
            >
              {location}
            </Typography>
          )}
          <Box display="flex" flexDirection="column">
            <IconButton onClick={onEdit}>
              <UserRoundCog size={24} color="#198282" />
            </IconButton>
            <FormControlLabel
              control={
                <Switch
                  checked={isSubscribed}
                  disabled={loading || currentUser.ref === family.advisorRef}
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                  onChange={onSubChange}
                />
              }
              label="Following"
              labelPlacement="bottom"
            />
          </Box>
        </Box>
      </Box>
      {isMobile && byline && (
        <Typography variant="bodySmall" color="#6B6E7B">
          {byline}
        </Typography>
      )}
    </Box>
  );
};

const statusOptions = () => {
  return [
    {
      label: "Any status",
      value: "",
      mutuallyExclusive: true,
    },
    {
      label: "Active",
      value: FamilyStatus.ACTIVE.toString(),
      mutuallyExclusive: true,
    },
    {
      label: "Prospect",
      value: FamilyStatus.PROSPECT.toString(),
      mutuallyExclusive: true,
    },
    {
      label: "Demo",
      value: FamilyStatus.DEMO.toString(),
      mutuallyExclusive: true,
    },
    {
      label: "Test",
      value: FamilyStatus.TEST.toString(),
      mutuallyExclusive: true,
    },
    {
      label: "Deactivated",
      value: FamilyStatus.DEACTIVATED.toString(),
      mutuallyExclusive: true,
    },
    {
      label: "Deactivated Prospect",
      value: FamilyStatus.DEACTIVATED_PROSPECT.toString(),
      mutuallyExclusive: true,
    },
    {
      label: "Pre-Activation",
      value: FamilyStatus.PREACTIVATION.toString(),
      mutuallyExclusive: true,
    },
  ];
};

const locationOptions = (inputLocations: string[]) => {
  const locations = new Set(inputLocations);
  const options = Array.from(locations)
    .sort()
    .map((location: string) => ({
      label: location,
      value: location,
      mutuallyExclusive: true,
    }));
  options.unshift({
    label: "Any market",
    value: "",
    mutuallyExclusive: true,
  });
  return options;
};

const dmaMappings = (
  families: Family[],
): Map<string, { name: string; code: number }> => {
  const markets = new Map<string, { name: string; code: number }>();
  families.forEach((f) => {
    const latlng = getLatLng(f.address);
    if (!latlng || !(latlng.lat && latlng.lng)) return;
    const dma = findNearestDMA(latlng.lat, latlng.lng);
    markets.set(f.ref, {
      name: dma.dma_name,
      code: dma.dma_code,
    });
  });
  return markets;
};

const advisorOptions = (advisors: AccountStub[]) => {
  const options = advisors
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
    .map((advisor: AccountStub) => ({
      label: advisor.displayName,
      value: advisor.ref,
      mutuallyExclusive: true,
    }));
  options.unshift({
    label: "Any advisor",
    value: "",
    mutuallyExclusive: true,
  });
  return options;
};

type PersistedFilters = {
  status: string;
  setStatus: (s: string) => void;
  advisor: string;
  setAdvisor: (s: string) => void;
  location: string;
  setLocation: (s: string) => void;
  query: string;
  setQuery: (s: string) => void;
};
const usePersistedFilters = ({}: {}): PersistedFilters => {
  const initialParams = new URLSearchParams(window.location.search);
  const [, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState<string>(
    initialParams.get("status") || "",
  );
  const [advisor, setAdvisor] = useState<string>(
    initialParams.get("advisor") || "",
  );
  const [location, setLocation] = useState<string>(
    initialParams.get("location") || "",
  );
  const [query, setQuery] = useState<string>(initialParams.get("query") || "");
  useEffect(() => {
    setSearchParams({
      ...(status ? { status } : {}),
      ...(advisor ? { advisor } : {}),
      ...(location ? { location } : {}),
      ...(query ? { query } : {}),
    });
  }, [status, advisor, location, query]);
  return {
    status,
    setStatus,
    advisor,
    setAdvisor,
    location,
    setLocation,
    query,
    setQuery,
  };
};

const FamilyFilters = ({
  families,
  advisors,
  onFiltered,
  extra = null,
  filters,
}: {
  families: Family[];
  advisors: AccountStub[];
  onFiltered: (f: Family[]) => void;
  extra?: ReactNode | ReactNode[];
  filters: PersistedFilters;
}) => {
  const isMobile = useIsMobile();
  const familyDMAMapping = useMemo(() => dmaMappings(families), [families]);
  const dmaOptions = locationOptions(
    Array.from(familyDMAMapping.values()).map((e) => e.name),
  );
  const runFilters = () => {
    if (!families.length) return;
    const cleanQuery = filters.query.toLowerCase().trim();
    const filteredFamilies = families
      .filter((f) =>
        filters.status ? f.status === parseInt(filters.status) : true,
      )
      .filter((f) =>
        filters.advisor ? f.advisorRef === filters.advisor : true,
      )
      .filter((f) =>
        filters.location
          ? (familyDMAMapping.has(f.ref)
              ? familyDMAMapping.get(f.ref)?.name
              : "N/A") === filters.location
          : true,
      )
      .filter((f) =>
        cleanQuery
          ? f.name.toLowerCase().includes(cleanQuery) ||
            getFormattedLocation(f.address)
              .toLowerCase()
              .includes(cleanQuery) ||
            f.familyMembers.some(
              (fm: Member) =>
                fm.firstName.toLowerCase().includes(cleanQuery) ||
                fm.lastName.toLowerCase().includes(cleanQuery),
            )
          : true,
      );
    onFiltered(filteredFamilies);
  };
  useEffect(() => {
    runFilters();
  }, [
    families,
    filters.status,
    filters.advisor,
    filters.location,
    filters.query,
  ]);
  return (
    <Box
      display={"flex"}
      flexDirection="row"
      sx={{ overflowX: "auto" }}
      justifyContent={!isMobile ? "end" : undefined}
    >
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent="end"
        gap="10px"
        mb={isMobile ? "20px" : undefined}
      >
        <CheckedDropDown
          disabled={false}
          radio={true}
          initial={[filters.status]}
          options={statusOptions()}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            (filters.status &&
              statusOptions().find((s) => s.value === filters.status)?.label) ||
            "Status"
          }
          onChange={(f) => f.length === 1 && filters.setStatus(f[0].value)}
        />
        <CheckedDropDown
          disabled={false}
          radio={true}
          initial={[filters.advisor]}
          options={advisorOptions(advisors)}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            (filters.advisor &&
              advisors.find((a) => a.ref === filters.advisor)?.displayName) ||
            "Advisor"
          }
          onChange={(f) => f.length === 1 && filters.setAdvisor(f[0].value)}
        />
        <CheckedDropDown
          disabled={false}
          radio={true}
          initial={[filters.location]}
          options={dmaOptions}
          sx={{ width: isMobile ? "50%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            (filters.location &&
              dmaOptions.find((l) => l.value === filters.location)?.label) ||
            "Market"
          }
          onChange={(f) => f.length === 1 && filters.setLocation(f[0].value)}
        />
        {extra}
      </Box>
    </Box>
  );
};

const getStateColor = (status: FamilyStatus) => {
  switch (status) {
    case FamilyStatus.ACTIVE:
      return "#198282";
    case FamilyStatus.PROSPECT:
      return "#FFA500";
    case FamilyStatus.DEACTIVATED:
      return "#FF0000";
    case FamilyStatus.DEMO:
    case FamilyStatus.TEST:
    default:
      return "#9E9E9E";
  }
};

const getDistinctAdvisors = (advisors: AccountStub[]) => {
  const distinctAdvisors = [];
  const seen = new Set();
  for (const advisor of advisors) {
    if (!seen.has(advisor.ref)) {
      seen.add(advisor.ref);
      distinctAdvisors.push(advisor);
    }
  }
  return distinctAdvisors;
};

export default () => {
  const isMobile = useIsMobile();
  const { loading, data, request } = useListAllFamilies();
  const [mapOpen, setMapOpen] = useState(false);
  const [addFamilyModalOpen, setAddFamilyModalOpen] = useState(false);
  const [filteredFamilies, setFilteredFamilies] = useState<Family[]>([]);
  const filters = usePersistedFilters({});
  const { request: advisorsRequest, data: advisorsData } =
    useAdminListAllAdvisors();
  useEffect(() => {
    request();
    advisorsRequest();
  }, []);
  const subscriptions = new Set(data?.subscribedFamilyRefs || []);
  const familyPermissions = new Map(
    (data?.familyPermissions || []).map((p) => [p.familyRef, p]),
  );
  const distinctAdvisors = getDistinctAdvisors(
    (data?.familyPermissions || []).map((p) => p.primaryAdvisor),
  );

  let resultingFamilies = filteredFamilies.sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const mappableFamilies = resultingFamilies.filter(
    (f) => !!getFormattedLocation(f.address),
  );
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "64px",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Family Directory</Typography>
            <Box
              display="flex"
              flexDirection="row"
              gap="8px"
              alignItems={"center"}
            >
              <SearchBar
                initialQuery={filters.query}
                expand={false}
                autofocus={true}
                onQuery={filters.setQuery}
                onKeyPress={true}
              />
              <Button
                variant="outlined"
                onClick={() => setAddFamilyModalOpen(true)}
                sx={{
                  height: "36px",
                  minWidth: "unset",
                  padding: "0 4px",
                }}
              >
                <Plus />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <FamilyFilters
        families={data?.families || []}
        advisors={distinctAdvisors}
        onFiltered={(f) => setFilteredFamilies(f)}
        filters={filters}
        extra={
          <Button
            onClick={() => mappableFamilies.length && setMapOpen(true)}
            sx={{
              borderRadius: "100px",
              border: "2px solid #EAEBEC",
              color: "#616161",
              backgroundColor: "unset",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#EAEBEC",
              },
            }}
            startIcon={<MapIcon size={20} stroke="#8E9598" />}
          >
            Mapped
          </Button>
        }
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        {!isMobile && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="end"
          >
            <Box
              display="flex"
              gap="32px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="bodySmallHeavy" color="#262626">
                Name
              </Typography>
            </Box>
            <Box
              display="flex"
              gap="32px"
              alignItems="center"
              justifyContent="space-between"
              mr={"32px"}
            >
              <Typography
                variant="bodySmallHeavy"
                color="#262626"
                alignSelf={"end"}
                mr={"54px"}
              >
                Location
              </Typography>
              <Typography variant="bodySmallHeavy" color="#262626">
                Edit
              </Typography>
            </Box>
          </Box>
        )}
        {loading && <Loading />}
        {resultingFamilies.map((family) => (
          <FamilyCard
            key={family.ref}
            family={family}
            subscribed={subscriptions.has(family.ref)}
            advisor={familyPermissions.get(family.ref)?.primaryAdvisor}
          />
        ))}
      </Box>
      <AddFamilyDialog
        selectAdvisor={true}
        open={addFamilyModalOpen}
        onClose={() => setAddFamilyModalOpen(false)}
      />
      <MapList
        open={mapOpen && mappableFamilies.length > 0}
        onClose={() => setMapOpen(false)}
        locations={[
          ...mappableFamilies.map((f) => ({
            title: f.name,
            link: `/families/${encodeURIComponent(f.ref)}`,
            content: getFormattedLocation(f.address),
            color: getStateColor(f.status),
            address: getFormattedLocation(f.address),
            ...getLatLng(f.address),
          })),
          ...(advisorsData?.advisors || []).map((a) => ({
            title: a.displayName,
            link: `/advisor/${encodeURIComponent(a.ref)}`,
            content: getFormattedLocation(a.address),
            color: "#165BAA",
            treatment: Treatment.PersistentDot,
            bounding: false,
            address: getFormattedLocation(a.address),
            ...getLatLng(a.address),
          })),
        ]}
      />
    </Box>
  );
};
