const SALT = 94129;
// Function to convert a base64 string to a user-friendly base36 string with a reversible transformation
const base64ToBytes = (b64: string): Uint8Array => {
  const binaryString = atob(b64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

// Convert Uint8Array to hex string
const bytesToHex = (bytes: Uint8Array): string => {
  return Array.from(bytes)
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
};

// Function to convert a base64 string to a user-friendly base36 string with a reversible transformation
const base64ToExactBase36 = (b64: string, salt: number = 12345): string => {
  // Decode the base64 string to bytes
  const bytes = base64ToBytes(b64);
  // Convert bytes to hex, then to BigInt
  const int = BigInt("0x" + bytesToHex(bytes));
  // Add the salt
  const saltedInt = int + BigInt(salt);
  // Convert to base36
  return saltedInt.toString(36);
};

export const userReferralLink = (ref: string) =>
  `https://findfaye.com#code=${encodeURIComponent(
    base64ToExactBase36(ref, SALT),
  )}`;
// // Example decoding:
// // Convert base36 string to BigInt
// const saltedInt = BigInt(parseInt("2e7a", 36));
// // Subtract the salt
// const exactOriginal = Number(saltedInt - BigInt(SALT));
// console.log("Original int:", exactOriginal);
