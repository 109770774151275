import { PlainMessage } from "@bufbuild/protobuf";
import { Advisor, AdvisorStatus } from "protogen/common_pb";
import { UpdateAdvisorRequest } from "protogen/advisors_service_pb";
export type EphemeralAdvisor = {
  ref?: string;
  firstName?: string;
  lastName?: string;
  personalEmail?: string;
  personalPhone?: string;
  username?: string;
  advisorPhone?: string;
  sendIntroEmail?: boolean;
  externalLinks?: ExternalLink[];
  isProfilePublic?: boolean;
  shouldUpdateProfileImage?: boolean;
  timezone?: string;
  specialties?: string[];
  status?: AdvisorStatus;
  password?: string;
  address?: string;
};

export type FormErrors = {
  [key: string]: string;
};

export type ExternalLink = {
  name: string;
  url: string;
};
export const updates = (
  advisor: Advisor,
  formData: EphemeralAdvisor,
): PlainMessage<UpdateAdvisorRequest> => {
  return {
    advisorRef: advisor.ref,
    updatedFirstName:
      advisor.firstName === formData.firstName ? "" : formData.firstName!,
    updatedLastName:
      advisor.lastName === formData.lastName ? "" : formData.lastName!,
    updatedPersonalEmail:
      advisor.personalEmail === formData.personalEmail
        ? ""
        : formData.personalEmail!,
    updatedPersonalPhone:
      advisor.personalPhone === formData.personalPhone
        ? ""
        : formData.personalPhone!,
    isProfilePublic:
      formData.isProfilePublic ?? advisor?.isProfilePublic ?? false,
    shouldUpdateProfileImage: formData.shouldUpdateProfileImage!,
    externalLinks: formData.externalLinks!,
    updatedTimezone:
      advisor.timezone == formData.timezone ? "" : formData.timezone!,
    updatedStatus:
      advisor.status === formData.status
        ? AdvisorStatus.NOT_SET
        : formData.status!,
    updatedSpecialties: compareArrayEqualityShallow(
      advisor.specialties,
      formData.specialties || [],
    )
      ? []
      : formData.specialties!,
    updatedAddress:
      advisor.address === formData.address ? "" : formData.address!,
    shouldUpdateEmailNotifications: false,
    emailNotificationsEnabled: false,
    shouldUpdateMetadata: false,
    updatedMetadata: undefined,
  };
};

const compareArrayEqualityShallow = (a: string[], b: string[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }
  return a.every((val, index) => val === b[index]);
};
