import { PhoneCall, PhoneCall_State } from "../../protogen/conversation_pb";
import useIsVisible from "../hooks/useIsVisible";
import { useEffect, useRef, useState } from "react";
import { useDeepgramLiveTranscriptions } from "../../services/phone";
import {
  LiveTranscriptionsResponse,
  TranscriptionSegment,
} from "../../protogen/phone_service_pb";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

type TranscriptionState = {
  cursorMs: number;
  maxTimestampProcessed: number;
  transcriptionComplete: boolean;
  segments: Array<TranscriptionSegment>;
  callCount: number;
  streamExists: boolean;
  transcriptionFailed: boolean;
};

const _defaultState = () => {
  return {
    cursorMs: 0,
    maxTimestampProcessed: 0,
    transcriptionComplete: false,
    segments: [],
    callCount: 0,
    streamExists: false,
    transcriptionFailed: false,
  };
};

type Output = {
  segments: TranscriptionSegment[];
  complete: boolean;
  streamStarted: boolean;
  transcriptionFailed: boolean;
};

export default (call: PhoneCall): Output => {
  const { request } = useDeepgramLiveTranscriptions();
  const { isVisible } = useIsVisible({});

  const [state, setState] = useState<TranscriptionState>(_defaultState());

  const unmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  useEffect(() => {
    let effectStale = false; // Don't forget ; on the line before self-invoking functions
    const looper = async () => {
      const nowSec = () => Math.floor(Date.now() / 1000);
      const maxWaitTime = 30000;
      let backoffCounter = 0;
      let waitTime = 500;
      let startTimeOffset = 0;
      let nullCounter: number | null = nowSec();
      let completed = false;
      let failed = false;
      while (!completed && !failed) {
        let resultData: LiveTranscriptionsResponse | null = null;
        if (isVisible) {
          resultData = await request({
            callRef: call.ref,
            track: "inbound",
            maxTimestampProcessed: state.maxTimestampProcessed,
            startTimeOffset: startTimeOffset,
          });
        }
        /* Component has been unmounted. Stop to avoid
           "Warning: Can't perform a React state update on an unmounted component." */
        if (unmountedRef.current) return;

        /* Component has re-rendered with different someId value
           Stop to avoid updating state with stale response */
        if (effectStale) return;

        // ... update component state
        if (resultData !== null) {
          nullCounter = null; // unset
          const newTimeoffset = resultData.segments.reduce(
            (offset, segment) => {
              return segment.stable ? segment.startSec : offset;
            },
            startTimeOffset,
          );
          if (newTimeoffset > startTimeOffset) {
            startTimeOffset = newTimeoffset;
            backoffCounter = 0;
            setState((prevState) => {
              if (resultData !== null) {
                completed = resultData.complete;
                const segments = [
                  ...prevState.segments,
                  ...resultData.segments,
                ];
                const filteredSegments = segments.filter((segment) => {
                  return segment.stable || segment.startSec > startTimeOffset;
                });
                return {
                  cursorMs: resultData.cursorMs,
                  maxTimestampProcessed: resultData.maxTimestampProcessed,
                  transcriptionComplete: resultData.complete,
                  segments: filteredSegments,
                  callCount: state.callCount + 1,
                  streamExists: true,
                  transcriptionFailed: false,
                };
              }
              return prevState;
            });
          } else {
            backoffCounter++;
          }
        } else if (call.state === PhoneCall_State.FAILED) {
          failed = true;
          setState({ ...state, transcriptionFailed: true });
          return;
        } else if (nullCounter && nowSec() - nullCounter > 60) {
          // If we didn't get any data for a minute, stop counting.
          failed = true;
          setState({ ...state, transcriptionFailed: true });
          return;
        }
        await sleep(Math.min(waitTime * 2 ** backoffCounter, maxWaitTime));
      }
    };
    if (call.transcriptionEnabled) {
      looper();
    }
    return () => {
      effectStale = true;
    };
  }, [call.ref, call.transcriptionEnabled, isVisible]);

  return {
    segments: state.segments,
    complete: state.transcriptionComplete,
    streamStarted: state.streamExists,
    transcriptionFailed: state.transcriptionFailed,
  };
};
