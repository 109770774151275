import { useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import { Box, Button, TextareaAutosize } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { Family } from "../family/types";
import { ArrowRight, Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface Props {
  family: Family;
  backgroundColor?: string;
  buttonPlacement?: "collapse" | "visible";
}

export default ({
  family,
  backgroundColor = "#FFF",
  buttonPlacement = "collapse",
}: Props) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [taskTitle, setTaskTitle] = useState<string>("");
  const handleCreateTask = () => {
    if (taskTitle) {
      navigate("/tasks/add", {
        state: { taskTitle, family: family },
      });
    }
  };
  return (
    <Box display="flex" flexDirection={"row"} alignItems="center" gap="16px">
      <div
        style={{
          borderRadius: "30px",
          border: "1px solid #D4D4D4",
          background: "#FFF",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexGrow: 1,
          padding: "11px 0px 12px 11px",
          backgroundColor: backgroundColor,
        }}
      >
        <TextareaAutosize
          minRows={1}
          defaultValue={taskTitle}
          onChange={(event) => setTaskTitle(event.target.value)}
          style={{
            boxSizing: "border-box",
            border: "none",
            outline: "none",
            resize: "none",
            fontFamily: "AlbertSans",
            fontSize: "16px",
            fontWeight: "500",
            fontStyle: "normal",
            color: "#3D3D3D",
            marginLeft: "10px",
            marginRight: "10px",
            flex: 1,
            transition: "width 0.5s",
            backgroundColor: backgroundColor,
          }}
          placeholder="Add a new task"
          onKeyDown={(event) => {
            // Allow shift for newlines.
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              handleCreateTask();
            }
          }}
        />
        {buttonPlacement === "visible" && (
          <Button
            disabled={!taskTitle}
            onClick={handleCreateTask}
            sx={{
              padding: "0px",
              height: "32px",
              width: "32px",
              minWidth: "32px",
              marginRight: "12px",
            }}
          >
            <ArrowRight />
          </Button>
        )}
      </div>
      {buttonPlacement === "collapse" && (
        <Collapse orientation={"horizontal"} in={!!taskTitle}>
          <Button
            onClick={handleCreateTask}
            sx={{
              textWrap: "nowrap",
              padding: isMobile ? "0px" : "8px 24px",
              overflow: "hidden",
            }}
          >
            {isMobile ? <Plus /> : "Create task"}
          </Button>
        </Collapse>
      )}
    </Box>
  );
};
