import { green, orange, red } from "@mui/material/colors";
import { relativeTime } from "common/utils";
import { Circle } from "lucide-react";
import { ReactNode } from "react";

export const calendarSyncStatus = (
  lastAccessedSec: number,
): [ReactNode, string] => {
  let byline = "";
  let status = "";
  if (Number(lastAccessedSec) === 0) {
    byline = "Calendar has never been synced";
    status = orange[500];
  } else if (
    Number(lastAccessedSec) <
    new Date().getTime() / 1000 - 60 * 60 * 25
  ) {
    byline = "Calendar has not been synced in over 24 hours";
    status = red[500];
  } else {
    byline = `Last synced ${relativeTime(
      new Date(Number(lastAccessedSec) * 1000),
    )}`;
    status = green[500];
  }
  const icon = (
    <Circle
      style={{
        height: "12px",
        width: "12px",
        fill: status,
        stroke: "none",
      }}
    />
  );
  return [icon, byline];
};
