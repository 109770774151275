import { Box, Select, MenuItem, Button } from "@mui/material";
import ActivityDivider from "../activity/ActivityDivider";
import PhoneInput from "../creation/PhoneInput";
import { FormErrors, Member } from "./types";
import ChipsField from "./ChipsField";
import { cleanPhone } from "../../common/utils";
import { checkEmail } from "../../common/userUtils";
import { TimezoneAbbreviations, Timezones } from "../common/Timezones";
import CopyTextField from "components/common/CopyTextField";
import { userReferralLink } from "components/referrals/utils";
import EditableCopyTextField from "components/common/EditableCopyTextField";

interface Props {
  title?: string;
  member: Member;
  onChange: (m: Member) => void;
  errors: FormErrors | null;
  onRemove?: () => void;
  noDivider?: boolean;
}

export default ({
  title,
  member,
  onChange,
  errors,
  onRemove,
  noDivider = false,
}: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "20px",
      }}
    >
      {!noDivider && title && <ActivityDivider text={title} />}
      <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
        <EditableCopyTextField
          error={!!errors?.firstName}
          helperText={errors?.firstName}
          label="First Name"
          fullWidth
          size="small"
          autoComplete="off"
          sx={{ marginTop: "8px", marginBottom: "4px" }}
          value={member?.firstName || ""}
          onChange={(e) => onChange({ ...member, firstName: e.target.value })}
        />
        <EditableCopyTextField
          error={!!errors?.lastName}
          helperText={errors?.lastName}
          label="Last Name"
          fullWidth
          autoComplete="off"
          size="small"
          sx={{ marginTop: "8px", marginBottom: "4px" }}
          value={member?.lastName || ""}
          onChange={(e) => onChange({ ...member, lastName: e.target.value })}
        />
      </Box>
      <PhoneInput
        handleCopy={true}
        value={member?.phone || ""}
        onChange={(phone) =>
          onChange({
            ...member,
            phone: cleanPhone(
              typeof phone === "string" ? phone : phone.target.value,
            ),
          })
        }
        error={!!errors?.phone}
        helperText={errors?.phone}
        label="Phone Number"
        fullWidth
        size="small"
        sx={{ marginTop: "8px", marginBottom: "4px" }}
        autoComplete="off"
      />
      <EditableCopyTextField
        error={!!errors?.email}
        helperText={errors?.email}
        label="Email Address"
        fullWidth
        sx={{ marginTop: "8px", marginBottom: "4px" }}
        type="email"
        size="small"
        autoComplete="off"
        value={member?.email || ""}
        onChange={(e) => onChange({ ...member, email: e.target.value })}
      />
      <ChipsField
        title="Alternate Emails"
        values={member?.altEmails || []}
        setValues={(v) => onChange({ ...member, altEmails: v })}
        validator={checkEmail}
      />
      <Select
        displayEmpty
        value={member?.timezone}
        error={!!errors?.timezone}
        onChange={(e) => onChange({ ...member, timezone: e.target.value })}
        sx={{
          ".MuiSelect-select": {
            marginTop: "0px",
          },
        }}
      >
        <MenuItem value="" disabled>
          Select timezone
        </MenuItem>
        {Timezones.map((tz) => (
          <MenuItem key={tz} value={tz}>
            {TimezoneAbbreviations[tz] || tz}
          </MenuItem>
        ))}
      </Select>
      {member.ref && (
        <CopyTextField sx={{ flex: 6 }} value={userReferralLink(member.ref)} />
      )}
      {onRemove && (
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
          }}
        >
          <Button
            variant="text"
            sx={{ color: "secondary.dark" }}
            onClick={() => {
              onRemove && onRemove();
            }}
          >
            Remove account
          </Button>
        </Box>
      )}
    </Box>
  );
};
