import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Link, Switch } from "@mui/material";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import {
  GetMemberDetailsResponse,
  NotificationSettingsValue,
} from "protogen/member_service_pb";
import FlexPage from "components/layout/FlexPage";
import AuthService from "services/auth";
import Breadcrumbs from "components/common/Breadcrumbs";
import {
  useGetMemberDetails,
  useGetBillingPortalUrl,
  useUpdateMemberNotifications,
} from "services/member";
import Loading from "components/common/Loading";
import { ArrowRight } from "lucide-react";
import { isStandaloneWebapp } from "../common/utils";

const formatDate = (dateString: string) => {
  if (!dateString) return;
  const date = new Date(dateString);
  return format(date, "MMMM do", { locale: enUS });
};

const formatBillingAmount = (amountCents: number | undefined): string => {
  if (amountCents === undefined) return "??/mo";
  return `$${(amountCents / 100).toString()}/mo`;
};

const Notifications = ({
  memberDetails,
  disabled,
}: {
  memberDetails: GetMemberDetailsResponse | null;
  disabled: boolean;
}) => {
  const { request, loading } = useUpdateMemberNotifications();
  const _disabled = disabled || loading;
  const onChangeEmail = async (val: boolean) => {
    if (_disabled) return;
    await request({
      settings: {
        email: val
          ? NotificationSettingsValue.ENABLED
          : NotificationSettingsValue.DISABLED,
        push: NotificationSettingsValue.UNSPECIFIED,
      },
    });
  };
  const onChangePush = async (val: boolean) => {
    if (_disabled) return;
    await request({
      settings: {
        push: val
          ? NotificationSettingsValue.ENABLED
          : NotificationSettingsValue.DISABLED,
        email: NotificationSettingsValue.UNSPECIFIED,
      },
    });
  };
  return (
    <Box>
      <Box>
        <Typography variant="h3" sx={{ paddingBottom: "16px" }}>
          Notifications
        </Typography>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body">Receive updates by email</Typography>
            <Switch
              disabled={
                _disabled ||
                memberDetails?.notificationSettings?.email ===
                  NotificationSettingsValue.UNSPECIFIED
              }
              defaultChecked={
                memberDetails?.notificationSettings?.email ===
                NotificationSettingsValue.ENABLED
              }
              onChange={(e) => onChangeEmail(e.target.checked)}
            />
          </Box>
          {isStandaloneWebapp() && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body">
                Receive push notifications for updates
              </Typography>
              <Switch
                disabled={
                  _disabled ||
                  memberDetails?.notificationSettings?.push ===
                    NotificationSettingsValue.UNSPECIFIED
                }
                defaultChecked={
                  memberDetails?.notificationSettings?.push ===
                  NotificationSettingsValue.ENABLED
                }
                onChange={(e) => onChangePush(e.target.checked)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const PlanDetails = ({
  memberDetails,
  loading,
}: {
  memberDetails: GetMemberDetailsResponse | null;
  loading: boolean;
}) => {
  const { request: billingUrlRequest } = useGetBillingPortalUrl();
  const onBillingRedirect = async () => {
    // Open a blank tab immediately upon user interaction, and then change that tab’s
    // location once we have the URL. This maintains the synchronous nature of the initial action:
    const newWindow = window.open("", "_blank");
    const result = await billingUrlRequest();
    const url = result?.url;
    if (newWindow && url) {
      newWindow.location.href = url;
    } else if (newWindow) {
      // If no URL is available, close the tab or show some error message
      newWindow.close();
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      {loading ? (
        <Loading />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box>
            <Typography variant="h3">Plan details</Typography>
          </Box>
          <Box
            sx={{
              border: "1px solid #D4D4D4",
              borderRadius: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                padding: "16px 20px",
              }}
            >
              <Typography variant="h4" color="#3D3D3D">
                {memberDetails?.family?.billingInfo?.product?.name}
              </Typography>
              <Typography variant="body" color="#3D3D3D">
                {formatBillingAmount(
                  memberDetails?.family?.billingInfo?.product?.amountCents,
                )}
              </Typography>
              <Typography variant="body" color="#3D3D3D">
                {memberDetails?.family?.billingInfo?.product?.description || ""}
              </Typography>
              <Typography variant="body" color="#3D3D3D">
                Renews{" "}
                {formatDate(
                  memberDetails?.planUtilization?.periodEndDate || "",
                )}
              </Typography>
            </Box>
            <Box sx={{}}>
              <Link
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "18px 20px 16px",
                  borderTop: "1px solid #D4D4D4",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onBillingRedirect();
                }}
              >
                <Typography variant="bodyHeavy">
                  Update billing information
                </Typography>
                <ArrowRight width="20" color="#6B6E7B" />
              </Link>
            </Box>
          </Box>
          <Box>
            <Typography variant="body">
              If you'd like to update your subscription, contact your Advisor
              directly. For any platform questions or concerns, contact{" "}
              <Link href="mailto:hi@findfaye.com">Faye support</Link>.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default () => {
  const navigate = useNavigate();
  const { request, data, loading, error } = useGetMemberDetails();

  const handleLogout = async () => {
    await AuthService.logout();
    navigate("/");
  };

  useEffect(() => {
    request();
  }, []);

  return (
    <FlexPage
      leftAligned
      fullHeight
      sx={{
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Breadcrumbs
          breadcrumbs={[{ name: "Home", link: "/" }, { name: "Settings" }]}
        />
        {data ? (
          <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
            <Box sx={{}}>
              <Typography variant="display">Settings</Typography>
            </Box>
            <PlanDetails memberDetails={data} loading={loading || !!error} />
            <Notifications memberDetails={data} disabled={loading} />
          </Box>
        ) : (
          <Loading />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "6px",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleLogout}
          sx={{ alignSelf: "flex-start" }}
        >
          Log out
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "end",
          }}
        >
          <Link
            href="https://www.findfaye.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Link
            href="https://www.findfaye.com/client-terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Client Terms of Service
          </Link>
        </Box>
      </Box>
    </FlexPage>
  );
};
