import { Checkbox, CheckboxProps } from "@mui/material";
import { CSSProperties } from "react";

type IconProps = {
  style?: CSSProperties;
  color?: string;
};

const UncheckedIcon = ({ style, color }: IconProps) => {
  return (
    <svg
      style={style}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="22" height="22" rx="3.5" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="22"
        height="22"
        rx="3.5"
        stroke={color || "#D4D4D4"}
      />
    </svg>
  );
};

const CheckedIcon = ({ style, color }: IconProps) => {
  return (
    <svg
      style={style}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="22"
        height="22"
        rx="3.5"
        fill={color || "white"}
      />
      <rect
        x="0.5"
        y="0.5"
        width="22"
        height="22"
        rx="3.5"
        stroke={color || "#D4D4D4"}
      />
      <path
        d="M4.78262 13.4348L8.60871 17.2609L18.1739 7.69568"
        stroke={color ? "white" : "#198282"}
        strokeWidth="2.86957"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ({
  size,
  bgColor,
  ...otherProps
}: CheckboxProps & { bgColor?: string }) => {
  return (
    <Checkbox
      icon={
        <UncheckedIcon
          color={bgColor}
          style={{
            ...(otherProps.disabled ? { opacity: 0.4 } : {}),
            ...(size === "small" ? { height: "16px", width: "16px" } : {}),
          }}
        />
      }
      checkedIcon={
        <CheckedIcon
          color={bgColor}
          style={{
            ...(otherProps.disabled ? { opacity: 0.4 } : {}),
            ...(size === "small" ? { height: "16px", width: "16px" } : {}),
          }}
        />
      }
      {...otherProps}
    />
  );
};
