import {
  EventNotice,
  RecurringUnit,
  EventNotice_NoticeType,
  CalendarEvent,
} from "protogen/calendar_pb";
import { recurringUnitString } from "../../types/calendars";
import { numberToWord, pluralize } from "../../common/utils";
import { TimezoneAbbreviations } from "../common/Timezones";

import { CalendarEvents } from "./CalendarComponent";

import { format, startOfWeek, parseISO, endOfWeek } from "date-fns";

const formatRecurringInterval = (
  unit: RecurringUnit,
  interval: number,
): string => {
  let unitStr = recurringUnitString(unit);
  // Pluralize the unit if interval is greater than 1
  let pluralUnit = interval > 1 ? pluralize(unitStr) : unitStr;
  // Format the interval (convert numbers to words for 1 and 2)
  let formattedInterval =
    interval === 1 ? "every" : `every ${numberToWord(interval)}`;
  return `${formattedInterval} ${pluralUnit}`;
};

export const recurringSubtitle = (event: EventNotice, withDate: boolean) => {
  let startStr: string;
  if (event.allDay) {
    const date = new Date(event.startDate);
    // format date as month/day
    const dayStr = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    startStr = withDate ? `All day on ${dayStr}` : "All day";
  } else {
    const start = new Date(Number(event.startSec) * 1000);
    startStr = start.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }
  return `${startStr} (${formatRecurringInterval(
    event.recurringUnit,
    event.recurringInterval,
  )})`;
};

export const subtitle = (event: EventNotice, withDate: boolean) => {
  if (event.noticeType === EventNotice_NoticeType.NoticeType_UPCOMING_TASK) {
    return "Task due";
  } else if (
    event.noticeType === EventNotice_NoticeType.NoticeType_OVERDUE_TASK
  ) {
    return "Task (overdue)";
  } else if (
    event.noticeType === EventNotice_NoticeType.NoticeType_RECURRING_EVENT
  ) {
    return recurringSubtitle(event, withDate);
  } else {
    return eventSubtitle(event, withDate);
  }
};

export const eventSubtitle = (
  event: EventNotice | CalendarEvent,
  withDate: boolean,
) => {
  const displayTimezone = event.displayTimezone;
  const start = new Date(Number(event.startSec) * 1000);
  const end = new Date(Number(event.endSec) * 1000);

  const localDateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const localTimeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };

  const displayDateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: displayTimezone || undefined,
  };

  const displayTimeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    timeZone: displayTimezone || undefined,
  };

  const localStartDateStr = start.toLocaleDateString("en-US", localDateOptions);
  const localStartTimeStr = start.toLocaleTimeString("en-US", localTimeOptions);
  const localEndTimeStr = end.toLocaleTimeString("en-US", localTimeOptions);

  const displayStartDateStr = displayTimezone
    ? start.toLocaleDateString("en-US", displayDateOptions)
    : "";
  const displayStartTimeStr = displayTimezone
    ? start.toLocaleTimeString("en-US", displayTimeOptions)
    : "";
  const displayEndTimeStr = displayTimezone
    ? end.toLocaleTimeString("en-US", displayTimeOptions)
    : "";

  let timezoneStr = "";
  if (displayTimezone) {
    timezoneStr = ` ${TimezoneAbbreviations[displayTimezone]}`;
  }

  const datePrefix = withDate ? `${localStartDateStr}, ` : "";
  if (event.allDay) {
    return withDate
      ? `All day on ${localStartDateStr}${timezoneStr}`
      : `All day${timezoneStr}`;
  } else if (event.endSec) {
    if (
      localStartDateStr === end.toLocaleDateString("en-US", localDateOptions)
    ) {
      // Same day
      return displayTimezone
        ? `${datePrefix}${localStartTimeStr} - ${localEndTimeStr} (${displayStartTimeStr} - ${displayEndTimeStr}${timezoneStr})`
        : `${datePrefix}${localStartTimeStr} - ${localEndTimeStr}`;
    } else {
      // Different days
      const localEndDateStr = end.toLocaleDateString("en-US", localDateOptions);
      return displayTimezone
        ? `${datePrefix}${localStartTimeStr} - ${localEndDateStr}, ${localEndTimeStr} (${displayStartDateStr}, ${displayStartTimeStr} - ${displayEndTimeStr}${timezoneStr})`
        : `${datePrefix}${localStartTimeStr} - ${localEndDateStr}, ${localEndTimeStr}`;
    }
  } else {
    return displayTimezone
      ? `${datePrefix}${localStartTimeStr} (${displayStartTimeStr}${timezoneStr})`
      : `${datePrefix}${localStartTimeStr}`;
  }
};

interface AttendeeStatus {
  status: number;
  label: string;
  labelColor: string | null;
  labelBackgroundColor: string | null;
}
const ATTENDEE_STATUS: AttendeeStatus[] = [
  {
    status: 1,
    label: "Accepted",
    labelColor: "primary.main",
    labelBackgroundColor: "#ECF4EC",
  },
  {
    status: 2,
    label: "Declined",
    labelColor: "secondary.dark",
    labelBackgroundColor: "secondary.light",
  },
  {
    status: 3,
    label: "Maybe",
    labelColor: null,
    labelBackgroundColor: null,
  },
  {
    status: 4,
    label: "Pending",
    labelColor: null,
    labelBackgroundColor: null,
  },
];
export const getAttendeeStatusLabel = (status: number) => {
  const attendeeObj = ATTENDEE_STATUS.find((p) => p.status === status);
  return attendeeObj ? attendeeObj.label : "Pending";
};
export const getAttendeeStatusLabelColor = (status: number) => {
  const attendeeObj = ATTENDEE_STATUS.find((p) => p.status === status);
  return attendeeObj ? attendeeObj.labelColor : null;
};
export const getAttendeeStatusLabelBackgroundColor = (status: number) => {
  const attendeeObj = ATTENDEE_STATUS.find((p) => p.status === status);
  return attendeeObj ? attendeeObj.labelBackgroundColor : null;
};

export const getMonthName = (date: Date) => {
  const monthIndex = date.getMonth();
  const MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return MONTH_NAMES[monthIndex];
};

export const getMonthBounds = (date: Date): [bigint, bigint] => {
  // First moment of the month
  const firstDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
    0,
    0,
    0,
    0,
  );

  // Last moment of the month
  const lastDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
    999,
  );

  // Convert to seconds since the Unix Epoch
  const firstMomentInSeconds = BigInt(
    Math.floor(firstDayOfMonth.getTime() / 1000),
  );
  const lastMomentInSeconds = BigInt(
    Math.floor(lastDayOfMonth.getTime() / 1000),
  );

  return [firstMomentInSeconds, lastMomentInSeconds];
};

export const addOneMonth = (date: Date): Date => {
  let newYear = date.getFullYear();
  let newMonth = date.getMonth() + 1;
  let newDay = date.getDate();

  if (newMonth > 11) {
    newYear++;
    newMonth = 0;
  }
  let result = new Date(newYear, newMonth, newDay);

  if (result.getMonth() !== newMonth) {
    result = new Date(newYear, newMonth + 1, 0);
  }
  return result;
};

export const subtractOneMonth = (date: Date): Date => {
  let newYear = date.getFullYear();
  let newMonth = date.getMonth() - 1;
  let newDay = date.getDate();

  if (newMonth < 0) {
    newYear--;
    newMonth = 11;
  }

  let result = new Date(newYear, newMonth, newDay);
  if (result.getMonth() !== newMonth) {
    result = new Date(newYear, newMonth + 1, 0);
  }
  return result;
};

export const defaultColor = ["#D7ECD4", "black"]; // Fallback color
const predefinedColors = [
  ["#D0F1EB", "black"],
  ["#FDD7C6", "black"],
  ["#F0DDEA", "black"],
  ["#BBDBE9", "black"],
  ["#FEF1C1", "black"],
  ["#D7ECD4", "black"],
  ["#FFB8B6", "black"],
  ["#198282", "white"],
  ["#FFC3D4", "black"],
  ["#EF7B77", "white"],
  ["#996A8A", "white"],
  ["#D56887", "white"],
  ["#6092A8", "white"],
  ["#F59F51", "white"],
  ["#468E3E", "white"],
  ["#C14743", "white"],
  // many more colors
  ["#5733FF", "white"], // Deep blue
  ["#FFC300", "black"], // Bright yellow
  ["#C70039", "white"], // Red
  ["#900C3F", "white"], // Dark purple
  ["#DAF7A6", "black"], // Light green
  ["#00FF00", "black"], // Lime green
  ["#008080", "white"], // Teal
  ["#FFA07A", "black"], // Light salmon
  ["#FF6347", "black"], // Tomato
  ["#00CED1", "white"], // Dark turquoise
  ["#4682B4", "white"], // Steel blue
  ["#708090", "black"], // Slate gray
  ["#6B8E23", "white"], // Olive drab
  ["#FFD700", "black"], // Gold
  ["#D2691E", "white"], // Chocolate
  ["#8B0000", "white"], // Dark red
  ["#FF00FF", "black"], // Magenta
  ["#7FFF00", "black"], // Chartreuse
  ["#40E0D0", "black"], // Turquoise
  ["#000080", "white"], // Navy
  ["#800080", "white"], // Purple
  ["#A52A2A", "white"], // Brown
  ["#5F9EA0", "white"], // Cadet blue
  ["#FF1493", "white"], // Deep pink
  ["#FF4500", "white"], // Orange red
  ["#20B2AA", "white"], // Light sea green
  ["#6495ED", "white"], // Cornflower blue
  ["#32CD32", "black"], // Lime green
  ["#2E8B57", "white"], // Sea green
  ["#B8860B", "black"], // Dark goldenrod
  ["#4169E1", "white"], // Royal blue
  ["#468499", "white"], // Teal-ish gray
  ["#DC143C", "white"], // Crimson
  ["#FA8072", "white"], // Salmon
  ["#8A2BE2", "white"], // Blue violet
  ["#F4A460", "black"], // Sandy brown
  ["#FFB6C1", "black"], // Light pink
];
export const createColorMap = (
  keys: string[],
): Record<string, [string, string]> => {
  const assignedColors = new Set<string>();
  return keys.reduce(
    (acc, key) => {
      if (acc[key] === undefined) {
        const nextColor =
          predefinedColors.find((color) => !assignedColors.has(color[0])) ||
          defaultColor;
        assignedColors.add(nextColor[0]);
        acc[key] = nextColor as [string, string];
      }
      return acc;
    },
    {} as Record<string, [string, string]>,
  );
};

export const getEarliestEventOfWeek = (
  events: CalendarEvents[],
  date: Date,
) => {
  const startTime = startOfWeek(date, { weekStartsOn: 0 }).getTime();
  const endTime = endOfWeek(date, { weekStartsOn: 0 }).getTime();
  return events.reduce((earliest: string, event: CalendarEvents) => {
    if (event.allDay) {
      return earliest;
    }
    const eventStart = Number(event.startSec) * 1000;
    if (eventStart > startTime && eventStart < endTime) {
      const eventTime = format(
        parseISO(new Date(eventStart).toISOString()),
        "HH:mm",
      );
      return eventTime < earliest ? eventTime : earliest;
    }
    return earliest;
  }, "07:30"); // Start at 7:30 AM if there are no earlier times
};

export const convertTimeToDate = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  const today = new Date();
  today.setHours(hours, minutes, 0, 0);
  return today;
};
