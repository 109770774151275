import { createContext, ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";

export type TaskFilter = {
  field: string;
  operator: string;
  value: any;
};

type contextType = {
  upsertFilter: (f: TaskFilter) => void;
  reset: () => void;
  filters: TaskFilter[];
};
const defaultFilters = (
  queryParams: URLSearchParams,
  initialStates: string[] | undefined,
  initialFamilies: string[] | undefined,
  initialPriorities: string[] | undefined,
): TaskFilter[] => {
  const familyParam = queryParams.get("familyRefs");
  const familyRefs: string[] | undefined = familyParam
    ? familyParam.split(",")
    : undefined;
  const filters = [];
  if (familyRefs) {
    filters.push({ field: "family", operator: "oneOf", value: familyRefs });
  }
  if (initialStates) {
    filters.push({ field: "status", operator: "oneOf", value: initialStates });
  }
  if (initialFamilies) {
    filters.push({
      field: "family",
      operator: "oneOf",
      value: initialFamilies,
    });
  }
  if (initialPriorities) {
    filters.push({
      field: "priority",
      operator: "oneOf",
      value: initialPriorities,
    });
  }
  return filters;
};

export const FilterContext = createContext<contextType>({
  upsertFilter: () => {},
  reset: () => {},
  filters: [],
});

export const AddFilterContext = ({
  children,
  initialStates,
  initialFamilies,
  initialPriorities,
}: {
  children: ReactNode;
  initialStates: string[] | undefined;
  initialFamilies?: string[] | undefined;
  initialPriorities?: string[] | undefined;
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<TaskFilter[]>(
    defaultFilters(
      queryParams,
      initialStates,
      initialFamilies,
      initialPriorities,
    ),
  );

  const upsertFilter = (f: TaskFilter) => {
    const newFilters = filters.filter((filter) => filter.field !== f.field);
    newFilters.push(f);
    setFilters(newFilters);
  };
  return (
    <FilterContext.Provider
      value={{
        filters,
        upsertFilter,
        reset: () => setFilters([]),
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
