import { ReactNode } from "react";
import GoogleCalendarFullRead from "./GoogleCalendarFullRead";
import URLCalendar from "./URLCalendar";
import InstructionStepper, {
  instructionPage,
  noImageInstructionPage,
} from "./InstructionStepper";
import GoogleCalendarOptionalRead from "./GoogleCalendarOptionalRead";
import AppleCalendar from "./AppleCalendar";
import { Breakpoint } from "@mui/material";

export abstract class ImportCalendar {
  title: string;
  name: string;
  steps?: Array<string | ReactNode>;

  constructor(name: string, title: string, steps?: Array<string | ReactNode>) {
    this.name = name;
    this.title = title;
    this.steps = steps;
  }

  getModalWidth(): Breakpoint | null {
    return null;
  }

  abstract getContent(
    onSuccess: (url: string) => void,
    onFailure: () => void,
  ): ReactNode;
}

class GoogleImportCalendar extends ImportCalendar {
  allowFreeBusy: boolean;

  constructor(allowFreeBusy: boolean = false) {
    super("google", "Google Calendar");
    this.allowFreeBusy = allowFreeBusy;
  }

  getContent() {
    return this.allowFreeBusy ? (
      <GoogleCalendarOptionalRead />
    ) : (
      <GoogleCalendarFullRead />
    );
  }

  getModalWidth(): Breakpoint | null {
    return "sm";
  }
}

class AppleImportCalendar extends ImportCalendar {
  constructor() {
    super("apple", "Apple Calendar");
  }

  getContent(onSuccess: (url: string) => void, onFailure: () => void) {
    return (
      <AppleCalendar
        instructions={
          <InstructionStepper
            steps={[
              instructionPage(
                "/assets/images/calendars/imports/examples/apple/apple-import-step1.png",
                "horizontal",
                [
                  {
                    number: 1,
                    content: (
                      <>
                        In Apple Calendar,{" "}
                        <b>click the Calendar icon button </b>
                        in the top-left corner to view the list of your
                        calendars.
                      </>
                    ),
                  },
                  {
                    number: 2,
                    content: (
                      <>
                        Locate the calendar you want to share, then right-click
                        on it.
                      </>
                    ),
                  },
                  {
                    number: 3,
                    content: (
                      <>
                        Select <b>Sharing Settings</b>.
                      </>
                    ),
                  },
                ],
              ),
              instructionPage(
                "/assets/images/calendars/imports/examples/apple/apple-import-step2.png",
                "horizontal",
                [
                  {
                    number: 4,
                    content: (
                      <>
                        <b>Check the box for "Public Calendar"</b> (if
                        available). This will only make the calendar public to
                        those with the secret link generated.
                      </>
                    ),
                  },
                ],
              ),
              instructionPage(
                "/assets/images/calendars/imports/examples/apple/apple-import-step3.png",
                "horizontal",
                [
                  {
                    number: 5,
                    content: (
                      <>
                        Copy the calendar URL (beginning with <b>webcal://</b>)
                        and paste it below.
                      </>
                    ),
                  },
                ],
              ),
            ]}
          />
        }
        onSuccess={onSuccess}
        onFailure={onFailure}
      />
    );
  }
}

class URLImportCalendar extends ImportCalendar {
  steps: ReactNode[];
  constructor(name: string, title: string, steps: ReactNode[]) {
    // super("url", "URL Calendar");
    super(name, title);
    this.steps = steps;
  }

  getContent(onSuccess: (url: string) => void, onFailure: () => void) {
    return (
      <URLCalendar
        instructions={<InstructionStepper steps={this.steps} />}
        onSuccess={onSuccess}
        onFailure={onFailure}
      />
    );
  }
}

export const GoogleImporter = new GoogleImportCalendar();
export const GoogleImporterWithOptions = new GoogleImportCalendar(true);
export const AppleImporter = new AppleImportCalendar();
export const GenericURLImporter = new URLImportCalendar(
  "Other",
  "Other calendar",
  [
    noImageInstructionPage([
      {
        content: (
          <>
            Most Calendar tools allow for exporting, sharing, or subscriptions
            with other calendar tools by giving you a special URL. If you have a
            URL, paste it below and we can try to import it.
          </>
        ),
      },
      {
        content: (
          <>
            If you still cannot import your desired calendar, reach out for help
            at{" "}
            <a target="_blank" href="mailto:support@findfaye.com">
              support@findfaye.com
            </a>{" "}
            and we can give you a hand.
          </>
        ),
      },
    ]),
  ],
);
export const CoziImporter = new URLImportCalendar("cozi", "Cozi Calendar", [
  instructionPage(
    "/assets/images/calendars/imports/examples/cozi/cozi-import-1.png",
    "horizontal",
    [
      {
        number: 1,
        content: (
          <>
            Log in to your Cozi account via the mobile app or at{" "}
            <a
              href="https://my.cozi.com"
              target="_blank"
              style={{
                color: "#198282",
                textDecoration: "none",
                fontWeight: "700",
              }}
            >
              my.cozi.com
            </a>
            .
          </>
        ),
      },
      {
        number: 2,
        content: (
          <>
            Navigate to <b>Settings</b> in the sidebar.
          </>
        ),
      },
    ],
  ),
  instructionPage(
    "/assets/images/calendars/imports/examples/cozi/cozi-import-2.png",
    "horizontal",
    [
      {
        number: 3,
        content: (
          <>
            Within Settings, select <b>Shared Cozi Calendars</b>.
          </>
        ),
      },
    ],
  ),
  instructionPage(
    "/assets/images/calendars/imports/examples/cozi/cozi-import-3.png",
    "horizontal",
    [
      {
        number: 4,
        content: (
          <>
            Choose the family member's calendar you wish to share and toggle
            switch to enable sharing for that calendar.
          </>
        ),
      },
      {
        number: 5,
        content: (
          <>
            Click <b>VIEW OR SEND COZI URL</b>.
          </>
        ),
      },
    ],
  ),
  instructionPage(
    "/assets/images/calendars/imports/examples/cozi/cozi-import-4.png",
    "horizontal",
    [
      {
        number: 6,
        content: (
          <>
            Copy the provided ICS URL and paste in the input box below these
            instructions.
          </>
        ),
      },
    ],
  ),
]);

export const OutlookImporter = new URLImportCalendar(
  "outlook",
  "Outlook Calendar",
  [
    instructionPage(
      "/assets/images/calendars/imports/examples/outlook/outlook-import-1.png",
      "horizontal",
      [
        {
          number: 1,
          content: (
            <>
              Navigate to Outlook’s{" "}
              <a
                href="https://outlook.live.com/calendar/0/options/calendar/SharedCalendars"
                target="_blank"
                style={{
                  color: "#198282",
                  textDecoration: "none",
                  fontWeight: "700",
                }}
              >
                Shared calendars
              </a>{" "}
              settings by going to{" "}
              <b>Settings → Calendars → Shared calendars</b>
            </>
          ),
        },
      ],
    ),
    instructionPage(
      "/assets/images/calendars/imports/examples/outlook/outlook-import-2.png",
      "vertical",
      [
        {
          number: 2,
          content: (
            <>
              Select your calendar and permissions and click <b>Publish</b>”
            </>
          ),
        },
      ],
    ),
    instructionPage(
      "/assets/images/calendars/imports/examples/outlook/outlook-import-3.png",
      "vertical",
      [
        {
          number: 3,
          content: (
            <>
              Copy the <b>ICS link</b> and paste it below
            </>
          ),
        },
      ],
    ),
  ],
);
export const YahooImporter = new URLImportCalendar("yahoo", "Yahoo Calendar", [
  instructionPage(
    "/assets/images/calendars/imports/examples/yahoo/yahoo-import-1.png",
    "horizontal",
    [
      {
        number: 1,
        content: (
          <>
            Open{" "}
            <a
              style={{
                color: "#198282",
                textDecoration: "none",
                fontWeight: "700",
              }}
              target="_blank"
              href="https://calendar.yahoo.com/"
            >
              Yahoo Calendar
            </a>
            .
          </>
        ),
      },
      {
        number: 2,
        content: (
          <>
            Select the dropdown next to your calendar and select{" "}
            <b>Edit Calendar</b>.
          </>
        ),
      },
    ],
  ),
  instructionPage(
    "/assets/images/calendars/imports/examples/yahoo/yahoo-import-2.png",
    "horizontal",
    [
      {
        number: 3,
        content: (
          <>
            Copy the shareable <b>ICS link</b> and paste it below.
          </>
        ),
      },
    ],
  ),
]);

export const MEMBER_IMPORT_CALENDARS = [
  GoogleImporter,
  AppleImporter,
  GenericURLImporter,
  CoziImporter,
  OutlookImporter,
  YahooImporter,
];

export const ADVISOR_IMPORT_CALENDARS = [
  GoogleImporterWithOptions,
  AppleImporter,
  CoziImporter,
  OutlookImporter,
  YahooImporter,
  GenericURLImporter,
];
