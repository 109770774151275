import { MenuItem, Select, Typography } from "@mui/material";
import { PlatformFeeSchedule } from "protogen/advisors_service_pb";
import { feeSchedule } from "./utils";
import { ChevronDown } from "lucide-react";

type Props = {
  value?: PlatformFeeSchedule;
  onUpdate: (p?: PlatformFeeSchedule) => void;
  disabled?: boolean;
};

export default ({ value, onUpdate, disabled = false }: Props) => {
  const toMenuItem = (
    name: string,
    description: string,
    val: PlatformFeeSchedule,
  ) => (
    <MenuItem key={name} value={val}>
      <div>
        <Typography
          variant="subtitle1"
          sx={{
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden",
          }}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden",
          }}
        >
          {description}
        </Typography>
      </div>
    </MenuItem>
  );
  return (
    <Select
      label="Platform fee"
      margin="none"
      displayEmpty
      value={value || ""}
      disabled={disabled}
      onChange={(e) => {
        const val = e.target.value as PlatformFeeSchedule;
        onUpdate(val || undefined);
      }}
      sx={{
        "& .MuiInputBase-input": {
          marginTop: "0px !important",
        },
        width: "calc(33% - 8px)",
        height: "60px",
      }}
      IconComponent={ChevronDown}
    >
      <MenuItem key={""} value={""} disabled>
        Platform fee
      </MenuItem>
      {toMenuItem(
        feeSchedule(PlatformFeeSchedule.SCHEDULE_LEAD_GEN_30_30_30_10),
        "Standard fee for generated lead (legacy advisors).",
        PlatformFeeSchedule.SCHEDULE_LEAD_GEN_30_30_30_10,
      )}
      {toMenuItem(
        feeSchedule(PlatformFeeSchedule.SCHEDULE_FLAT_10),
        "A flat 10% fee.",
        PlatformFeeSchedule.SCHEDULE_FLAT_10,
      )}
      {toMenuItem(
        feeSchedule(PlatformFeeSchedule.SCHEDULE_LEAD_GEN_30_30_30_15),
        "Standard fee for generated lead.",
        PlatformFeeSchedule.SCHEDULE_LEAD_GEN_30_30_30_15,
      )}
      {toMenuItem(
        feeSchedule(PlatformFeeSchedule.SCHEDULE_FLAT_15),
        "A flat 15% fee.",
        PlatformFeeSchedule.SCHEDULE_FLAT_15,
      )}
      {toMenuItem(
        feeSchedule(PlatformFeeSchedule.SCHEDULE_NO_FEE),
        "No faye fee for this client.",
        PlatformFeeSchedule.SCHEDULE_NO_FEE,
      )}
    </Select>
  );
};
