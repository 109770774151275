import { Family, Member, UserPermission } from "./types";
import { cleanPhone } from "./utils";
import {
  Family as FamilyProto,
  FamilyStatus,
  PlatformFeeSchedule,
  UpdateFamilyRequest,
  UpdateFamilyRequest_UpdatedPermissions,
} from "protogen/advisors_service_pb";
import { Member as MemberProto } from "protogen/common_pb";
import { PlainMessage } from "@bufbuild/protobuf";
import { UserPermission as UserPermissionProto } from "protogen/common_pb";
import { isEqual } from "lodash";

const permissionAreEqual = (
  prevPermission: UserPermissionProto,
  nextPermission: UserPermission,
): boolean => {
  return (
    prevPermission.ref === nextPermission.ref &&
    prevPermission.account?.ref === nextPermission.advisorRef &&
    prevPermission.expiration === nextPermission.expiration &&
    prevPermission.active === nextPermission.active &&
    isEqual(prevPermission.actions, nextPermission.actions)
  );
};

const updatedPermissions = (
  prevPermissions: UserPermissionProto[],
  nextPermissions: UserPermission[],
): PlainMessage<UpdateFamilyRequest_UpdatedPermissions>[] => {
  let hasChanges = false;
  const updatedPerms: PlainMessage<UpdateFamilyRequest_UpdatedPermissions>[] =
    [];
  const prevMap = new Map(prevPermissions.map((p) => [p.ref, p]));
  for (const next of nextPermissions) {
    if (next.ref && prevMap.has(next.ref)) {
      if (
        !permissionAreEqual(prevMap.get(next.ref)!, next) ||
        next.reactivate ||
        next.deactivate
      ) {
        hasChanges = true;
      }
      updatedPerms.push({
        ref: next.ref,
        accountRef: next.advisorRef,
        expiration: next.expiration || BigInt(0),
        actions: next.actions,
        deactivate: !!next.deactivate,
        isCreation: false,
        reactivate: !!next.reactivate,
      });
    } else {
      hasChanges = true;
      updatedPerms.push({
        isCreation: true,
        ref: "",
        accountRef: next.advisorRef,
        expiration: next.expiration || BigInt(0),
        actions: next.actions,
        deactivate: false,
        reactivate: false,
      });
    }
  }
  return hasChanges ? updatedPerms : [];
};

export const memberUpdates = (newMember: Member, oldMember: Member) => ({
  ref: newMember.ref!,
  updatedFirstName:
    newMember.firstName === oldMember.firstName ? "" : newMember.firstName!,
  updatedLastName:
    newMember.lastName === oldMember.lastName ? "" : newMember.lastName!,
  updatedPhone:
    newMember.phone === oldMember.phone ? "" : cleanPhone(newMember.phone!),
  updatedEmail: newMember.email === oldMember.email ? "" : newMember.email!,
  shouldUpdateAltEmails: newMember.altEmails !== oldMember.altEmails,
  updatedAltEmails:
    newMember.altEmails === oldMember.altEmails ? [] : newMember.altEmails!,
  updatedTimezone:
    newMember.timezone === oldMember.timezone ? "" : newMember.timezone!,
});

export const updates = (
  family: FamilyProto,
  familyData: Family,
  memberData: Member[],
  advisorPermissions: UserPermissionProto[],
): PlainMessage<UpdateFamilyRequest> => {
  const familyMemberMap = new Map<string, MemberProto>();
  family.familyMembers.forEach((member: MemberProto) => {
    familyMemberMap.set(member.ref, member);
  });
  return {
    familyRef: family.ref,
    updatedName: family.name === familyData.name ? "" : familyData.name!,
    updatedAdvisorRef:
      family.advisorRef === familyData.advisorRef ? "" : familyData.advisorRef!,
    updatedStatus:
      family.status === familyData.status
        ? FamilyStatus.NOT_SET
        : familyData.status,
    updatedStartDate:
      familyData.startDate && familyData.startDate !== family.startDate
        ? familyData.startDate
        : "",
    clearStartDate: !!familyData.clearStartDate,
    updatedEndDate: familyData.endDate || "",
    clearEndDate: !!(!familyData.endDate && family.endDate),
    platformFeeSchedule:
      family.platformFeeSchedule === familyData.platformFeeSchedule
        ? PlatformFeeSchedule.SCHEDULE_NOT_SET
        : familyData.platformFeeSchedule ||
          PlatformFeeSchedule.SCHEDULE_NOT_SET,
    updatedPermissions: updatedPermissions(
      advisorPermissions,
      familyData.permissions,
    ),
    updatedAddress:
      family.address === familyData.address ? "" : familyData.address!,
    updatedProduct:
      familyData?.product?.id &&
      familyData?.product?.id != family.billingInfo?.product?.id
        ? familyData?.product
        : undefined,
    updatedIntroCoupon:
      familyData?.introCoupon?.id &&
      familyData?.introCoupon?.id != family.billingInfo?.introCoupon?.id
        ? familyData?.introCoupon
        : undefined,
    nullifyCoupon:
      family.billingInfo?.introCoupon && !familyData?.introCoupon?.id,
    setStripeCustomerId: familyData.stripeCustomerId || "",
    updatedMembers: memberData
      .filter((m) => !!m.ref)
      .map((member) => ({
        ref: member.ref!,
        updatedFirstName:
          member.firstName === familyMemberMap.get(member.ref!)?.firstName
            ? ""
            : member.firstName!,
        updatedLastName:
          member.lastName === familyMemberMap.get(member.ref!)?.lastName
            ? ""
            : member.lastName!,
        updatedPhone:
          member.phone === familyMemberMap.get(member.ref!)?.primaryPhone
            ? ""
            : cleanPhone(member.phone!),
        updatedEmail:
          member.email === familyMemberMap.get(member.ref!)?.primaryEmail
            ? ""
            : member.email!,
        shouldUpdateAltEmails:
          member.altEmails !==
          familyMemberMap.get(member.ref!)?.alternateEmails,
        updatedAltEmails:
          member.altEmails === familyMemberMap.get(member.ref!)?.alternateEmails
            ? []
            : member.altEmails!,
        updatedTimezone:
          member.timezone === familyMemberMap.get(member.ref!)?.timezone
            ? ""
            : member.timezone!,
      })),
    newMembers: memberData
      .filter((m) => !m.ref)
      .map((member) => ({
        ref: "",
        updatedFirstName: member.firstName,
        updatedLastName: member.lastName,
        updatedPhone: cleanPhone(member.phone),
        updatedEmail: member.email,
        shouldUpdateAltEmails: true,
        updatedAltEmails: member.altEmails,
        updatedTimezone: member.timezone,
      })),
    shouldUpdateMetadata: false,
    updatedMetadata: undefined,
  };
};

export const emptyUpdates = (
  familyRef: string,
): PlainMessage<UpdateFamilyRequest> => {
  return {
    familyRef,
    updatedName: "",
    updatedAdvisorRef: "",
    updatedStatus: FamilyStatus.NOT_SET,
    updatedStartDate: "",
    clearStartDate: false,
    updatedEndDate: "",
    clearEndDate: false,
    platformFeeSchedule: PlatformFeeSchedule.SCHEDULE_NOT_SET,
    updatedPermissions: [],
    updatedAddress: "",
    updatedProduct: undefined,
    updatedIntroCoupon: undefined,
    nullifyCoupon: false,
    setStripeCustomerId: "",
    updatedMembers: [],
    newMembers: [],
    shouldUpdateMetadata: false,
    updatedMetadata: undefined,
  };
};
