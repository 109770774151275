import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsVisible from "components/hooks/useIsVisible";
import { ScrollContainerHandle } from "components/common/ScrollContainer";
import { Button, Box, Chip, Popper } from "@mui/material";
import { AccountStub, Attachment } from "protogen/common_pb";
import ScrollableActivityPanel from "components/details-page/ScrollableActivityPanel";
import AttachmentDialog from "components/common/AttachmentDialog";
import {
  CompositionSection,
  SendOptions,
  ShellCompositionSection,
} from "components/activity/TextMessageCompose";
import FaceIcon from "@mui/icons-material/Face";
import {
  useFetchMessageThread,
  useSendMessage,
  useGetOrCreateMessageConversation,
} from "services/messaging";
import { DirectMessage } from "protogen/messaging_service_pb";
import { protoInt64 } from "@bufbuild/protobuf";
import { CurrentUserContext } from "components/context/RequireAuth";
import { isToday } from "../../common/utils";
import DateDisplay from "../common/DateDisplay";
import ActivityFeedEntry from "components/activity/ActivityFeedEntry";
import ActivityDivider from "components/activity/ActivityDivider";
import TextMessage from "components/activity/TextMessage";
import { CurrentUser } from "protogen/auth_pb";
import { Advisor } from "protogen/common_pb";
import AdvisorAutocomplete from "./AdvisorAutocomplete";
import { UserAvatar } from "../common/CurrentUserAvatar";
import InboxThreadHeader from "../inbox/InboxThreadHeader";
import { ReactionAction, ReactionRow, ReactionSelector } from "./Reactions";
import useIsMobile from "../hooks/useIsMobile";
import useLongPress from "../hooks/useLongPress";
import LinkRouter from "components/navigation/LinkRouter";

type FeedEntryProps = {
  entry: DirectMessage;
  // Used to format things in a sequence.
  nextEntry?: DirectMessage;
  prevEntry?: DirectMessage;
  sender: AccountStub;
  openAttachment: (a: Attachment) => void;
  currentUser: CurrentUser;
  refresh?: () => void;
};
const FeedEntryComp = ({
  entry,
  sender,
  prevEntry,
  openAttachment,
  currentUser,
  refresh,
}: FeedEntryProps) => {
  const isMobile = useIsMobile();
  const [hover, setHover] = useState(false);
  const [activeReactions, setActiveReactions] = useState(false);
  const mobileLongPress = useLongPress({
    onLongPress: () => setActiveReactions(true),
  });
  const entryDt = new Date(Number(entry.timestampSec) * 1000);
  const time = DateDisplay({ date: entryDt });
  const boxRef = useRef(null);

  // Helpful if you get confused.
  // console.log(entry.textMessage?.content, ' between ', prevEntry?.textMessage?.content, ' and ', nextEntry?.textMessage?.content);
  const SAME_TS_THRESHOLD_SEC = 10 * 60;
  const includeSender = !prevEntry || prevEntry.senderRef !== entry.senderRef;
  const includeTime =
    !prevEntry ||
    Number(entry.timestampSec) - Number(prevEntry.timestampSec) >
      SAME_TS_THRESHOLD_SEC;
  const includeHeader = includeSender || includeTime;
  // const includeAvatar = includeSender && sender && !sender.isAdvisor;
  const includeTodayDivider =
    isToday(entryDt) &&
    (!prevEntry || !isToday(new Date(Number(prevEntry.timestampSec) * 1000)));
  const buildHeader = (): string => {
    const senderName =
      currentUser.ref === entry.senderRef ? "You" : `${sender.displayName}`;
    return senderName;
  };
  const alignLeft = sender.ref !== currentUser.ref;
  return (
    <>
      <ActivityFeedEntry
        alignLeft={alignLeft}
        avatarGutter={false}
        headerLeftText={includeHeader ? buildHeader() : null}
        headerRightText={includeHeader ? time : null}
      >
        <Box
          ref={boxRef}
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          justifyContent={alignLeft ? "flex-start" : "flex-end"}
          alignItems={"flex-end"}
          gap={"8px"}
          sx={{
            position: "relative",
            ...(isMobile && { UserSelect: "none" }),
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          {...(isMobile && mobileLongPress)}
        >
          {!alignLeft && !isMobile && (
            <ReactionAction
              currentUser={currentUser}
              message={entry}
              active={hover}
              refreshFeed={refresh}
            />
          )}
          <TextMessage
            alignLeft={alignLeft}
            content={entry.content.textContent}
            loading={false}
            attachments={entry.content.attachments}
            openAttachment={openAttachment}
          />
          {alignLeft && !isMobile && (
            <ReactionAction
              currentUser={currentUser}
              message={entry}
              active={hover}
              refreshFeed={refresh}
            />
          )}
        </Box>
        <ReactionRow
          currentUser={currentUser}
          alignLeft={alignLeft}
          message={entry}
          refreshFeed={refresh}
        />
        {activeReactions && (
          <Popper
            open={activeReactions}
            anchorEl={boxRef.current}
            placement={isMobile ? "bottom" : "top"}
          >
            <ReactionSelector
              currentUser={currentUser}
              message={entry}
              onClose={() => setActiveReactions(false)}
              refreshFeed={refresh}
            />
          </Popper>
        )}
      </ActivityFeedEntry>
      {includeTodayDivider && <ActivityDivider text={"Today"} />}
    </>
  );
};

const dedupeMessages = (entries: DirectMessage[]) => {
  const result: DirectMessage[] = [];
  const indexMap = new Map<string, number>();
  for (const item of entries) {
    // Create a unique string representation of the keys
    const key = `${item.ref}`;
    if (!indexMap.has(key)) {
      indexMap.set(key, result.length);
      result.push(item);
    }
  }
  return result;
};

const sortMessageTimestamps = (a: DirectMessage, b: DirectMessage) => {
  const aTs = protoInt64.parse(a.timestampSec!);
  const bTs = protoInt64.parse(b.timestampSec!);
  return aTs < bTs ? 1 : aTs > bTs ? -1 : 0;
};

const Compose = ({
  conversationRef,
  onSent,
  placeholder,
}: {
  conversationRef: string;
  onSent: (entry: DirectMessage) => void;
  placeholder: string;
}) => {
  const { loading, request } = useSendMessage();
  const handleSend = async (inputValue: string, options: SendOptions) => {
    const resp = await request({
      conversationRef,
      content: {
        contentType: "text",
        payload: inputValue,
        textContent: inputValue,
        attachments: options.attachments,
      },
    });
    if (!resp) return false;
    onSent(resp?.message!);
    return true;
  };
  const handleSendCannedMessage = async (message: string | null) => {
    if (message) {
      await handleSend(message, { attachments: [] });
    }
  };

  return (
    <CompositionSection
      onSend={handleSend}
      placeholder={placeholder}
      handleSendCannedMessage={handleSendCannedMessage}
      loading={loading}
      thumbsUp={true}
      autoFocus={true}
      cacheKey={`direct-message:${conversationRef}`}
      // 100MBs, Pretty lenient for now
      maxUploadBytes={1024 * 1024 * 100}
    />
  );
};

const MessageFeed = ({
  conversationRef,
  onNewMessage,
  isNewConversation,
}: {
  conversationRef: string | undefined;
  onNewMessage?: (entry: DirectMessage) => void;
  isNewConversation?: boolean;
}) => {
  const navigate = useNavigate();
  const { request: getOrCreateMessageConversationRequest } =
    useGetOrCreateMessageConversation();
  const currentUser = useContext(CurrentUserContext);
  const scrollRef = useRef<ScrollContainerHandle | null>(null);
  const hasLoadedOnce = useRef(false);
  const [localEntries, setLocalEntries] = useState<DirectMessage[]>([]);
  const [feedCursor, setFeedCursor] = useState("");
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [entries, setEntries] = useState<DirectMessage[]>([]);
  const [participants, setParticipants] = useState<AccountStub[]>([]);
  const [accountMap, setAccountMap] = useState<Map<string, AccountStub>>(
    new Map(),
  );
  const [openAttachment, setOpenAttachment] = useState<Attachment | null>(null);
  const [advisors, setAdvisors] = useState<Advisor[]>([]);
  const { request } = useFetchMessageThread();
  const [lastSeen, setLastSeen] = useState<bigint | null>(null);
  const refresh = async (feedCursor?: string) => {
    if (conversationRef === undefined) return;
    const response = await request({
      conversationRef,
      cursor: feedCursor || "",
      // Only mark conversations as read if the feed is visible.
      markConversationsRead: isVisible,
    });
    if (!response) return;
    if (feedCursor || !hasLoadedOnce.current) {
      // Be careful of thrashing nextCursor on refreshes of the first page.
      setFeedCursor(() => response.nextCursor);
      setHasMore(() => !!response.nextCursor);
    }
    hasLoadedOnce.current = true;
    setEntries((pastEntries) => {
      return dedupeMessages(
        [...response.messages, ...pastEntries].sort(sortMessageTimestamps),
      );
    });
    setAccountMap(
      response.participants.reduce(
        (acc: Map<string, AccountStub>, account: AccountStub) => {
          acc.set(account.ref, account);
          return acc;
        },
        new Map(),
      ),
    );
    setParticipants(
      response.participants.filter((p) => p.ref !== currentUser.ref),
    );
  };
  const { isVisible } = useIsVisible({
    onRefocus: async (blurSecs) => {
      if (blurSecs > 10) {
        await refresh();
      }
    },
  });

  useEffect(() => {
    const latest = entries.reduce(
      (prev, current) => {
        if (!prev) return current;

        return current.senderRef !== currentUser.ref &&
          current.timestampSec > prev.timestampSec
          ? current
          : prev;
      },
      null as DirectMessage | null,
    );
    if (latest && (!lastSeen || latest.timestampSec > lastSeen)) {
      setLastSeen(latest.timestampSec);
      scrollRef.current?.setSticky();
    }
  }, [entries]);
  const onNewThread = async () => {
    const resp = await getOrCreateMessageConversationRequest({
      userRefs: advisors.map((a) => a.ref),
    });
    if (resp?.conversationRef) {
      navigate(`/inbox/messages/${encodeURIComponent(resp.conversationRef)}`);
    }
  };

  useEffect(() => {
    if (!hasLoadedOnce.current) {
      refresh();
    }
    const intervalId = setInterval(async () => {
      if (isVisible) {
        await refresh();
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  // Dedupe.
  const allEntries = dedupeMessages(
    [...entries, ...localEntries].sort(sortMessageTimestamps),
  );
  const scrollEntries = allEntries.map((e, i) => (
    <FeedEntryComp
      key={e.ref}
      entry={e}
      sender={accountMap.get(e.senderRef)!}
      openAttachment={(attachment) => {
        setOpenAttachment(attachment);
      }}
      // Be confused! We sort these in reverse from our CSS rendering which makes life more confusing.
      nextEntry={allEntries[i - 1]}
      prevEntry={allEntries[i + 1]}
      currentUser={currentUser}
      refresh={refresh}
    />
  ));

  const onMessageSent = (entry: DirectMessage) => {
    setLocalEntries([...localEntries, entry]);
    scrollRef.current?.setSticky();
    refresh();
    onNewMessage && onNewMessage(entry);
  };

  if (isNewConversation && !conversationRef) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "12px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "12px",
            height: "70px",
          }}
        >
          <Box sx={{ width: "100%", height: "10px" }}>
            <AdvisorAutocomplete
              setSelected={setAdvisors}
              autoFocus={true}
              onEnter={onNewThread}
            />
          </Box>
          <Button
            disabled={advisors.length === 0}
            sx={{ minWidth: "150px", alignSelf: "center" }}
            onClick={() => {
              onNewThread();
            }}
          >
            Start thread
          </Button>
        </Box>
        <ShellCompositionSection
          onFocus={onNewThread}
          placeholder={"Send message"}
          disabled={advisors.length === 0}
        />
      </Box>
    );
  }
  return (
    <>
      <ScrollableActivityPanel
        scrollEntries={
          isNewConversation && !conversationRef ? [] : scrollEntries
        }
        fetchScrollEntries={() => refresh(feedCursor)}
        hasMoreScrollEntries={hasMore}
        scrollRef={scrollRef}
        headerPanel={
          <InboxThreadHeader>
            {participants.map((p) => (
              <LinkRouter
                to={`/advisor/${encodeURIComponent(p.ref)}`}
                key={p.ref}
              >
                <Chip
                  sx={{
                    padding: "10px",
                    height: "40px",
                    borderRadius: "20px",
                  }}
                  icon={
                    p.avatarUrl ? (
                      <UserAvatar user={p} size={24} />
                    ) : (
                      <FaceIcon />
                    )
                  }
                  label={`${p.firstName} ${p.lastName[0]}`}
                  variant={"outlined"}
                />
              </LinkRouter>
            ))}
          </InboxThreadHeader>
        }
        hasHeaderBorder={!(isNewConversation && !conversationRef)}
        footerPanel={
          conversationRef && (
            <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
              {conversationRef && (
                <Compose
                  conversationRef={conversationRef}
                  placeholder={`Send message`}
                  onSent={onMessageSent}
                />
              )}
            </Box>
          )
        }
        sx={{ height: "100%", padding: "0px" }}
      />
      <AttachmentDialog
        attachment={openAttachment}
        onClose={() => setOpenAttachment(null)}
      />
    </>
  );
};

interface DirectMessagingThreadProps {
  conversationRef: string | undefined;
  onNewMessage?: (m: DirectMessage) => void;
  isNewConversation: boolean;
}

export default ({
  conversationRef,
  onNewMessage,
  isNewConversation,
}: DirectMessagingThreadProps) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <MessageFeed
        conversationRef={conversationRef}
        onNewMessage={onNewMessage}
        isNewConversation={isNewConversation}
      />
    </Box>
  );
};
