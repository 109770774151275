import { EventNotice } from "protogen/calendar_pb";
import ReactiveDialog from "../common/ReactiveDialog";
import EventView from "./EventView";
import useIsMobile from "../hooks/useIsMobile";
import { useContext, useEffect } from "react";
import { StatusContext } from "../context/StatusContextProvider";

interface Props {
  open: boolean;
  onClose: (refresh?: boolean) => void;
  onDelete?: (eventRef?: string) => void;
  primaryAction: (eventRef: string) => void;
  eventRef: string;
  accountType?: "advisor" | "member";
  eventNotice?: EventNotice;
}

export default ({
  open,
  onClose,
  eventRef,
  primaryAction,
  onDelete,
  accountType = "advisor",
  eventNotice,
}: Props) => {
  const isMobile = useIsMobile();
  const { refresh: statusRefresh } = useContext(StatusContext);
  useEffect(() => {
    // Refresh the status on close.
    if (!open && eventNotice?.unreadStatusCount) {
      statusRefresh();
    }
  }, [open]);
  return (
    <ReactiveDialog
      open={open}
      onClose={() => onClose(false)}
      noActionsFullScreen={true}
      style={{
        width: "776px",
        maxWidth: "776px",
        padding: !isMobile ? "20px 18px" : undefined,
      }}
    >
      <EventView
        eventRef={eventRef}
        onClose={() => onClose(false)}
        primaryAction={primaryAction}
        onDelete={onDelete}
        accountType={accountType}
        eventNotice={eventNotice}
      />
    </ReactiveDialog>
  );
};
